var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.changeNav()}}}),_c('v-toolbar-title',[_vm._v(" Novo Chamado ")]),_c('v-spacer'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'Chamados'}}},[_c('v-btn',{attrs:{"small":"","color":"secondary"}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle")]),_vm._v("   Voltar ")],1)],1),_vm._v("   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.createTicket()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bullhorn-outline")])],1)]}}])},[_c('span',[_vm._v("Enviar")])])],1),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.largura2}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","label":"Título","required":""},model:{value:(_vm.newTicket.titulo),callback:function ($$v) {_vm.$set(_vm.newTicket, "titulo", $$v)},expression:"newTicket.titulo"}})],1),_c('v-col',{attrs:{"cols":_vm.largura1}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Tipo","outlined":""},model:{value:(_vm.newTicket.tipo),callback:function ($$v) {_vm.$set(_vm.newTicket, "tipo", $$v)},expression:"newTicket.tipo"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"label":"Usuários","items":_vm.getUsers,"outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(item.nomeCompleto + ' (' + item.email +')')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(item.nomeCompleto)+" ")])]}}],null,false,2671109249),model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}})],1):_vm._e()],1)],1),_c('v-card-text',[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.newTicket.descHTML),callback:function ($$v) {_vm.$set(_vm.newTicket, "descHTML", $$v)},expression:"newTicket.descHTML"}})],1),_c('v-card-actions',[_c('v-file-input',{attrs:{"label":"Anexar Arquivos","multiple":"","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"secondary","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" Arquivo(s) ")]):_vm._e()]}}]),model:{value:(_vm.newTicket.files),callback:function ($$v) {_vm.$set(_vm.newTicket, "files", $$v)},expression:"newTicket.files"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
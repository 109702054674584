<template>
<div>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            Visualizar Usuário
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link class="link" :to="{ name: 'Usuarios'}">
            <v-btn small color="secondary">
                <v-icon>mdi-arrow-left-circle</v-icon>
                &nbsp;
                Voltar
            </v-btn>
        </router-link>
        &nbsp;
        <v-tooltip bottom v-if="isAdmin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="updateProfileUser()" small dark color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-account-sync-outline</v-icon>
            </v-btn>
          </template>
          <span>Atualizar</span>
        </v-tooltip>
        &nbsp;
        <v-tooltip bottom v-if="isMaster">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="delUser = true" small dark color="red" v-bind="attrs" v-on="on">
              <v-icon>mdi-account-cancel-outline</v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
    </v-toolbar>
      <v-card elevation="0">
        <v-card-title class="pt-0">
          <v-row class="justify-center">
            <v-avatar size="150"><img :src="profile.photoURL" alt="User"></v-avatar>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="3">
                <v-text-field type="text" outlined v-model="profile.nome" label="Nome" required></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field type="text" outlined v-model="profile.sobreNome" label="Sobrenome" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="3">
                <v-text-field type="email" outlined v-model="profile.email" label="Endereço de Email" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                <v-text-field v-mask="'###.###.###-##'" type="text" outlined v-model="profile.CPF" label="CPF" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field v-mask="'##.###.###-#'" type="text" outlined v-model="profile.RG" label="RG" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.empresa" label="Empresa" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                <v-select @change="changePhotoURL" outlined :items="sexualidades" v-model="profile.sexo" label="Sexualidade" required></v-select>
            </v-col>
            <v-col cols="2">
                <v-text-field v-mask="'(##)#####-####'" type="text" outlined v-model="profile.telCelular" label="Tel.Celular" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.cargo" label="Cargo" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field v-mask="'(##)####-####'" type="text" outlined v-model="profile.telComercial" label="Tel.Comercial"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                    <v-text-field v-mask="'#####-###'" type="text" outlined v-model="profileCEP" label="CEP" :rules="rules" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="1">
                <v-select outlined :items="estados" v-model="profile.estado" label="Estado"></v-select>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.cidade" label="Cidade"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
              <v-col cols="3">
                  <v-text-field type="text" outlined v-model="profile.logradouro" label="Logradouro"></v-text-field>
              </v-col>
              <v-col cols="3">
                  <v-text-field type="text" outlined v-model="profile.bairro" label="Bairro"></v-text-field>
              </v-col>
          </v-row>
          <v-row class="justify-center">
              <v-col cols="6">
                  <v-textarea type="text" outlined v-model="profile.bio" label="Biografria"></v-textarea>
              </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="delUser" max-width="500">
        <v-card>
            <v-card-title class="text-h6">
                Tem certeza que gostaria de excuir este usuário?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="delUser = false">
                    Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="delProfileUser()">
                    Confirmar
                </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      rules: [
        value => !!value || 'CEP Inválido.',
        value => (value && value.length >= 8 && value.length <= 9) || 'Refazer CEP'
      ],
      estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      sexualidades: ['Masculino', 'Feminino', 'Não-binário'],
      profile: {},
      delUser: false
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async changePhotoURL () {
      if (this.profile.sexo === 'Feminino') {
        this.profile.photoURL = 'https://img.icons8.com/color/99/000000/circled-user-female-skin-type-5--v1.png'
      } else if (this.profile.sexo === 'Masculino') {
        this.profile.photoURL = 'https://img.icons8.com/color/99/000000/user-male-circle--v1.png'
      } else {
        this.profile.photoURL = 'https://img.icons8.com/material-rounded/99/000000/user-male-circle.png'
      }
    },
    async updateProfileUser () {
      this.profile.nomeCompleto = await this.profile.nome + ' ' + this.profile.sobreNome
      await this.$store.dispatch('updateProfileUser', this.profile)
    },
    async delProfileUser () {
      await this.$store.dispatch('delUser', this.profile.profileId).then(() => {
        this.delUser = false
      })
    }
  },
  computed: {
    profileCEP: {
      get () {
        return this.profile.CEP
      },
      set (value) {
        value = value.replace('-', '')
        if (value.length === 8) {
          axios.get(`https://viacep.com.br/ws/${value}/json/`)
            .then(response => {
              this.profile.CEP = response.data.cep
              this.profile.estado = response.data.uf
              this.profile.cidade = response.data.localidade
              this.profile.logradouro = response.data.logradouro
              this.profile.bairro = response.data.bairro
            }).catch(error => console.log(error))
        }
      }
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    getUsers () {
      return this.$store.state.usersMembers
    },
    getAdmins () {
      return this.$store.state.adminMembers
    }
  },
  async mounted () {
    const perfil = await this.getAdmins.filter(adm => {
      return adm.profileId === this.$route.params.iduser
    })
    if (perfil.length > 0) {
      this.profile = perfil[0]
    } else {
      this.profile = await this.getUsers.filter(user => {
        return user.profileId === this.$route.params.iduser
      })[0]
    }
  }
}
</script>

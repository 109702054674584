<template>
<div>
    <v-form ref="form" lazy-validation>
        <v-card>
            <v-toolbar flat>
                <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
                <v-toolbar-title>
                   Editar Curso
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <router-link class="link" :to="{ name: 'VisualizarCurso', params: { idcurso: this.$route.params.idcurso } }">
                    <v-btn small color="secondary">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                        &nbsp;
                        Voltar
                    </v-btn>
                </router-link>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="updateCurso()" small dark color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-send-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Atualizar</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        <v-text-field type="text" outlined v-model="editCourse.titulo" label="Título"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-select :items="items" label="Tipo" outlined v-model="editCourse.tipo"></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-select :items="adminMembers" label="Instrutor" outlined v-model="editCourse.teacher">
                            <template v-slot:item="{ item }">
                                <span class="text-overline grey--text text--darken-3 mx-2">
                                    {{ item.nomeCompleto + ' (' + item.email +')' }}
                                </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span class="text-overline grey--text text--darken-3 mx-2">
                                    {{ item.nomeCompleto }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="text" outlined v-model="editCourse.descSimples" label="Descrição (Simples)" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="CBS">
                  <v-col cols="4">
                    <v-combobox v-model="editCourse.linguagem" :items="languages" label="Linguagem do Vídeo" outlined dense hide-selected></v-combobox>
                  </v-col>
                  <v-col cols="4">
                    <v-combobox v-model="editCourse.legendas" :items="languages" label="Legendas Disponíveis" multiple outlined dense hide-selected></v-combobox>
                  </v-col>
                  <v-col cols="4">
                    <v-combobox v-model="editCourse.auxiliares" label="Auxiliares" :items="adminMembers" multiple outlined dense>
                      <template v-slot:item="{ item }">
                        <span class="text-overline grey--text text--darken-3 mx-2">
                          {{ item.nomeCompleto + ' (' + item.email +')' }}
                        </span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span class="text-overline grey--text text--darken-3 mx-2">
                          {{ item.nomeCompleto }}
                        </span>
                      </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
                <vue-editor :editorToolbar="customToolbar" v-model="editCourse.descHTML" />
            </v-card-text>
            <v-card-actions>
                <v-file-input v-model="editCourse.file" label="Capa (370x250)" prepend-icon="mdi-image-album" outlined :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="secondary" dark label small>
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-card-actions>
        </v-card>
    </v-form>
</div>
</template>

<script>
export default {
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }],
        [{
          color: []
        }]
      ],
      items: ['AutoCAD Civil 3D', 'Infraworks', 'Navisworks', 'AutoCAD'],
      editCourse: {
        id: null,
        titulo: null,
        tipo: null,
        descSimples: null,
        descHTML: null,
        teacher: null,
        file: null,
        vimeoURI: null,
        legendas: [],
        linguagem: null,
        auxiliares: []
      },
      languages: [
        'Português',
        'Inglês',
        'Espanhol',
        'Chinês',
        'Francês',
        'Russo'
      ]
    }
  },
  computed: {
    adminMembers () {
      return this.$store.state.adminMembers
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    updateCurso () {
      this.$store.dispatch('updateCourse', this.editCourse)
    }
  },
  mounted () {
    const course = this.$store.state.courses.filter(C => {
      return C.courseId === this.$route.params.idcurso
    })[0]

    this.editCourse.titulo = course.titulo
    this.editCourse.tipo = course.tipo
    this.editCourse.descSimples = course.descSimples
    this.editCourse.descHTML = course.descHTML
    this.editCourse.id = course.courseId
    this.editCourse.teacher = course.teacher
    this.editCourse.vimeoURI = course.vimeoURI
    this.editCourse.legendas = course.legendas
    this.editCourse.linguagem = course.linguagem
    this.editCourse.auxiliares = course.auxiliares
  },
  async created () {
    if (!this.$store.state.adminMembers.length > 0) {
      await this.$store.dispatch('getAllAdmins')
    }
  }
}
</script>

<style>

</style>

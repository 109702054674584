var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.changeNav()}}}),_c('v-toolbar-title',[_vm._v("   Sugestões ")])],1),_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getSuggestions,"sort-by":"criadoEm","items-per-page":20,"sort-desc":true,"search":_vm.search,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus',
       'items-per-page-text':'Quantidade'
  }},scopedSlots:_vm._u([{key:"item.nomeUser",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeUser)+" ")]}},{key:"item.mensagem",fn:function(ref){
  var item = ref.item;
return [_c('b',[_c('i',[_vm._v(_vm._s(item.mensagem))])])]}},{key:"item.criadoEm",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }))+" ")]}},{key:"item.buttons",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.openModalSugestion(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('small',[_c('v-icon',[_vm._v("mdi-check")]),_vm._v("   Não há sugestões no momento. ")],1)]),_c('template',{slot:"no-results"},[_c('small',[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v("   Nenhum resultado encontrado. ")],1)])],2),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.modalDelSuggestion),callback:function ($$v) {_vm.modalDelSuggestion=$$v},expression:"modalDelSuggestion"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Excluir sugestão? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.delSuggestion()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
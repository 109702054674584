<template>
<div>
    <v-toolbar flat>
      <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
      <v-toolbar-title>
          Página de Sorteio
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch class="mt-6" v-model="showBtnRaffle" inset v-if="isAdmin" @change="setBtnRaffle()"></v-switch>
      &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="primary" v-bind="attrs" v-on="on" @click="modalClear = true">
              <v-icon>mdi-broom</v-icon>
            </v-btn>
          </template>
          <span>Limpar</span>
        </v-tooltip>
        &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="green" v-bind="attrs" v-on="on" @click="exportExcel()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar Excel</span>
        </v-tooltip>
    </v-toolbar>
    <v-card v-if="getUsersRaffle.length > 1" elevation="0" class="mt-2 d-flex justify-center">
        <RaffleWheel @spin-stop="setUser" :slicesFont="slicesFont" :hubFont="hubFont" buttonText="Girar" :options="getUsersRaffle" />
    </v-card>
    <v-dialog v-model="modalClear" max-width="300px">
      <v-card>
          <v-card-title class="text-h6">
              Deseja continuar?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="delUsersRaffle()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalUser" max-width="300px">
      <v-card v-if="objUser !== null">
          <v-card-title class="text-h6">
            {{ objUser.text }}
          </v-card-title>
          <v-card-subtitle>
            {{ objUser.email }}
          </v-card-subtitle>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import RaffleWheel from 'vue-raffle-wheel'
export default {
  data () {
    return {
      modalClear: false,
      modalUser: false,
      objUser: null,
      slicesFont: { style: 'bold', size: '12px', family: 'Helvetica, Arial' },
      hubFont: { style: 'bold', size: '30px', family: 'Helvetica, Arial' }
    }
  },
  components: { RaffleWheel },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async delUsersRaffle () {
      await this.$store.dispatch('delUsersRaffle').then(() => {
        this.modalClear = false
      })
    },
    exportExcel () {
      const data = []
      if (this.getUsersRaffle.length > 1) {
        let i = 0
        this.getUsersRaffle.forEach(U => {
          i += 1
          data.push({
            Id: i,
            Nome: U.text,
            TelCelular: U.telCelular,
            Email: U.email,
            Curso: U.curso
          })
        })
      }
      if (data.length > 0) {
        import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ json2excel }) => {
          json2excel({
            data,
            name: 'Lista Sorteio',
            formateDate: 'dd/mm/yyyy'
          })
        }).catch((e) => {
          console.log(e)
        })
      } else {
        console.log('Nenhum resultado encontrado.')
      }
    },
    setUser (e) {
      this.objUser = e
      this.modalUser = true
    },
    async setBtnRaffle () {
      await this.$store.dispatch('updateBtnRaffle')
    }
  },
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getUsersRaffle () {
      return this.$store.state.raffles
    },
    showBtnRaffle: {
      get () {
        return this.$store.state.btnRaffle
      },
      set (value) {
        this.$store.state.btnRaffle = value
      }
    }
  },
  async created () {
    await this.$store.dispatch('getAllUsersRaffle')
    await this.$store.dispatch('getBtnRaffle')
  }
}
</script>
<style>
.raffle-wheel {
    display: grid;
    justify-items: center;
}
.raffle-wheel__button {
    color: rgba(0, 0, 0, 0.87);
    width: 80px;
    margin-top: 4px;
    text-align: center;
    background-color: transparent;
    height: 28px;
    padding: 0 12.4444444444px;
    font-size: 0.75rem;
    border-radius: 4px;
    letter-spacing: 0.0892857143em;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
}
.raffle-wheel__button:hover {
    background-color: aqua;
}
.raffle-wheel__button:before {
    background-color: transparent;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
</style>

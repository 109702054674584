<template>
<div>
    <v-form ref="form" lazy-validation>
        <v-card elevation="0">
            <v-toolbar flat>
                <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
                <v-toolbar-title>
                    Novo Chamado
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <router-link class="link" :to="{ name: 'Chamados'}">
                    <v-btn small color="secondary">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                        &nbsp;
                        Voltar
                    </v-btn>
                </router-link>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="createTicket()" small dark color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-bullhorn-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Enviar</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-row>
                    <v-col :cols="largura2">
                        <v-text-field type="text" outlined v-model="newTicket.titulo" label="Título" required></v-text-field>
                    </v-col>
                    <v-col :cols="largura1">
                        <v-select :items="items" label="Tipo" outlined v-model="newTicket.tipo"></v-select>
                    </v-col>
                    <v-col cols="4" v-if="isAdmin">
                      <v-combobox label="Usuários" v-model="searchUser" :items="getUsers" outlined>
                        <template v-slot:item="{ item }">
                          <span class="text-overline grey--text text--darken-3 mx-2">
                            {{ item.nomeCompleto + ' (' + item.email +')' }}
                           </span>
                         </template>
                         <template v-slot:selection="{ item }">
                           <span class="text-overline grey--text text--darken-3 mx-2">
                             {{ item.nomeCompleto }}
                           </span>
                        </template>
                      </v-combobox>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <vue-editor :editorToolbar="customToolbar" v-model="newTicket.descHTML" />
            </v-card-text>
            <v-card-actions>
                <v-file-input v-model="newTicket.files" label="Anexar Arquivos" multiple prepend-icon="mdi-paperclip" outlined :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="secondary" dark label small>
                            {{ text }}
                        </v-chip>
                        <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                            +{{ files.length - 2 }} Arquivo(s)
                        </span>
                    </template>
                </v-file-input>
            </v-card-actions>
        </v-card>
    </v-form>
</div>
</template>

<script>
export default {
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }]
      ],
      items: ['Civil 3D', 'Infraworks', 'Navisworks', 'AutoCAD 2D', 'AutoCAD 3D', 'Plugin: CADinfra', 'Plugin: C3DFarm', 'Plugin: Chronus', 'Catálogo de Peças', 'Country Kits', 'Cursos & Vídeos'],
      newTicket: {
        titulo: null,
        tipo: null,
        descHTML: null,
        profileId: null,
        files: []
      },
      user: null
    }
  },
  async created () {
    if (this.isAdmini && !this.$store.state.usersMembers.length > 0) {
      await this.$store.dispatch('getAllUsers')
    }
  },
  computed: {
    searchUser: {
      get () {
        return this.user
      },
      set (value) {
        try {
          if (value.profileId) {
            this.newTicket.profileId = value.profileId
          } else {
            this.newTicket.profileId = null
            this.user = value
          }
        } catch {}
      }
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getUsers () {
      if (this.user === null) {
        return this.$store.state.usersMembers.slice().sort(this.compare)
      } else {
        var users = this.$store.state.usersMembers.slice()
        return users.filter(U => {
          if (this.user.nomeCompleto) {
            return U.nomeCompleto.toLowerCase().match(this.user.nomeCompleto.toLowerCase()) || U.email.toLowerCase().match(this.user.email.toLowerCase())
          } else {
            return U.nomeCompleto.toLowerCase().match(this.user.toLowerCase()) || U.email.toLowerCase().match(this.user.toLowerCase())
          }
        }).sort(this.compare)
      }
    },
    profileId () {
      return this.$store.state.profileId
    },
    largura1 () {
      if (this.isAdmin) {
        return 3
      } else {
        return 6
      }
    },
    largura2 () {
      if (!this.isAdmin) {
        return 12 - this.largura1
      } else {
        return 5
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async createTicket () {
      if (this.newTicket.titulo !== null && this.newTicket.tipo !== null && this.newTicket.descHTML !== null) {
        if (!this.isAdmin) {
          this.newTicket.profileId = this.profileId
        }
        await this.$store.dispatch('createTicket', this.newTicket).then(() => this.$router.push({ name: 'Chamados' }))
      } else {
        alert('Verificar se Todos os Campos estão Preenchidos.')
      }
    },
    compare (profile1, profile2) {
      // Use toUpperCase() to ignore character casing
      const bandA = profile1.nomeCompleto.toUpperCase()
      const bandB = profile2.nomeCompleto.toUpperCase()

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    }
  }
}
</script>

<style>
</style>

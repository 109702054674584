<template>
<div>
    <v-form ref="form" lazy-validation>
        <v-card elevation="0">
            <v-toolbar flat>
                <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
                <v-toolbar-title>
                    Novo Download
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <router-link class="link" :to="{ name: 'Downloads'}">
                    <v-btn small color="secondary">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                        &nbsp;
                        Voltar
                    </v-btn>
                </router-link>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="createDownload()" small dark color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Subir</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        <v-select :items="produtos" label="Produto" outlined v-model="newDownload.produto" required></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="text" outlined v-model="newDownload.versaoProduto" :rules="rules" hide-details="auto" label="Versão do Produto" required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-actions class="px-10">
                <v-file-input v-model="newDownload.file" label="Anexar Arquivos" prepend-icon="mdi-paperclip" outlined :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="secondary" dark label small>
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-card-actions>
        </v-card>
    </v-form>
</div>
</template>

<script>
export default {
  data () {
    return {
      rules: [
        value => !!value || 'Requerido.',
        value => (value && value.length === 4) || 'Máximo de 4 characteres. Ex: 1.01'
      ],
      newDownload: {
        produto: null,
        versaoProduto: null,
        file: null
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    createDownload () {
      if (this.newDownload.produto && this.newDownload.versaoProduto.length === 4 && this.newDownload.file !== null) {
        this.$store.dispatch('createDownload', this.newDownload)
      }
    }
  },
  computed: {
    produtos () {
      return this.$store.state.produtos
    },
    versoesCivil () {
      return this.$store.state.versoesCivil
    }
  }
}
</script>

<style>
</style>

<template>
<v-card>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            &nbsp;
            Propagandas
        </v-toolbar-title>
        <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small dark color="primary" v-bind="attrs" v-on="on" @click="modal.modalNewAd = true">
                <v-icon>mdi-view-grid-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Nova Propaganda</span>
          </v-tooltip>
    </v-toolbar>
    <v-data-table sort-by="position" :headers="headers" :items="getAdvertisings" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há propagandas no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-slot:[`item.photoURL`]="{ item }">
            <v-btn text small @click="openIMG(item.photoURL)">
                <v-icon color="secondary"> mdi-eye-circle</v-icon>
                &nbsp; Visualizar
            </v-btn>
        </template>
        <template v-slot:[`item.cardURL`]="{ item }">
            <v-btn text small @click="openIMG(item.cardURL)">
                <v-icon color="secondary"> mdi-eye-circle</v-icon>
                &nbsp; Visualizar
            </v-btn>
        </template>
        <template v-slot:[`item.criadoEm`]="{ item }">
            {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.docId`]="{ item }">
            <v-btn text small @click="searchAdv(item.docId), modal.modalUp = true">
              <v-icon color="primary">mdi-dots-horizontal-circle-outline</v-icon>
            </v-btn>
            <v-btn text small @click="searchAdv(item.docId), modal.modalDel = true">
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <v-dialog v-model="modal.modalNewAd" max-width="400px" @click:outside="resetAdv()">
      <v-card>
        <v-card-text class="pb-0 pt-7">
          <v-row>
            <v-text-field type="text" outlined v-model="newAd.link" label="Link de Redirecionamento"/>
          </v-row>
          <v-row>
            <v-file-input v-model="newAd.photoFile" label="Imagem de Carrossel (1600x400)" prepend-icon="mdi-image-album" outlined :show-size="1000">
                <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="secondary" dark label small>
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>
          </v-row>
          <v-row>
            <v-file-input v-model="newAd.cardFile" label="Imagem de Card (400x200)" prepend-icon="mdi-image-album" outlined :show-size="1000">
                <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="secondary" dark label small>
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>
          </v-row>
        </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="resetAdv()">
                  Fechar
              </v-btn>
              <v-btn color="primary" text @click="newAdv()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal.modalIMG" max-width="800px" @click:outside="resetAdv()">
         <img :src="imgURL">
    </v-dialog>
    <v-dialog v-model="modal.modalUp" max-width="400px" @click:outside="resetAdv()">
      <v-card class="pt-5">
        <v-card-text v-if="adv !== null" class="pb-0">
          <v-row>
            <v-col cols="3">
              <v-text-field type="number" outlined v-model="adv.position" label="Posição"/>
            </v-col>
            <v-col cols="9">
              <v-text-field type="text" outlined v-model="adv.link" label="Link de Redirecionamento"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetAdv()">
              Fechar
          </v-btn>
          <v-btn color="primary" text @click="upAdv()">
              Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal.modalDel" max-width="550px" @click:outside="resetAdv()">
      <v-card>
            <v-card-title class="text-h6">
                Tem certeza que gostaria de excuir esta propaganda?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="resetAdv()">
                    Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="delAdv()">
                    Confirmar
                </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
</v-card>
</template>

<script>
export default {
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getAdvertisings () {
      return this.$store.state.advertisings
    }
  },
  data () {
    return {
      modal: {
        modalNewAd: false,
        modalIMG: false,
        modalUp: false,
        modalDel: false
      },
      imgURL: null,
      adv: null,
      oldPositionAdv: 0,
      newAd: {
        photoFile: null,
        cardFile: null,
        position: 0,
        link: null,
        docId: null
      },
      headers: [
        {
          text: 'N°',
          align: 'start',
          sortable: true,
          value: 'position'
        },
        {
          text: 'Carrossel',
          value: 'photoURL',
          sortable: false
        },
        {
          text: 'Cartão',
          value: 'cardURL',
          sortable: false
        },
        {
          text: 'Data',
          value: 'criadoEm'
        },
        {
          text: '',
          value: 'docId'
        }
      ]
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async newAdv () {
      if (this.newAd.photoFile !== null && this.newAd.cardFile !== null) {
        if (this.newAd.photoFile.name.includes('.png') || this.newAd.photoFile.name.includes('.jpg')) {
          if (this.newAd.cardFile.name.includes('.png') || this.newAd.cardFile.name.includes('.jpg')) {
            // const mPosition = this.getAdvertisings.slice().sort(this.comparePositions)[this.getAdvertisings.length - 1].position
            this.newAd.position = await this.getAdvertisings.length + 1
            this.newAd.docId = this.generateDocKey()
            await this.$store.dispatch('createAdvertising', this.newAd).then(() => {
              this.modal.modalNewAd = false
            })
          } else {
            alert('Escolha Somente Imagens do Tipo PNG ou JPG.')
          }
        } else {
          alert('Escolha Somente Imagens do Tipo PNG ou JPG.')
        }
      } else {
        alert('Favor Preencher Ambos os Inputs de Imagem.')
      }
    },
    async upAdv () {
      const filt = this.getAdvertisings.filter(Adv => {
        return Adv.position === parseInt(this.adv.position)
      })
      if (filt.length > 0) {
        console.log(this.oldPositionAdv)
        filt[0].position = this.oldPositionAdv
        await this.$store.dispatch('updateAdvertising', filt[0])
      }
      await this.$store.dispatch('updateAdvertising', this.adv).then(() => {
        this.modal.modalUp = false
      })
    },
    async delAdv () {
      await this.$store.dispatch('delAdvertising', this.adv).then(() => {
        this.modal.modalDel = false
      })
    },
    openIMG (URL) {
      this.imgURL = URL
      this.modal.modalIMG = true
    },
    resetAdv () {
      this.newAd.photoFile = null
      this.newAd.cardFile = null
      this.newAd.position = 0
      this.newAd.docId = null
      this.newAd.link = null
      this.imgURL = null
      this.adv = null
      this.modal.modalNewAd = false
      this.modal.modalIMG = false
      this.modal.modalUp = false
      this.modal.modalDel = false
    },
    generateDocKey () {
      var tokens = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz'
      var chars = 5
      var segments = 4
      var keyString = ''

      for (var i = 0; i < segments; i++) {
        var segment = ''
        for (var j = 0; j < chars; j++) {
          var k = Math.floor(Math.random() * (62))
          segment += tokens[k]
        }
        keyString += segment
      }
      return keyString
    },
    searchAdv (docId) {
      const filt = this.getAdvertisings.filter(Adv => {
        return Adv.docId === docId
      })[0]
      this.adv = filt
      this.oldPositionAdv = filt.position
    },
    comparePositions (adv1, adv2) {
      // Use toUpperCase() to ignore character casing
      const bandA = adv1.position
      const bandB = adv2.position

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    }
  },
  async created () {
    if (!this.$store.state.advertisings.length > 0) {
      await this.$store.dispatch('getAllAdvertisings')
    }
  }
}
</script>

<style>

</style>

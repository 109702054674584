<template>
<v-main>
    <v-container fluid class="fill-height" v-if="!logado">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="6" md="6">
                <v-row align="center" justify="center">
                    <v-col cols="12" md="10">
                        <v-card elevation="0">
                            <v-card-title class="d-flex justify-center">
                                <v-img :src="require('../assets/logo.png')" class="my-3" contain height="200" />
                            </v-card-title>
                            <v-window v-model="step">
                                <v-window-item :value="1">
                                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="signIn(), disabled = true">
                                        <v-card-text class="pb-0">
                                            <v-text-field prepend-icon="mdi-email" outlined v-model="email" :rules="emailRules" label="Endereço de Email" required></v-text-field>
                                            <v-text-field type="password" prepend-icon="mdi-lock" outlined v-model="password" label="Senha" required>
                                            </v-text-field>
                                        </v-card-text>
                                        <v-card-actions class="d-flex justify-center">
                                            <v-btn type="submit" color="primary" :disabled="disabled">Entrar</v-btn>
                                        </v-card-actions>
                                        <v-card-actions class="d-flex justify-center" v-if="errorLogin">
                                            <v-card-subtitle>Email ou senha inválido.</v-card-subtitle>
                                        </v-card-actions>
                                        <v-card-actions class="d-flex justify-center">
                                            <a @click="step = 2">Esqueceu a senha?</a>
                                        </v-card-actions>
                                        <v-divider></v-divider>
                                         <!--   <vue-recaptcha sitekey="6Lf1dm8fAAAAABS6IXbLITvsm2o0sBAbdjl7DkTk" @verify="verifyMethod"></vue-recaptcha>
                                        <br>-->
                                        <v-card-actions class="mb-3 mt-3">
                                            <v-row align="center" justify="center">
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="signInGoogle()" class="mx-2" dark color="red" v-bind="attrs" v-on="on">
                                                    <v-icon dark>
                                                        mdi-google
                                                    </v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Google</span>
                                              </v-tooltip>
                                              <router-link class="link" :to="{ name: 'Sorteio'}" v-if="showBtnRaffle && !fromApp">
                                              &nbsp;
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-2" dark color="purple" v-bind="attrs" v-on="on">
                                                    <v-icon dark>
                                                        mdi-emoticon-excited-outline
                                                    </v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Sorteio</span>
                                              </v-tooltip>
                                              </router-link>
                                            </v-row>
                                        </v-card-actions>
                                    </v-form>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="resetPassword()">
                                        <v-card-text>
                                            <v-text-field prepend-icon="mdi-email" outlined v-model="email" :rules="emailRules" label="Endereço de Email" required></v-text-field>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn type="submit" color="primary">Recuperar</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                        <v-card-actions v-if="msg">
                                            <v-spacer></v-spacer>
                                            <v-card-subtitle color="primary"> {{ msgReset }}</v-card-subtitle>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <a @click="step = 1">Voltar</a>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-form>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</v-main>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
/* import { VueRecaptcha } from 'vue-recaptcha' */
export default {
  /* components: { VueRecaptcha }, */
  data: () => ({
    disabled: false,
    step: 1,
    email: '',
    password: '',
    valid: true,
    errorLogin: false,
    msgReset: '',
    msg: true,
    nameRules: [
      v => !!v || 'A senha é obrigatória',
      v => (v && v.length <= 6) || 'A senha é inválida'
    ],
    emailRules: [
      v => !!v || 'O email é obrigatório',
      v => /.+@.+\..+/.test(v) || 'O email não é válido'
    ]
  }),
  methods: {
    signIn () {
      this.$store.dispatch('loading', true)
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
        this.disabled = false
        /* this.$router.push({ name: 'Home' }) */
      }).catch((error) => {
        if (error.code.includes('wrong-password')) {
          alert('Senha inválida.')
        } else {
          alert('Favor verificar sua conta com a adminstração.')
        }
        this.disabled = false
      })
      this.$store.dispatch('loading', false)
    },
    resetPassword () {
      this.$store.dispatch('loading', true)
      firebase.auth().sendPasswordResetEmail(this.email).then(() => {
        this.msgReset = 'Enviamos um email de recuperação.'
      }).catch(() => {
        this.msgReset = 'Não existe registros em nossa base de dados.'
      })
      this.msg = true
      this.$store.dispatch('loading', false)
    },
    admin (email) {
      this.$store.dispatch('admin', email)
    },
    async signInGoogle () {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
          // console.log(result)
          this.$store.dispatch('verifyAccount', result.user)
        }).catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    logado () {
      return this.$store.state.user
    },
    showBtnRaffle () {
      return this.$store.state.btnRaffle
    },
    fromApp () {
      return this.$store.state.fromApp
    }
  },
  async created () {
    await this.$store.dispatch('getBtnRaffle')
  }
}
</script>
<style>

</style>

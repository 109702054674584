<template>
  <Pie :chart-options="chartOptions" :chart-data="chartData" />
</template>
<script>
/* import { Doughnut } from 'vue-chartjs/legacy' */
import { Pie } from 'vue-chartjs/legacy'

export default {
  components: { Pie },
  computed: {
    chartOptions () {
      return {
        responsive: true, maintainAspectRatio: false
      }
    },
    chartData () {
      return {
        labels: [
          'Quentes',
          'Mornos',
          'Frios'
        ],
        datasets: [{
          label: 'Propostas',
          data: [this.Quentes.length, this.Mornos.length, this.Frios.length],
          backgroundColor: ['rgba(255, 159, 64, 0.7)', 'rgba(201, 203, 207, 0.7)', 'rgba(0, 255, 255, 0.7)'],
          borderColor: ['orange', 'gray', 'cyan'],
          borderWidth: 2,
          hoverOffset: 5
        }]
      }
    },
    getFollowUp () {
      return this.$store.state.masterHome.followUp.values
    },
    Quentes () {
      return this.getFollowUp.filter(T => {
        return T.qualification === 'Quente'
      })
    },
    Mornos () {
      return this.getFollowUp.filter(T => {
        return T.qualification === 'Morno'
      })
    },
    Frios () {
      return this.getFollowUp.filter(T => {
        return T.qualification === 'Frio'
      })
    }
  }
}
</script>
<style>
</style>

<template>
<v-card>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            &nbsp;
            Usuários
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link class="link" :to="{ name: 'NovoUsuario'}">
          &nbsp;
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small dark color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>Novo Usuário</span>
          </v-tooltip>
        </router-link>
        &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="green" v-bind="attrs" v-on="on" @click="modalExpExcel = true">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar Excel</span>
        </v-tooltip>
    </v-toolbar>
    <v-card-title>
        <v-switch class="justify-end d-flex" v-model="rulesAdm" inset v-if="isMaster" :label="labelRules">Teste</v-switch>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" label="Buscar" single-line hide-details outlined></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="setRules" :search="search" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há usuários no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-slot:[`item.profileId`]="{ item }">
            <router-link class="link" :to="{ name: 'VisualizarUsuario', params: { iduser: item.profileId } }">
                <v-btn text small>
                    <v-icon color="secondary"> mdi-eye-circle</v-icon>
                    &nbsp; Visualizar
                </v-btn>
            </router-link>
        </template>
        <template v-slot:[`item.criadoEm`]="{ item }">
            {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.photoURL`]="{ item }">
            <v-avatar width="40" height="40">
                <img :src="item.photoURL">
            </v-avatar>
        </template>
        <template v-slot:[`item.permissions`]="{ item }">
          <v-hover v-slot="{ hover }">
            <v-icon v-if="hover" color="green" @click="modalPermissoes = true, profileIdUser = item.profileId">mdi-lock-open-alert-outline</v-icon>
            <v-icon v-else color="blue">mdi-lock-alert-outline</v-icon>
          </v-hover>
        </template>
    </v-data-table>
    <v-dialog v-model="modalPermissoes" max-width="550px">
      <v-responsive class="overflow-y-auto" max-height="80vh">
        <v-card class="px-4">
        <v-card-text class="pb-0 pt-5">
          <v-row v-for="(item, i) in newPermissions" :key="i">
            <v-card-subtitle class="pb-0">
              <b>{{ item.titulo }}</b>
            </v-card-subtitle>
              <v-col cols="9">
                <v-menu v-model="item.modalMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="item.permDate" label="Modificar permissão" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.permDate"
                    @input="item.modalMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="d-flex pr-0" cols="2" v-if="item.permDate !== null">
                <v-switch :input-value="item.status" @change="item.status = !item.status" color="green" hide-details></v-switch>
              </v-col>
              <v-col class="px-0" cols="1" v-if="item.permDate !== null">
                <v-hover v-slot="{ hover }">
                  <v-icon v-if="hover" color="red" @click="modalDelPerm = true, permsId = item.permsId">mdi-delete</v-icon>
                  <v-icon v-else color="blue">mdi-delete</v-icon>
                </v-hover>
              </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="float-right" color="blue darken-1 right" text @click="updatePermission()">
              Atualizar Permissão
            </v-btn>
        </v-card-actions>
      </v-card>
      </v-responsive>
    </v-dialog>
    <v-dialog v-model="modalDelPerm" max-width="300px">
      <v-card>
          <v-card-title class="text-h6">
              Excluir Permissão?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="delPermission()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalExpExcel" max-width="500px">
      <v-card class="overflow-y-hidden">
        <v-card-text class="pt-5 pb-2">
        <v-row>
          <v-col cols="6">
          <v-select v-model="selPrimary" :items="primaryParams" label="Primário" outlined></v-select>
        </v-col>
        <v-col cols="6">
          <v-select v-if="selPrimary !== null" v-model="selSecondary" :items="secondaryParams" label="Secundário" outlined></v-select>
        </v-col>
        </v-row>
        </v-card-text>
          <v-card-actions v-if="selSecondary">
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="exportExcel()">
                  Exportar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
</v-card>
</template>

<script>
export default {
  computed: {
    isSales () {
      return this.$store.state.profile.rules.isSales
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getUsers () {
      return this.$store.state.usersMembers
    },
    getAdms () {
      return this.$store.state.adminMembers
    },
    setRules () {
      if (this.rulesAdm) {
        return this.getAdms
      } else {
        return this.getUsers
      }
    },
    labelRules () {
      if (this.rulesAdm) {
        return 'Administradores'
      } else {
        return 'Membros Comuns'
      }
    },
    getAllCourses () {
      return this.$store.state.courses
    },
    getPermissions () {
      return this.$store.state.permissions
    },
    newPermissions () {
      const nArray = []
      const oArray = []
      this.getPermissions.forEach(P => {
        let permDate = null
        let status = null
        const exist = P.existing.filter(E => {
          return E.profileId === this.profileIdUser
        })
        if (exist.length > 0) {
          permDate = (new Date(exist[0].expires)).toISOString().substr(0, 10)
          // eslint-disable-next-line no-const-assign
          status = exist[0].status
        }
        switch (P.permsId) {
          case 'duEJNQ0_QBCrtsAvCffkLQ':
            oArray.push({ titulo: 'Permissão de acesso aos Chamados', modalMenu: false, permDate: permDate, status: status, permsId: P.permsId })
            break
          case 'X90oRbgIkwW-xH4AMuKWpw':
            oArray.push({ titulo: 'Permissão de acesso aos Mapas', modalMenu: false, permDate: permDate, status: status, permsId: P.permsId })
            break
          default:
            if (this.getAllCourses.length > 0) {
              nArray.push({
                titulo: 'Permissão de acesso ao Curso ' + this.getAllCourses.filter(C => {
                  return C.courseId === P.permsId
                })[0].titulo,
                modalMenu: false,
                permDate: permDate,
                status: status,
                permsId: P.permsId
              })
            }
            break
        }
      })
      return oArray.concat(nArray)
    },
    primaryParams () {
      return ['Chamados', 'Cursos']
    },
    secondaryParams () {
      const nArray = []
      switch (this.selPrimary) {
        case 'Chamados':
          nArray.push('Todos Chamados')
          break
        case 'Cursos':
          if (this.getAllCourses.length > 0) {
            this.getAllCourses.forEach(C => {
              nArray.push(C.titulo)
            })
          }
          break
        default:
          break
      }
      return nArray
    },
    getTickets () {
      return this.$store.state.tickets
    }
  },
  data () {
    return {
      search: '',
      permsId: null,
      selPrimary: null,
      selSecondary: null,
      modalExpExcel: false,
      modalPermissoes: false,
      modalDelPerm: false,
      profileIdUser: null,
      rulesAdm: false,
      headers: [{
        text: '',
        value: 'profileId'
      },
      {
        text: '',
        align: 'right',
        sortable: false,
        value: 'photoURL'
      },
      {
        text: 'Nome Completo',
        align: 'start',
        sortable: true,
        value: 'nomeCompleto'
      },
      {
        text: 'Endereço de Email',
        value: 'email'
      },
      {
        text: 'Data',
        value: 'criadoEm'
      },
      {
        text: '',
        value: 'permissions',
        sortable: false,
        align: 'middle'
      }
      ]
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async updatePermission () {
      this.newPermissions.forEach(async N => {
        const perm = this.getPermissions.filter(P => {
          return P.permsId === N.permsId
        })[0]
        const exist = perm.existing.filter(E => {
          return E.profileId === this.profileIdUser
        })
        if (N.permDate !== null) {
          if (N.status === null) {
            N.status = true
          }
          if (exist.length > 0) {
            exist[0].status = N.status
            exist[0].expires = new Date(N.permDate).getTime()
          } else {
            perm.existing.push({
              criadoEm: new Date(),
              expires: new Date(N.permDate).getTime(),
              profileId: this.profileIdUser,
              status: N.status
            })
          }
          await this.$store.dispatch('updatePermission', N.permsId)
        }
      })
    },
    async delPermission () {
      if (this.permId !== null) {
        const perm = this.getPermissions.filter(P => {
          return P.permsId === this.permsId
        })[0]
        perm.existing = perm.existing.filter(E => {
          return E.profileId !== this.profileIdUser
        })
        await this.$store.dispatch('updatePermission', this.permsId).then(() => {
          this.modalDelPerm = false
          this.permsId = null
        })
      }
    },
    exportExcel () {
      if (this.selPrimary === null || !this.selSecondary === null) {
        return
      }
      const data = []
      switch (this.selPrimary) {
        case 'Chamados':
          var perm = this.getPermissions.filter(P => {
            return P.permsId === 'duEJNQ0_QBCrtsAvCffkLQ'
          })[0]
          perm.existing.forEach(E => {
            var users = this.getUsers.filter(U => {
              return U.profileId === E.profileId
            })
            if (users.length > 0) {
              var tickets = this.getTickets.filter(T => {
                return T.profileId === E.profileId
              })
              data.push({
                Nome: users[0].nomeCompleto,
                Email: users[0].email,
                Contato: users[0].telCelular,
                DataCriacao: new Date(E.criadoEm),
                DataExpiracao: new Date(E.expires),
                QuantidadeChamados: tickets.length
              })
            }
          })
          break
        case 'Cursos':
          var courses = this.getAllCourses.filter(C => {
            return C.titulo === this.selSecondary
          })[0]
          var ss = this.getPermissions.filter(P => {
            return P.permsId === courses.courseId
          })[0]
          ss.existing.forEach(E => {
            var users = this.getUsers.filter(U => {
              return U.profileId === E.profileId
            })
            if (users.length > 0) {
              data.push({
                Nome: users[0].nomeCompleto,
                Email: users[0].email,
                Contato: users[0].telCelular,
                DataCriacao: new Date(E.criadoEm),
                DataExpiracao: new Date(E.expires)
              })
            }
          })
          break
      }

      if (data.length > 0) {
        import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ json2excel }) => {
          json2excel({
            data,
            name: this.selSecondary,
            formateDate: 'dd/mm/yyyy'
          })
        }).catch((e) => {
          console.log(e)
        })
      } else {
        console.log('Nenhum resultado encontrado.')
      }
    }
  },
  watch: {
    modalPermissoes: function (newValue, old) {
      if (!newValue) {
        this.modalMenu = false
        this.profileIdUser = null
      }
    }
  },
  async created () {
    if (!this.$store.state.usersMembers.length > 0) {
      await this.$store.dispatch('getAllUsers')
    }
    if (!this.$store.state.permissions.length > 0) {
      await this.$store.dispatch('getAllPermissions')
    }
    if (!this.$store.state.courses.length > 0) {
      await this.$store.dispatch('getAllCourses')
    }
    if (!this.isSales) {
      if (!this.$store.state.adminMembers.length > 0) {
        await this.$store.dispatch('getAllAdmins')
      }
    }
  }
}
</script>

<style>

</style>

<template>
<div>
    <v-toolbar flat>
      <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
      <v-toolbar-title>
          Página Principal
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card elevation="0" class="mt-2" v-if="charts">
      <v-row justify="center">
        <v-col cols="3">
          <v-menu v-model="menus.menuModalS" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="setDateS" label="Data Início" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="setDateS" @input="menus.menuModalS = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu :disabled="setDateS === null" v-model="menus.menuModalE" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="setDateE" label="Data Final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="setDateE" @input="menus.menuModalE = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="pa-5">
        <v-col cols="6">
          <ChartAll/>
        </v-col>
        <v-col cols="6">
          <Doughnut/>
        </v-col>
      </v-row>
    </v-card>
</div>
</template>

<script>
import ChartAll from '../components/charts/ChartAll'
import Doughnut from '../components/charts/Doughnut'

export default {
  components: { ChartAll, Doughnut },
  data () {
    return {
      menus: {
        menuModalS: false,
        menuModalE: false
      }
    }
  },
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    charts () {
      return this.$store.state.home.charts
    },
    setDateS: {
      get () {
        return this.setTimeLapse.dateS
      },
      set (value) {
        const newValue = new Date(new Date(value).getTime() + 10800000)
        if (newValue.getTime() < new Date().getTime()) {
          if (newValue.getTime() < new Date(this.setDateE).getTime()) {
            this.setTimeLapse = { dateS: new Date(newValue).getFullYear().toString() + '-' + (new Date(newValue).getMonth() + 1).toString() + '-' + (new Date(newValue).getDate()).toString() }
          }
        }
      }
    },
    setDateE: {
      get () {
        return this.setTimeLapse.dateE
      },
      set (value) {
        const newValue = new Date(new Date(value).getTime() + 10800000)
        if (newValue.getTime() <= new Date().getTime()) {
          if (newValue.getTime() > new Date(new Date(this.setDateS).getTime() + 10800000).getTime()) {
            this.setTimeLapse = { dateE: new Date(newValue).getFullYear().toString() + '-' + (new Date(newValue).getMonth() + 1).toString() + '-' + (new Date(newValue).getDate()).toString() }
          }
        }
      }
    },
    setTimeLapse: {
      get () {
        return this.$store.state.timeLapse
      },
      set (value) {
        if (value.dateS) {
          this.$store.state.timeLapse.dateS = value.dateS
        } else {
          this.$store.state.timeLapse.dateE = value.dateE
        }
        try {
          this.$root.$emit('BarCharA')
          this.$root.$emit('BarCharF')
          this.$root.$emit('BarCharN')
          this.$root.$emit('BarCharR')
          this.$root.$emit('Doughnut')
        } catch {
        }
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    redirectURL (url) {
      window.open(url, '_blank')
    }
  },
  async created () {
    // alert('Informamos que entre os dias 14 e 15 de novembro não haverá expediente, estando suspensas nossas atividades em razão do feriado para nossos funcionários. \n\n Atenciosamente CSBIM')
    // alert('Informamos que entre os dias 19/12 á 03/01 entraremos em recesso coletivo.\n\n Atenciosamente CSBIM')
  }
}
</script>
<style>
</style>

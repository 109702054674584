<template>
    <div>
        <v-toolbar flat>
          <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
          <v-toolbar-title>
              Página Principal
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card elevation="0" class="mt-2">
          <v-carousel hide-delimiters cycle height="400" hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="(slide, i) in getAdvertisings" :key="i" :src="slide.photoURL" class="hoverIMG" @click="redirectURL(slide.link)"></v-carousel-item>
          </v-carousel>
          <v-row class="mt-2">
            <v-col cols="3" v-for="(slide, i) in getAdvertisings" :key="i" class="d-flex justify-center">
              <v-card width="400px">
                <v-img class="hoverIMG" :src="slide.cardURL" height="200px" @click="redirectURL(slide.link)"></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
    </div>
    </template>

<script>

export default {
  data () {
    return {
    }
  },
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getAdvertisings () {
      if (!this.isAdmin && this.$store.state.advertisings.length > 0) {
        return this.$store.state.advertisings
      } else {
        return null
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    redirectURL (url) {
      window.open(url, '_blank')
    }
  },
  async created () {
    /* if (!this.$store.state.advertisings.length > 0) {
      await this.$store.dispatch('getAllAdvertisings')
    } */
    // alert('Informamos que entre os dias 14 e 15 de novembro não haverá expediente, estando suspensas nossas atividades em razão do feriado para nossos funcionários. \n\n Atenciosamente CSBIM')
    // alert('Informamos que entre os dias 19/12 á 03/01 entraremos em recesso coletivo.\n\n Atenciosamente CSBIM')
  }
}
</script>
    <style>
    .hoverIMG {
      opacity: 0.9;
      cursor: pointer;
    }
    .hoverIMG:hover {
      opacity: 1;
    }
    </style>

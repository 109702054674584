var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.changeNav()}}}),_c('v-toolbar-title',[_vm._v("   Usuários ")]),_c('v-spacer'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'NovoUsuario'}}},[_vm._v("   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}])},[_c('span',[_vm._v("Novo Usuário")])])],1),_vm._v("   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"green"},on:{"click":function($event){_vm.modalExpExcel = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar Excel")])])],1),_c('v-card-title',[(_vm.isMaster)?_c('v-switch',{staticClass:"justify-end d-flex",attrs:{"inset":"","label":_vm.labelRules},model:{value:(_vm.rulesAdm),callback:function ($$v) {_vm.rulesAdm=$$v},expression:"rulesAdm"}},[_vm._v("Teste")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.setRules,"search":_vm.search,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }},scopedSlots:_vm._u([{key:"item.profileId",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'VisualizarUsuario', params: { iduser: item.profileId } }}},[_c('v-btn',{attrs:{"text":"","small":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-eye-circle")]),_vm._v("   Visualizar ")],1)],1)]}},{key:"item.criadoEm",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }))+" ")]}},{key:"item.photoURL",fn:function(ref){
      var item = ref.item;
return [_c('v-avatar',{attrs:{"width":"40","height":"40"}},[_c('img',{attrs:{"src":item.photoURL}})])]}},{key:"item.permissions",fn:function(ref){
      var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(hover)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){_vm.modalPermissoes = true, _vm.profileIdUser = item.profileId}}},[_vm._v("mdi-lock-open-alert-outline")]):_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-lock-alert-outline")])]}}],null,true)})]}}],null,true)},[_c('template',{slot:"no-data"},[_c('small',[_c('v-icon',[_vm._v("mdi-check")]),_vm._v("   Não há usuários no momento. ")],1)]),_c('template',{slot:"no-results"},[_c('small',[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v("   Nenhum resultado encontrado. ")],1)])],2),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.modalPermissoes),callback:function ($$v) {_vm.modalPermissoes=$$v},expression:"modalPermissoes"}},[_c('v-responsive',{staticClass:"overflow-y-auto",attrs:{"max-height":"80vh"}},[_c('v-card',{staticClass:"px-4"},[_c('v-card-text',{staticClass:"pb-0 pt-5"},_vm._l((_vm.newPermissions),function(item,i){return _c('v-row',{key:i},[_c('v-card-subtitle',{staticClass:"pb-0"},[_c('b',[_vm._v(_vm._s(item.titulo))])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Modificar permissão","prepend-icon":"mdi-calendar","readonly":""},model:{value:(item.permDate),callback:function ($$v) {_vm.$set(item, "permDate", $$v)},expression:"item.permDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.modalMenu),callback:function ($$v) {_vm.$set(item, "modalMenu", $$v)},expression:"item.modalMenu"}},[_c('v-date-picker',{on:{"input":function($event){item.modalMenu = false}},model:{value:(item.permDate),callback:function ($$v) {_vm.$set(item, "permDate", $$v)},expression:"item.permDate"}})],1)],1),(item.permDate !== null)?_c('v-col',{staticClass:"d-flex pr-0",attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"input-value":item.status,"color":"green","hide-details":""},on:{"change":function($event){item.status = !item.status}}})],1):_vm._e(),(item.permDate !== null)?_c('v-col',{staticClass:"px-0",attrs:{"cols":"1"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(hover)?_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){_vm.modalDelPerm = true, _vm.permsId = item.permsId}}},[_vm._v("mdi-delete")]):_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-delete")])]}}],null,true)})],1):_vm._e()],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"color":"blue darken-1 right","text":""},on:{"click":function($event){return _vm.updatePermission()}}},[_vm._v(" Atualizar Permissão ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.modalDelPerm),callback:function ($$v) {_vm.modalDelPerm=$$v},expression:"modalDelPerm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Excluir Permissão? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.delPermission()}}},[_vm._v(" Confirmar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.modalExpExcel),callback:function ($$v) {_vm.modalExpExcel=$$v},expression:"modalExpExcel"}},[_c('v-card',{staticClass:"overflow-y-hidden"},[_c('v-card-text',{staticClass:"pt-5 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.primaryParams,"label":"Primário","outlined":""},model:{value:(_vm.selPrimary),callback:function ($$v) {_vm.selPrimary=$$v},expression:"selPrimary"}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.selPrimary !== null)?_c('v-select',{attrs:{"items":_vm.secondaryParams,"label":"Secundário","outlined":""},model:{value:(_vm.selSecondary),callback:function ($$v) {_vm.selSecondary=$$v},expression:"selSecondary"}}):_vm._e()],1)],1)],1),(_vm.selSecondary)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" Exportar ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
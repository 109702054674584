<template>
<div>
    <v-form ref="form" lazy-validation>
        <v-card>
            <v-toolbar flat>
                <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
                <v-toolbar-title>
                    Novo Curso
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <router-link class="link" :to="{ name: 'Cursos'}">
                    <v-btn small color="secondary">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                        &nbsp;
                        Voltar
                    </v-btn>
                </router-link>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="criaCurso()" small dark color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-send-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Enviar</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
              <v-row>
                    <v-col cols="4">
                        <v-text-field type="text" outlined v-model="newCourse.titulo" label="Título" required></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-select :items="items" label="Tipo" outlined v-model="newCourse.tipo"></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-select :items="adminMembers" label="Instrutor" outlined v-model="newCourse.teacher">
                            <template v-slot:item="{ item }">
                                <span class="text-overline grey--text text--darken-3 mx-2">
                                    {{ item.nomeCompleto + ' (' + item.email +')' }}
                                </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span class="text-overline grey--text text--darken-3 mx-2">
                                    {{ item.nomeCompleto }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="text" outlined v-model="newCourse.descSimples" label="Descrição (Simples)" required></v-text-field>
                    </v-col>
              </v-row>
                <v-row class="CBS">
                  <v-col cols="4">
                    <v-combobox v-model="newCourse.linguagem" :items="languages" label="Linguagem do Vídeo" outline dense hide-selected></v-combobox>
                  </v-col>
                  <v-col cols="4">
                    <v-combobox v-model="newCourse.legendas" :items="languages" label="Legendas Disponíveis" multiple outlined dense hide-selected></v-combobox>
                  </v-col>
                   <v-col cols="4">
                    <v-combobox v-model="newCourse.auxiliares" label="Auxiliares" :items="adminMembers" multiple outlined dense>
                      <template v-slot:item="{ item }">
                        <span class="text-overline grey--text text--darken-3 mx-2">
                          {{ item.nomeCompleto + ' (' + item.email +')' }}
                         </span>
                       </template>
                       <template v-slot:selection="{ item }">
                         <span class="text-overline grey--text text--darken-3 mx-2">
                           {{ item.nomeCompleto }}
                         </span>
                      </template>
                     </v-combobox>
                   </v-col>
                 </v-row>
            </v-card-title>
            <v-card-text>
                <vue-editor :editorToolbar="customToolbar" v-model="newCourse.descHTML" />
            </v-card-text>
            <v-card-actions>
                <v-file-input v-model="newCourse.file" label="Capa (370x250)" prepend-icon="mdi-image-album" outlined :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="secondary" dark label small>
                            {{ text }}
                        </v-chip>
                        <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                            +{{ files.length - 2 }} Arquivo(s)
                        </span>
                    </template>
                </v-file-input>
            </v-card-actions>
        </v-card>
    </v-form>
</div>
</template>

<script>
export default {
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }],
        [{
          color: []
        }]
      ],
      items: ['AutoCAD Civil 3D', 'Infraworks', 'Navisworks', 'AutoCAD'],
      newCourse: {
        titulo: null,
        tipo: null,
        descSimples: null,
        descHTML: null,
        teacher: null,
        file: null,
        legendas: [],
        linguagem: null,
        auxiliares: []
      },
      languages: [
        'Português',
        'Inglês',
        'Espanhol',
        'Chinês',
        'Francês',
        'Russo'
      ]
    }
  },
  computed: {
    profileId () {
      return this.$store.state.profile.Id
    },
    adminMembers () {
      return this.$store.state.adminMembers
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async criaCurso () {
      if (this.isAdmin && this.newCourse.titulo !== null && this.newCourse.tipo !== null && this.newCourse.teacher !== null && this.newCourse.descSimples !== null && this.newCourse.linguagem !== null && this.newCourse.file !== null) {
        const auxiliares = []
        this.newCourse.auxiliares.forEach(A => {
          auxiliares.push({
            cargo: A.cargo,
            nomeCompleto: A.nomeCompleto,
            photoURL: A.photoURL,
            profileId: A.profileId
          })
        })
        this.newCourse.auxiliares = auxiliares
        await this.$store.dispatch('createCourse', this.newCourse)
      } else {
        alert('Únicos campos não obrigatórios: Legendas Disponíveis, Auxiliares e Descrição Completa.')
      }
    }
  }
}
</script>

<style>
/* .CBS .v-input__slot{
  height: 56px !important;
} */
</style>

<template>
  <div class="content">
    <!-- <v-sheet elevation="6" style="height: 100px">
      <v-tabs v-model="tab" background-color="#c95454" dark next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
        <v-tabs-slider color="black"></v-tabs-slider>
        <v-tab v-for="item in itemsTab" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn class="ma-2 secondary" @click="getSourcesDraw()" larg color="white" v-bind="attrs" v-on="on">
                    <img src="https://img.icons8.com/color/32/000000/border-color.png"/>
                </v-btn>
              </template>
              <span>Projetar Linhas</span>
            </v-tooltip>
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn class="ma-2 secondary" @click="suavizarLinhas()" larg color="white" v-bind="attrs" v-on="on">
                    <img src="https://img.icons8.com/color/32/000000/border-color.png"/>
                </v-btn>
              </template>
              <span>Suavizar Linhas</span>
            </v-tooltip>
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn class="ma-2 secondary" @click="cortarLinhas()" larg color="white" v-bind="attrs" v-on="on">
                    <img src="https://img.icons8.com/color/32/000000/border-color.png"/>
                </v-btn>
              </template>
              <span>Cortar Linhas</span>
            </v-tooltip>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet> -->
    <MglMap :map-style.sync="mapStyle" @load="onMapLoaded" :attributionControl="false">
      <MglSource id="JsonFonte" type='geojson' :data='geoJsonSource.data'>
        <!-- <MglLayer id="JsonFonte" type='line' :layout=" { 'line-join': 'round', 'line-cap': 'round'}" :paint="{ 'line-color': '#888', 'line-width': 2}"/> -->
        <MglLayer id="LayerLine" type='line' :paint="paintLayerLine" :layout=" { 'line-join': 'round', 'line-cap': 'round'}" />
        <MglLayer id="JsonFonte" type='fill' :paint="paintJsonFonte"/>
        <MglLayer id="JsonPoint" type='circle' :paint="paintJsonPoint"/>
      </MglSource>
      <!-- <MglSource id="mapbox-dem" type='raster-dem' url='mapbox://mapbox.mapbox-terrain-dem-v1' tileSize='512' maxzoom='14'></MglSource> -->
      <MglScaleControl position="top-left"/> <!-- Input de Distancia do Chão -->
      <MglGeolocateControl position="top-right"/> <!-- Minha Localização -->
      <MglNavigationControl position="top-right"/> <!-- Zoom -->
      <MglPitchControl position="top-right"/> <!-- 2D/3D -->
      <MglCustomControl position="bottom-right" :containerProps="{ class: 'mapboxgl-ctrl' }">
        <v-speed-dial v-model="fab" :top="false" :bottom="true" :right="true" :left="false" :direction="'top'" :open-on-hover="true" :transition="'slide-y-reverse-transition'">
            <template v-slot:activator>
                <v-btn v-model="fab" color="blue darken-2" dark fab>
                    <v-icon v-if="fab">mdi-package-variant</v-icon>
                    <v-icon v-else>mdi-package-variant-closed</v-icon>
                </v-btn>
            </template>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="modal.ajustes = true" fab small color="white" v-bind="attrs" v-on="on">
                    <img width="50%" src="https://img.icons8.com/color/48/000000/gear.png" />
                </v-btn>
              </template>
              <span>Configuração</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="modal.tematizar = true" fab small color="white" v-bind="attrs" v-on="on">
                    <img width="50%" src="https://img.icons8.com/color/48/000000/color-palette.png" />
                </v-btn>
              </template>
              <span>Tematização</span>
            </v-tooltip>
            <!-- <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="modal.print = true" fab small color="white" v-bind="attrs" v-on="on">
                    <img width="50%" src="https://img.icons8.com/color/48/000000/print.png" />
                </v-btn>
              </template>
              <span>Tirar Print</span>
            </v-tooltip> -->
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="modal.listagem = true, getFeaturesTable()" fab small color="white" v-bind="attrs" v-on="on">
                    <img width="50%" src="https://img.icons8.com/color/48/000000/bulleted-list.png"/>
                </v-btn>
              </template>
              <span>Tabela de Atributos</span>
            </v-tooltip>
            <!-- <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="modal.pesquisar = true" fab small color="white" v-bind="attrs" v-on="on">
                    <img width="50%" src="https://img.icons8.com/color/48/000000/search.png" />
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip> -->
        </v-speed-dial>
      </MglCustomControl>
      <MglCustomControl position="bottom-left" :containerProps="{ class: 'mapboxgl-ctrl' }">
          <v-chip color="#c0c0c0"><input type="text" id="cursorCoordinates" style="text-align: center;width: 300px; font-weight: bold;"/></v-chip>
      </MglCustomControl>
      <MglPopup :lnglat='popUp.coordinates' :show='showMapPopUp' style="width: 600px;">
        <v-card elevation="0">
          <v-card-title class="d-flex justify-center">
            <b>Propriedades</b>
          </v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-row>
                <v-col v-for="(items, i) in popUp.items" :key="i">
                  <p v-for="(item, ii) in items" :key="ii"><b>{{item.prop}}:</b> {{item.value}}</p>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </MglPopup>
        <!-- JANELA DE AJUSTES -->
        <v-dialog v-model="modal.ajustes" max-width="900px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">Abrir</v-btn>
            </template>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-card-title>
                            <span class="headline">Estilos & Textos</span>
                        </v-card-title>
                        <v-row>
                            <v-col cols="4" sm="4">
                                <v-select v-model="setMapStyle"
                                    :items="['Ruas', 'Claro', 'Escuro', 'Exterior', 'Satélite', 'Satélite Ruas']" outlined label="Estilo do Mapa"
                                    required></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-autocomplete v-model="ajustes.labelItems" :items="items" label="Textos" multiple
                                    outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select v-model="ajustes.textHeight"
                                    :items="[6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]" outlined
                                    label="Tamanho do Texto" required></v-select>
                            </v-col>
                        </v-row>
                        <!-- <v-divider></v-divider>
                        <v-switch label="Habilitar" color="info" v-model="ajustes.coresAjustes"></v-switch>
                        <v-card-title>
                            <span class="headline">Cores</span>
                        </v-card-title>
                        <v-row align="center">
                            <v-col cols="6" sm="6">
                                <label for="cor"> Preenchimento</label><br>
                                <v-color-picker v-model="ajustes.corPreenchimento" id="cor" name="cor" class="ma-2"
                                    hide-inputs></v-color-picker>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <label for="cor"> Contorno</label><br>
                                <v-color-picker v-model="ajustes.corContorno" id="cor" name="cor" class="ma-2"
                                    hide-inputs></v-color-picker>
                            </v-col>
                        </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="modal.ajustes = false">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text @click="setSettings()">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- JANELA DE TEMATIZAÇÃO -->
        <v-dialog v-model="modal.tematizar" max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">Abrir</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Tematização</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-switch @change="resetarTema()" v-model="tematizar.aplicar" class="ma-4"
                                        label="Aplicar Tematização">
                                    </v-switch>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-autocomplete @change="createRanges()" v-model="tematizar.atributo" :items="items"
                                        label="Atributo" outlined :readonly="!tematizar.aplicar"></v-autocomplete>
                                </v-col>
                                <v-col v-show="tematizar.isNumeric" cols="12" sm="6" md="6">
                                    <v-select @change="createRanges()" v-model="tematizar.nFaixas"
                                        :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" outlined label="Nº Faixas"
                                        required></v-select>
                                </v-col>
                                <v-col v-if="tematizar.aplicar && tematizar.isNumeric" cols="12" sm="12" md="12">
                                    <v-simple-table :dense="true" :fixed-header="true" height="300">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Cor</th>
                                                    <th class="text-left">Mínimo</th>
                                                    <th class="text-left">Máximo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in tematizar.faixas" :key="item.id">
                                                    <td>
                                                        <v-rating full-icon="mdi-checkbox-blank" half-icon="
                                                    mdi-checkbox-blank" empty-icon="mdi-checkbox-blank" length="1"
                                                            :color="item.color" :value="item.value">
                                                        </v-rating>
                                                    </td>
                                                    <td>{{item.min}}</td>
                                                    <td>{{item.max}}</td>
                                                </tr>

                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>

                                <v-col v-if="tematizar.aplicar && tematizar.isString" cols="12" sm="12" md="12">
                                    <v-simple-table :dense="true" :fixed-header="true" height="300">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Cor</th>
                                                    <th class="text-left">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in tematizar.faixasString" :key="item.id">
                                                    <td>
                                                        <v-rating full-icon="mdi-checkbox-blank" half-icon="
                                                    mdi-checkbox-blank" empty-icon="mdi-checkbox-blank" length="1"
                                                            :color="item.color" :value="item.value">
                                                        </v-rating>
                                                    </td>
                                                    <td>{{item.text}}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="modal.tematizar = false">Cancelar</v-btn>
                        <v-btn color="blue darken-1" text @click="aplicarTema()">Aplicar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <!-- JANELA DE IMPRESSÁO -->
        <v-dialog v-model="modal.print" max-width="600px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">Abrir</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">Impressão</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col v-show="print.processando" class="text-center" cols="12" sm="12" md="12">
                            <v-progress-circular :size="70" :width="7" color="primary"
                                indeterminate></v-progress-circular>
                            </v-col>
                            <v-col v-show="!print.processando" cols="12" sm="6" md="6">
                                <v-autocomplete v-model="print.folha" :items="['A4','A3','A2','A1']" label="Folha"
                                    outlined></v-autocomplete>
                            </v-col>
                            <v-col v-show="!print.processando" cols="12" sm="6" md="6">
                                <v-select v-model="print.orientacao" :items="['Retrato', 'Paisagem']" outlined
                                    label="Orientação" required></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogPrint = false">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text @click="criaPDF()">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- JANELA DE PESQUISA -->
        <!-- <v-dialog v-model="modal.pesquisar" max-width="600px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
            </template>
        <v-card>
            <v-card-title>
                <span class="headline">Pesquisar</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-combobox :items="items" label="Atributo" outlined></v-combobox>
                        </v-col>
                        <v-col cols="6">
                            <v-combobox :items="itemsOp" label="Operador" outlined></v-combobox>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined label="Valor*" required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-combobox :items="itemsOp" label="Valor" outlined></v-combobox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="modal.pesquisar = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="dialog = false">Pesquisar</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog> -->
        <!-- JANELA DE LISTAGEM DE ATRIBUTOS -->
        <v-dialog v-model="modal.listagem" max-width="80%">
          <v-card class="py-3">
            <v-simple-table height="800px" dense fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      ID
                    </th>
                    <th v-for="(item, i) in items" :key="i" class="text-left">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in featuresJson" :key="item.name">
                    <td>{{ i + 1 }}</td>
                    <td v-for="(prop, ii) in items" :key="ii">{{ item.properties[prop] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-dialog>
    </MglMap>
    <!-- <v-container fluid class="pa-0">
      <v-textarea v-model="prompt" color="teal" background-color="white" filled style="height: 10px !important">
        <template v-slot:label>
          <div>
            Prompt de Comando <small>(optional)</small>
          </div>
        </template>
      </v-textarea>
    </v-container> -->
    </div>
</template>

<script>
import {
  MglAttributionControl,
  MglControlGroup,
  MglFullscreenControl,
  MglGeolocateControl,
  MglNavigationControl,
  MglScaleControl,
  MglCustomControl,
  MglFlyToControl,
  MglPitchControl,
  MglLayer,
  MglSource,
  mgl
} from 'vue-mgl'

// eslint-disable-next-line no-unused-vars
import * as turf from '@turf/turf'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
// import printPdf from 'mapbox-print-pdf'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MglLayer, MglSource, MglAttributionControl, MglControlGroup, MglFullscreenControl, MglGeolocateControl, MglNavigationControl, MglScaleControl, MglCustomControl, MglFlyToControl, MglPitchControl
  },
  data () {
    return {
      paintLayerLine: { 'line-color': ['get', 'Color'], 'line-width': ['get', 'Width'] },
      paintJsonFonte: { 'fill-opacity': 0, 'fill-color': ['get', 'Color'] },
      paintJsonPoint: { 'circle-color': ['get', 'Color'], 'circle-radius': ['get', 'Width'] },
      Source: null,
      featuresJson: [],
      prompt: '',
      tab: null,
      itemsTab: [
        'Projetos', 'Anotações', 'Analises', 'Exportações'
      ],
      items: [],
      itemsOp: ['=', '<', '>', '!='],
      modal: {
        ajustes: false,
        tematizar: false,
        print: false,
        pesquisar: false,
        listagem: false
      },
      showMapPopUp: false,
      mapStyle: 'mapbox://styles/mapbox/satellite-v9',
      popUp: {
        coordinates: [0, 0],
        items: []
      },
      drawTools: new MapboxDraw(),
      fab: false,
      popupTools: [],
      ajustes: {
        coresAjustes: false,
        center: null,
        textHeight: 14,
        labelItems: null,
        corPreenchimento: {
          r: 255,
          g: 255,
          b: 255,
          a: 0.5
        },
        corContorno: {
          r: 255,
          g: 0,
          b: 0,
          a: 1
        },
        zoom: 15
      },
      tematizar: {
        aplicar: false,
        faixas: [],
        atributo: '',
        nFaixas: 5,
        isNumeric: false,
        isString: false,
        faixasString: []
      },
      print: {
        folha: 'A4',
        orientacao: 'Retrato',
        processando: false
      }
    }
  },
  computed: {
    geoJsonSource () {
      return this.$store.state.jsonSources.filter(Source => {
        return Source.docId === this.$route.params.docId
      })[0]
    },
    setMapStyle: {
      get () {
        switch (this.mapStyle) {
          case 'mapbox://styles/mapbox/streets-v11':
            return 'Ruas'
          case 'mapbox://styles/mapbox/light-v10':
            return 'Claro'
          case 'mapbox://styles/mapbox/dark-v10':
            return 'Escuro'
          case 'mapbox://styles/mapbox/outdoors-v11':
            return 'Exterior'
          case 'mapbox://styles/mapbox/satellite-v9':
            return 'Satélite'
          case 'mapbox://styles/mapbox/satellite-streets-v11':
            return 'Satélite Ruas'
          default:
            return 'Ruas'
        }
      },
      async set (value) {
        switch (value) {
          case 'Ruas':
            this.mapStyle = 'mapbox://styles/mapbox/streets-v11'
            break
          case 'Claro':
            this.mapStyle = 'mapbox://styles/mapbox/light-v10'
            break
          case 'Escuro':
            this.mapStyle = 'mapbox://styles/mapbox/dark-v10'
            break
          case 'Exterior':
            this.mapStyle = 'mapbox://styles/mapbox/outdoors-v11'
            break
          case 'Satélite':
            this.mapStyle = 'mapbox://styles/mapbox/satellite-v9'
            break
          case 'Satélite Ruas':
            this.mapStyle = 'mapbox://styles/mapbox/satellite-streets-v11'
            break
          default:
            this.mapStyle = 'mapbox://styles/mapbox/streets-v11'
            break
        }
      }
    }
  },
  methods: {
    // Carregamento do Mapa
    async onMapLoaded ({ map }) {
      this.map = await map

      // this.map.addSource('JsonFonte', { type: 'geojson', data: this.geoJsonSource.data })

      // EVENTOS DE INICIALIZAÇÃO DO JSON
      await this.zoomMap()
      await this.showPopUp()
      await this.mouseEnter()
      await this.mouseLeave()
      await this.mouseMove()
      // REALIZA A OBTENÇÃO DOS ATRIBUTOS PARA O COMBOBOX
      await this.map.on('render', this.moveEnd)
      // REALIZA A CONFIGURAÇÃO DA LINGUAGEM DO MAPA
      this.map.addControl(new MapboxLanguage())
      // ADICIONA OS CONTROLES COM AS FERRAMENTAS DE DESENHO
      this.map.addControl(this.drawTools, 'top-left')
      // ASSOCIA OS EVENTOS DE CRIAÇÃO, APAGAR E ATUALIZAÇÃO A FUNÇÃO
      this.map.on('draw.create', this.updateTools)
      this.map.on('draw.delete', this.updateTools)
      this.map.on('draw.update', this.updateTools)

      /* this.map.setTerrain({ source: 'mapbox-dem', exaggeration: 2 }) */
    },
    // Eventos Mapa
    async mouseMove () {
      await this.map.on('mousemove', function (e) {
        const latitude = e.lngLat.wrap().lat
        const longitude = e.lngLat.wrap().lng
        document.getElementById('cursorCoordinates').value = latitude + ' , ' + longitude
      })
    },
    async mouseLeave () {
      const mapa = this.map
      // VOLTA O CURSOR ORIGINAL
      await mapa.on('mouseleave', 'JsonFonte', function () {
        mapa.getCanvas().style.cursor = 'default'
      })
    },
    async mouseEnter () {
      const mapa = this.map
      try {
        // REALIZA A MUDANÇA DO CURSOR
        await mapa.on('mouseenter', 'JsonFonte', function () {
          mapa.getCanvas().style.cursor = 'pointer'
        })
      } catch {
      }
    },
    async moveEnd () {
      // RECEBE TODAS AS FEATURES
      var features = this.map.querySourceFeatures('JsonFonte', {
        sourceLayer: 'JsonFonte'
      })
      // SE A QUANTIDADE DE FEATURES FOR MAIOR QUE 0
      if (features.length > 0) {
        // PARA CADA PROPRIEDADE NA FEATURE
        for (var property in features[0].properties) {
          if (property !== 'Color' && property !== 'Width') {
          // VERIFICA SE O NOME DO ATRIBUTO JÁ FOI ADICIONADO NO COMBOBOX
            if (this.items.includes(property) === false) {
            // ADICIONA O NOME DO ATRIBUTO NO COMBOX
              this.items.push(property)
            }
          }
        }
      }
    },
    async getFeaturesTable () {
      if (!this.featuresJson.length > 0) {
        this.featuresJson = await this.geoJsonSource.data.features
        /* this.featuresJson = this.Source.features */
        /* this.featuresJson = this.map.querySourceFeatures('JsonFonte') */
      }
    },
    async getCollections () {
      const ids = {
        sources: [],
        layers: []
      }
      const features = await this.map.queryRenderedFeatures()
      await features.forEach(async F => {
        var filtSources = []
        var filtLayers = []
        if (ids.sources.length > 0) {
          filtSources = ids.sources.filter(S => {
            return S === F.source
          })
        }
        if (ids.layers.length > 0) {
          ids.layers.filter(L => {
            return L === F.layer.id
          })
        }

        if (!filtSources.length > 0) {
          if (F.source) {
            await this.ids.sources.push(F.source)
          }
        }
        if (!filtLayers.length > 0) {
          if (F.layer.id) {
            await this.ids.layers.push(F.layer.id)
          }
        }
      })
      return ids
    },
    async newSource (idSource, idLayer, Feature) {
      this.map.addSource(idSource, Feature)
      switch (Feature.geometry.type) {
        case 'LineString':
          this.map.addLayer({
            id: idLayer,
            type: 'line',
            source: idSource
          })
          break
        case 'Polygon':
          this.map.addLayer({
            id: idLayer,
            type: 'line',
            source: idSource,
            paint: {
              'line-color': Feature.properties.stroke,
              'line-width': Feature.properties['stroke-width'],
              'line-opacity': Feature.data.properties['stroke-opacity']
            }
          })
          this.map.addLayer({
            id: idLayer,
            type: 'fill',
            source: idSource,
            paint: {
              'fill-color': Feature.properties.fill,
              'fill-opacity': Feature.properties['fill-opacity']
            }
          })
          break
        case 'Point':
          this.map.addLayer({
            id: idLayer,
            type: 'circle',
            source: idSource,
            paint: {
              'circle-color': Feature.properties['marker-color'],
              'circle-radius': 3,
              'circle-stroke-width': 3,
              'circle-stroke-color': '#fff'
            }
          })
          break
        default:
          break
      }
    },
    async showPopUp () {
      this.map.on('click', (e) => {
        this.showMapPopUp = false
        // this.popUp.coordinates = [0, 0]
        this.popUp.items = []
        const features = this.map.queryRenderedFeatures(e.point)
        if (features.length > 0) {
          var arrayProperties = Object.getOwnPropertyNames(features[0].properties)

          let arrayCols = []
          let i = 0
          let l = 0
          const rows = Math.round(arrayProperties.length / 3)
          arrayProperties.forEach(P => {
            if (P !== 'Color' || P !== 'Width') {
              i += 1
              l += 1
              arrayCols.push({ prop: P, value: features[0].properties[P] })
              if ((i / rows) === 1 || l === arrayProperties.length) {
                this.popUp.items.push(arrayCols)
                arrayCols = []
                i = 0
              }
            }
            // this.popUp.items.push({ prop: P, value: features[0].properties[P] })
          })
          this.popUp.coordinates = e.lngLat
          this.showMapPopUp = true
        }
      })
    },
    async zoomMap () {
      const coordinates = this.geoJsonSource.data.features[0].geometry.coordinates[0]

      this.map.flyTo({
        // These options control the ending camera position: centered at
        // the target, at zoom level 9, and north up.
        center: coordinates,
        zoom: 14,
        bearing: 0,

        // These options control the flight curve, making it move
        // slowly and zoom out almost completely before starting
        // to pan.
        speed: 1.5, // make the flying slow
        curve: 1.5, // change the speed at which it zooms out

        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: (t) => t,

        // this animation is considered essential with respect to prefers-reduced-motion
        essential: true
      })
    },
    async getRandomColor () {
      var letters = '0123456789ABCDEF'
      var color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    async updateTools (e) {
      // console.log(this.map.getStyle().sources)
      // REALIZA A REMOÇÃO DOS POPUP ADICIONADOS NO MAPA
      for (var p in this.popupTools) {
        this.popupTools[p].remove()
      }
      // RECEBE TODOS OS OBJETOS DESENHADOS NO MAPBOX
      var data = this.drawTools.getAll()
      //  SE QUANTIDADE DE OBJETOS FOR MAIOR QUE 0
      if (data.features.length > 0) {
        // PARA CADA FEATURE DESENHADA NO MAPA
        for (var fea in data.features) {
          // RECEBE O TIPO DE FEATURE, POLYGON, LINESTRING, POINT
          var geomType = turf.getType(data.features[fea])
          // SE O TIPO DE FEATURE FOR DE POLÍGONO
          if (geomType === 'Polygon') {
            var area = turf.area(data.features[fea])
            var length = turf.length(data.features[fea], {
              units: 'meters'
            })
            // REALIZA A FORMATAÇÃO PARA 2 CASAS
            var roundedarea = Math.round((area / 10000) * 100) / 100
            var roundedlength = Math.round(length * 100) / 100
            var centroid = turf.centroid(data.features[fea])

            // ADICIONA O VALOR DA ÁREA E PERIMETRO

            var htmlTextTools = '<v-card elevation="0" class="pt-3"><v-card-title class="d-flex justify-center pv-2"><b>Propriedades</b></v-card-title>'
            htmlTextTools += '<v-card-text>'
            htmlTextTools += '<p class="px-3"><b>Área:</b> ' + roundedarea + '<b>(h)</b></p>'
            htmlTextTools += '<p class="px-3"><b>Perímetro:</b> ' + roundedlength + '<b>(m)</b></p>'
            htmlTextTools += '</v-card-text></v-card>'
            // CRIA O POPUP E ADICIONA ELE NA LISTA
            var pop = new mgl.Popup({
              closeOnClick: false
            }).setLngLat(centroid.geometry.coordinates)
              .setHTML(htmlTextTools)
              .addTo(this.map)
            this.popupTools.push(pop)
          }
        }
      } else {
        if (e.type !== 'draw.delete') { alert('Use the draw tools to draw a polygon!') }
      }
    },
    async createRanges () {
      this.tematizar.faixas = []
      this.tematizar.faixasString = []
      var features = this.map.querySourceFeatures('JsonFonte')
      if (features.length > 0) {
        if (typeof features[0].properties[this.tematizar.atributo] === 'number') {
          var valores = []
          for (var i in features) {
            if (features[i].properties[this.tematizar.atributo] !== undefined) {
              valores.push(features[i].properties[this.tematizar.atributo])
            }
          }
          var valorMaximo = Math.max.apply(Math, valores)
          // var step = Math.round(valorMaximo / this.tematizar.nFaixas)
          var step = (valorMaximo / this.tematizar.nFaixas)
          var ultimoValor = 0
          for (var ii = 0; ii < this.tematizar.nFaixas; ii++) {
            var objFaixa = {
              id: ii,
              min: parseFloat(ultimoValor.toFixed(3)),
              max: parseFloat((ultimoValor + step).toFixed(3)),
              color: await this.getRandomColor(),
              value: 1
            }
            ultimoValor = ultimoValor + step
            this.tematizar.faixas.push(objFaixa)
          }
          this.tematizar.isNumeric = true
          this.tematizar.isString = false
        } else {
          var valoresTemp = []
          for (var iii in features) {
            if (features[iii].properties[this.tematizar.atributo] !== undefined) {
              if (valoresTemp.includes(features[iii].properties[this.tematizar.atributo]) === false) {
                valoresTemp.push(features[iii].properties[this.tematizar.atributo])
                var faixaString = {
                  id: iii,
                  text: features[iii].properties[this.tematizar.atributo],
                  color: await this.getRandomColor(),
                  value: 1
                }
                this.tematizar.faixasString.push(faixaString)
              }
            }
          }
          this.tematizar.isString = true
          this.tematizar.isNumeric = false
        }
      }
    },
    async setDialog () {
      this.modal.ajustes = false
      this.ajustes.center = this.map.getCenter()
      this.ajustes.zoom = this.map.getZoom()
      /* this.map.remove()
      this.inicializarMapa() */
    },
    async aplicarTema () {
      this.modal.tematizar = false
      this.ajustes.center = this.map.getCenter()
      this.ajustes.zoom = this.map.getZoom()
      this.setTema()
      /* this.map.remove()
      this.inicializarMapa() */
    },
    async resetarTema () {
      if (this.tematizar.aplicar === false) {
        this.tematizar.atributo = null
        this.tematizar.isNumeric = false
        this.tematizar.isString = false
      }
    },
    async setTema () {
      // SE A OPÇÃO DE TEMATIZAR ESTIVER HABILITADA
      if (this.tematizar.aplicar) {
        // SE O TIPO DE TEMATIZAÇÃO FOR NÚMERICA
        if (this.tematizar.isNumeric) {
          // SE HOUVER FAIXAS
          if (this.tematizar.faixas.length > 0) {
            var expressoes = []
            expressoes.push('case')
            // REALIZA A MONTAGEM DAS EXPRESSÕES
            for (var ia = 0; ia < this.tematizar.faixas.length; ia++) {
              var eXp = ['all', ['>=', ['get', this.tematizar.atributo], this
                .tematizar.faixas[ia].min
              ],
              ['<', ['get', this.tematizar.atributo], this.tematizar
                .faixas[ia].max
              ]
              ]
              expressoes.push(eXp)
              expressoes.push(this.tematizar.faixas[ia].color.toLowerCase())
            }
            expressoes.push(await this.getRandomColor())
            // REALIZA A ADIÇÃO DO LAYER COM AS CONFFIGURAÇÕES DA TEMATIZAÇÃO
            this.paintLayerLine = { 'line-color': expressoes, 'line-width': ['get', 'Width'] }
            this.paintJsonFonte = { 'fill-opacity': 0, 'fill-color': expressoes }
          }
        }
        // SE O TIPO DE TEMATIZAÇÃO FOR STRING
        if (this.tematizar.isString) {
          // SE HOUVER FAIXAS
          if (this.tematizar.faixasString.length > 0) {
            var expressoesString = []
            expressoesString.push('case')
            // REALIZA A MONTAGEM DAS EXPRESSÕES
            for (var iaa = 0; iaa < this.tematizar.faixasString.length; iaa++) {
              if (this.tematizar.faixasString[iaa].text !== undefined) {
                var eXpa = ['==', ['get', this.tematizar.atributo], this.tematizar
                  .faixasString[iaa].text
                ]
                expressoesString.push(eXpa)
                expressoesString.push(this.tematizar.faixasString[iaa].color
                  .toLowerCase())
              }
            }
            expressoesString.push(await this.getRandomColor())

            // REALIZA A ADIÇÃO DO LAYER COM AS CONFFIGURAÇÕES DA TEMATIZAÇÃO
            this.paintLayerLine = { 'line-color': expressoesString, 'line-width': ['get', 'Width'] }
            this.paintJsonFonte = { 'fill-opacity': 0, 'fill-color': expressoesString }
          }
        }
      } else {
        this.paintLayerLine = { 'line-color': ['get', 'Color'], 'line-width': ['get', 'Width'] }
        this.paintJsonFonte = { 'fill-opacity': 0, 'fill-color': ['get', 'Color'] }
      }
    },
    async setSettings () {
      this.modal.ajustes = false
      // REALIZA A VERIFICAÇÃO SE HÁ TEXTOS PARA SEREM INSERIDOS NO MEIO DOS POLÍGONOS
      if (this.ajustes.labelItems && this.ajustes.labelItems.length > 0) {
        if (this.map.getLayer('mapLayer_label')) {
          await this.map.removeLayer('mapLayer_label')
        }
        if (this.map.getSource('JsonPoints')) {
          await this.map.removeSource('JsonPoints')
        }
        var textosCentroide = ''
        for (var i in this.ajustes.labelItems) {
          textosCentroide = textosCentroide + '{' + this.ajustes.labelItems[i] + '} \n'
        }

        // CRIA O GEOJSON COM OS PONTOS DO CENTROID
        var newGeoJson = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        }

        await this.geoJsonSource.data.features.forEach(T => {
          if (T.geometry.type === 'LineString') {
            if (T.geometry.coordinates.length > 3) {
              try {
                var polygon = turf.polygon([T.geometry.coordinates])
                var newFeature = turf.centroid(polygon)
                newFeature.properties = T.properties
                newGeoJson.data.features.push(newFeature)
              } catch (error) {
                var nF = turf.point(T.geometry.coordinates[0])
                nF.properties = T.properties
                newGeoJson.data.features.push(nF)
              }
            }
          }
        })
        await this.map.addSource('JsonPoints', newGeoJson)

        // REALIZA A CRIAÇÃO DOS TEXTOS NOS POLÍGONOS
        await this.map.addLayer({
          id: 'mapLayer_label',
          type: 'symbol',
          source: 'JsonPoints',
          layout: {
            'text-field': textosCentroide,
            'text-font': ['DIN Offc Pro Medium',
              'Arial Unicode MS Bold'
            ],
            'text-size': this.ajustes.textHeight
          }
        })
      }
      // SE A OPÇÃO DE TEMATIZAR NÃO ESTIVER HABILITADA
      if (!this.tematizar.aplicar && this.ajustes.corContorno === true) {
        console.log('teste')
        await this.map.removeLayer('JsonFonte')

        // REALIZA A ADIÇÃO DO LAYER COM A AS CONFIGURAÇÕES DE CORES
        this.map.addLayer({
          id: 'JsonFonte',
          type: 'fill',
          source: 'JsonFonte',
          layout: {},
          paint: {
            'fill-color': 'rgba(' + this.ajustes.corPreenchimento.r +
                                        ',' + this.ajustes.corPreenchimento.g + ',' + this.ajustes
              .corPreenchimento.b + ',' + this.ajustes.corPreenchimento
              .a + ')',
            'fill-opacity': this.ajustes.corPreenchimento.a,
            'fill-outline-color': 'rgba(' + this.ajustes.corContorno.r +
                                        ',' + this.ajustes.corContorno.g + ',' + this.ajustes
              .corContorno.b + ',' + this.ajustes.corContorno
              .a + ')'
          }
        })
      }
    },
    async retornarExpressoesTema (Tipo) {
      const expressoes = []
      var randomColor = await this.getRandomColor()
      // SE O TIPO DE TEMATIZAÇÃO FOR NÚMERICA
      if (Tipo === 'NUMERIC') {
        expressoes.push('case')
        // REALIZA A MONTAGEM DAS EXPRESSÕES
        for (var ia = 0; ia < this.tematizar.faixas.length; ia++) {
          var eXp = ['all', ['>=', ['get', this.tematizar.atributo], this
            .tematizar.faixas[ia].min
          ],
          ['<', ['get', this.tematizar.atributo], this.tematizar
            .faixas[ia].max
          ]
          ]
          expressoes.push(eXp)
          expressoes.push(this.tematizar.faixas[ia].color.toLowerCase())
        }
        expressoes.push(randomColor)
      }

      if (Tipo === 'STRING') {
        expressoes.push('case')
        // REALIZA A MONTAGEM DAS EXPRESSÕES
        for (var iaa = 0; iaa < this.tematizar.faixasString.length; iaa++) {
          var eXpa = ['==', ['get', this.tematizar.atributo], this.tematizar
            .faixasString[iaa].text
          ]
          expressoes.push(eXpa)
          expressoes.push(this.tematizar.faixasString[iaa].color
            .toLowerCase())
        }
        expressoes.push(randomColor)
      }
      return expressoes
    },
    async projetoLinhas () {
      // RECEBE TODOS OS OBJETOS DESENHADOS NO MAPBOX
      var data = this.drawTools.getAll()
      //  SE QUANTIDADE DE OBJETOS FOR MAIOR QUE 0
      if (data.features.length > 0) {
        var polTurf = null
        var linhaTurf = null
        // PARA CADA FEATURE DESENHADA NO MAPA
        for (var fea in data.features) {
          // RECEBE O TIPO DE FEATURE, POLYGON, LINESTRING, POINT
          var geomType = turf.getType(data.features[fea])

          // SE O TIPO DE FEATURE FOR DE POLÍGONO
          if (geomType === 'Polygon') {
            polTurf = data.features[fea]
          } else if (geomType === 'LineString') {
            linhaTurf = data.features[fea]
          }
        }
        var polLine = turf.polygonToLine(polTurf)

        var arrayLinhas = []
        for (var i = 1; i <= 100; i++) {
          var offsetLine = turf.lineOffset(linhaTurf, i * 5, { units: 'meters' })

          var overlapping = turf.lineIntersect(polLine, offsetLine)

          if (overlapping.features.length > 1) {
            var ponto1 = overlapping.features[0].geometry.coordinates
            var ponto2 = overlapping.features[1].geometry.coordinates

            var linhaCortada = turf.lineSlice(ponto1, ponto2, offsetLine)
            arrayLinhas.push(linhaCortada)
          }
        }
        for (var ii = 1; ii <= 100; ii++) {
          var offsetLineA = turf.lineOffset(linhaTurf, ii * -5, { units: 'meters' })

          var overlappingA = turf.lineIntersect(polLine, offsetLineA)
          if (overlappingA.features.length > 1) {
            var ponto1A = overlappingA.features[0].geometry.coordinates
            var ponto2A = overlappingA.features[1].geometry.coordinates

            var linhaCortadaA = turf.lineSlice(ponto1A, ponto2A, offsetLineA)
            arrayLinhas.push(linhaCortadaA)
          }
        }

        var FLinhas = turf.featureCollection(arrayLinhas)

        /* console.log(FLinhas) */
        this.map.addSource('Linha', {
          type: 'geojson',
          data: FLinhas
        })
        this.map.addLayer({
          id: 'Linha',
          type: 'line', /* background, fill, line, symbol, raster, circle, fill-extrusion, heatmap, hillshade, sky. */
          source: 'Linha',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#34eb6b',
            'line-width': 2
          }
        })
      }
    },
    async resetMap () {
      var collections = await this.getCollections()
      collections.sources.forEach(S => {
        this.map.removeSource(S)
      })
      collections.layers.forEach(L => {
        this.map.removeLayer(L)
      })
    },
    /* criaPDF () {
      this.print.processando = true
      if (this.print.orientacao === 'Retrato') {
        printPdf.build()
          .format(this.print.folha.toLowerCase())
          .portrait() // Unnecessary since it's the default but it's included for clarity.
          .print(this.map, mgl)
          .then(function (pdf) {
            pdf.save('map.pdf')
            this.print.processando = false
            this.dialogPrint = false
          })
      } else {
        printPdf.build()
          .format(this.print.folha.toLowerCase())
          .landscape() // Unnecessary since it's the default but it's included for clarity.
          .print(this.map, mgl)
          .then(function (pdf) {
            pdf.save('map.pdf')
            this.print.processando = false
            this.dialogPrint = false
          })
      }
    }, */
    getSourcesDraw () {
      var sourceDrawCold = this.map.getSource('mapbox-gl-draw-cold')
      var sourceDrawHot = this.map.getSource('mapbox-gl-draw-hot')
      console.log(sourceDrawCold)
      console.log('-----------------------------------------------')
      console.log(sourceDrawHot)
    }
  }
}
</script>

<style>

.mapboxgl-popup-content {
  padding: 0 !important;
}
#create .v-speed-dial {
    position: absolute;
  }

#create .v-btn--floating {
    position: relative;
  }
.coordinates {
    z-index: 1 !important;
    display: flex !important;
    justify-content: flex-end !important;
    position: absolute !important;
    margin-bottom: 6% !important;
}
* {
    text-decoration: none !important;
    outline: none !important;
}
.content {
  height: 100% !important;
}
.mapboxgl-popup-close-button {
  color: red;
  font-size: 20px;
  left: 580px;
}
</style>

<template>
    <div>
        <v-toolbar flat>
          <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
          <v-toolbar-title>
              Página Principal
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select label="Página" v-model="page" :items="['Chamados', 'Licenças', 'CRMs']" variant="underlined" @change="updateCharts()" style="max-width: 200px; margin-top: 40px;"></v-select>
        </v-toolbar>
        <v-row justify="center">
            <v-col cols="2">
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="setDateP" persistent width="290px" :disabled="page === 'Licenças'" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="setDateP" label="Pesquisa" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="setDateP" scrollable range>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(setDateP), setPesquisa()">OK</v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>
        </v-row>
          <br>
          <br>
          <br>
        <v-card elevation="0" class="mt-3" v-if="page === 'Chamados'">
          <v-row class="mx-0 mb-0" v-if="showTicket === true">
            <v-col cols="6" class="bordaL">
              <ChartAllT/>
            </v-col>
            <v-col cols="6" class="bordaR">
              <DoughnutT/>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
        <v-card elevation="0" class="mt-3" v-if="page === 'Licenças'">
          <v-row class="mx-0" v-if="showLics === true">
            <v-col cols="12" class="bordaL">
              <BarChartV/>
            </v-col>
          </v-row>
          <v-row class="mx-0 mb-0" v-if="showLics === true">
            <v-col cols="6" class="bordaL">
              <PieA/>
            </v-col>
            <v-col cols="6" class="bordaR">
              <PieS/>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
        <v-card elevation="0" class="mt-3" v-if="page === 'CRMs'">
          <v-row class="mx-0" v-if="showFollowUp === true">
            <v-col cols="6" class="bordaL">
              <ChartAllF/>
            </v-col>
            <v-col cols="6" class="bordaR">
              <DoughnutF/>
            </v-col>
          </v-row>
          <v-row class="mx-0" v-if="showFollowUp === true">
            <v-col cols="6" class="bordaL">
              <RadarCC/>
            </v-col>
            <v-col cols="6" class="bordaR">
              <RadarTC/>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
      </div>
    </template>

<script>
import DoughnutT from '../components/charts/tickets/DoughnutT'
import DoughnutF from '../components/charts/followUp/DoughnutP'
import ChartAllF from '../components/charts/followUp/ChartAll'
import ChartAllT from '../components/charts/tickets/ChartAll'
import RadarCC from '../components/charts/followUp/RadarCC'
import RadarTC from '../components/charts/followUp/RadarTC'
import PieA from '../components/charts/licenses/PieA'
import PieS from '../components/charts/licenses/PieS'
import BarChartV from '../components/charts/licenses/BarChartV'

export default {
  components: { ChartAllF, ChartAllT, DoughnutT, DoughnutF, RadarCC, RadarTC, PieA, PieS, BarChartV },
  data () {
    return {
      date: [],
      modal: false,
      page: 'Chamados'
    }
  },
  computed: {
    labelPage () {
      if (this.page) {
        return 'CRMs'
      } else {
        return 'Chamados'
      }
    },
    isMaster () {
      return this.$store.state.profile.rules.isAdmin
    },
    setDateP: {
      get () {
        return this.date
      },
      set (value) {
        if (value.length === 2) {
          const Date1 = new Date(new Date(value[0]).getTime() + new Date().getTimezoneOffset() * 60000).getTime()
          const Date2 = new Date(new Date(value[1]).getTime() + new Date().getTimezoneOffset() * 60000).getTime()

          if (Date1 > Date2) {
            this.date = [value[1], value[0]]
            this.$store.state.masterHome.tickets.initialDate = Date2
            this.$store.state.masterHome.tickets.finalDate = Date1
            this.$store.state.masterHome.followUp.initialDate = Date2
            this.$store.state.masterHome.followUp.finalDate = Date1
            this.$store.state.masterHome.licenses.initialDate = Date2
            this.$store.state.masterHome.licenses.finalDate = Date1
          } else {
            this.date = value
            this.$store.state.masterHome.tickets.initialDate = Date1
            this.$store.state.masterHome.tickets.finalDate = Date2
            this.$store.state.masterHome.followUp.initialDate = Date1
            this.$store.state.masterHome.followUp.finalDate = Date2
            this.$store.state.masterHome.licenses.initialDate = Date1
            this.$store.state.masterHome.licenses.finalDate = Date2
          }
        } else {
          this.date = value
        }
      }
    },
    showTicket () {
      return this.$store.state.masterHome.tickets.show
    },
    showFollowUp () {
      return this.$store.state.masterHome.followUp.show
    },
    showLics () {
      return this.$store.state.masterHome.licenses.show
    }
  },
  methods: {
    async updateCharts () {
      await this.$store.dispatch('getMasterHome')
    },
    async setPesquisa () {
      if (this.date.length > 1) {
        await this.$store.dispatch('getMasterHome')
      }
    },
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    }
  },
  async created () {
    // await this.$store.dispatch('getMasterHome')
    var data1 = new Date(this.$store.state.masterHome.tickets.initialDate)
    var data2 = new Date(this.$store.state.masterHome.tickets.finalDate)

    this.date = [data1.getFullYear() + '-' + (data1.getUTCMonth() + 1) + '-' + data1.getUTCDate(), data2.getFullYear() + '-' + (data2.getMonth() + 1) + '-' + data2.getDate()]
  }
}
</script>
<style>
.bordaL {
    border: groove !important;
    border-bottom: none !important;
}
.bordaR {
    border: groove !important;
    border-left: none !important;
    border-bottom: none !important;
}
</style>

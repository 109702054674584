<template>
<div>
    <v-form ref="form" lazy-validation>
        <v-card>
            <v-toolbar flat>
                <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
                <v-toolbar-title>
                    Cursos
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <router-link class="link" :to="{ name: 'VisualizarCurso', params: { idcurso: this.$route.params.idcurso } }">
                    <v-btn small color="secondary">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                        &nbsp;
                        Voltar
                    </v-btn>
                </router-link>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="createClass()" small dark color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-send-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Subir</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        <v-text-field type="text" outlined v-model="newClass.nome" label="Nome" required/>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="number" outlined v-model="newClass.nAula" label="N°" required/>
                    </v-col>
                    <v-col cols="4">
                      <v-file-input v-model="newClass.vFile" label="Arquivo de Video" prepend-icon="mdi-video-vintage" outlined :show-size="1000" required accept="video/MKV, video/mp4, video/mov, video/avi">
                        <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 2" color="secondary" dark label small>
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <vue-editor :editorToolbar="customToolbar" v-model="newClass.desc" />
            </v-card-text>
        </v-card>
    </v-form>
</div>
</template>
<script>
export default {
  data () {
    return {
      newClass: {
        nome: null,
        nAula: null,
        desc: null,
        vFile: null
      },
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }],
        [{
          color: []
        }]
      ],
      languages: [
        'Português',
        'Inglês',
        'Espanhol',
        'Chinês',
        'Francês',
        'Russo'
      ]
    }
  },
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getCurrentCourse () {
      return this.$store.state.courses.filter(C => {
        return C.courseId === this.$route.params.idcurso
      })[0]
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async createClass () {
      if (this.isAdmin && this.newClass.nome && this.newClass.nAula && this.newClass.vFile) {
        await this.$store.dispatch('createClass', { course: this.getCurrentCourse, info: this.newClass })
      }
    }
  }
}
</script>

<style>

</style>

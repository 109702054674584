<template>
<v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
      <v-toolbar-title>
          &nbsp;
          Chamados
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="#ffd438" @click="openModal.addPermission = true" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-key-outline</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Permissão</span>
      </v-tooltip>
      <router-link class="link" :to="{ name: 'NovoChamado'}">
        &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-bullhorn-outline</v-icon>
            </v-btn>
          </template>
          <span>Novo Chamado</span>
        </v-tooltip>
      </router-link>
    </v-toolbar>
    <v-card-title>
      <v-row>
        <v-col cols="2" v-if="isAdmin">
          <v-select :items="items" label="Status" outlined v-model="statusTicket"></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-text-field v-model="search" label="Filtrar" single-line hide-details outlined></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table :headers="headers" :items="getTickets" sort-by="criadoEm" :items-per-page="20" :sort-desc="true" :search="search" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há chamados no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-if="isAdmin" v-slot:[`item.id`]="{ index }">
            {{ getTickets.length - index }}
        </template>
        <template hidden v-slot:[`item.ticketUserName`]="{ }">
        </template>
        <template v-slot:[`item.icon`]="{ item }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" v-if="item.lvl === 'Normal' || !item.lvl" color="green" dark>mdi-emoticon-outline</v-icon>
            <v-icon v-bind="attrs" v-on="on" v-else-if="item.lvl === 'Intermediário'" color="orange" dark>mdi-emoticon-neutral-outline</v-icon>
            <v-icon v-bind="attrs" v-on="on" v-else-if="item.lvl === 'Complicado'" color="red" dark>mdi-emoticon-confused-outline</v-icon>
          </template>
          <span v-if="item.lvl">{{ item.lvl }}</span>
          <span v-else>Normal</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
            <v-avatar v-if="isAdmin && item.interactions.length > 0" :key="getTickets.indexOf(item)" size="35">
              <img :src="verifyImgUser(item.interactions)">
              &nbsp;
            </v-avatar>
            <v-chip v-if="item.status == 1" color="red" dark>
                <v-icon>mdi-alert-decagram</v-icon>&nbsp; Novo
            </v-chip>
            <v-chip v-if="item.status == 2" color="orange" dark>
                <v-icon>mdi-face-agent</v-icon> &nbsp; Em Andamento
            </v-chip>
            <v-chip v-if="item.status == 3" color="green" dark>
                <v-icon>mdi-check-all</v-icon>&nbsp; Concluído
            </v-chip>
            <v-chip v-if="item.status == 4" color="#34c0eb" dark>
                <v-icon>mdi-chevron-down-circle</v-icon>&nbsp; Reaberto
            </v-chip>
            <v-chip v-if="item.status == 5" color="#17a2b8" dark>
                <v-icon>mdi-face-agent</v-icon>&nbsp; Respondido
            </v-chip>
        </template>
        <template v-slot:[`item.ticketId`]="{ item }">
            <router-link class="link" :to="{ name: 'VisualizarChamado', params: { idchamado: item.ticketId }}">
                <v-btn text small>
                    <v-icon color="secondary"> mdi-eye-circle</v-icon>
                    &nbsp; Visualizar
                </v-btn>
            </router-link>
        </template>
        <template v-slot:[`item.criadoEm`]="{ item }">
        {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.tipo`]="{ item }" v-if="isAdmin">
            <v-btn text small @click="delTicket(item)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.protoId`]="{ item }">
          {{ item.protoId.substr(0, 6) }}
        </template>
        <template v-slot:[`item.titulo`]="{ item }">
          <!-- {{ item.titulo }} <span v-if="isAdmin"><br> <b><i> -> {{ retornarUser(item.profileId) }}</i></b></span> -->
          {{ item.titulo }} <span v-if="isAdmin"><br> <b><i> -> {{ (item.ticketUserName  ? item.ticketUserName  : "Usúario deletado") }}</i></b></span>
        </template>
    </v-data-table>
    <v-dialog v-model="openModal.addPermission" max-width="500px">
      <v-card>
          <v-card-title class="white--text blue darken-4" color="#ffd438">
            Adicionar Permissões de Suporte
            <v-spacer></v-spacer>
            <v-btn color="green" class="text--primary" fab small @click="setPermission">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="4">
                <v-menu v-model="menus.menuModal" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="addPermission.date" label="Expiração" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addPermission.date"
                    @input="menus.menuModal = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8" class="mt-4">
                <v-combobox v-model="newPermission" :items="getUsers" outline dense hide-selected>
                  <template v-slot:item="{ item }">
                    <span class="text-overline grey--text text--darken-3 mx-2">
                      {{ item.nomeCompleto + ' (' + item.email +')' }}
                     </span>
                   </template>
                   <template v-slot:selection="{ item }">
                     <span class="text-overline grey--text text--darken-3 mx-2">
                       {{ item.nomeCompleto }}
                     </span>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-virtual-scroll :items="getUsers" :item-height="50" height="300">
            <template v-slot:default="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar color="white" class="white--text" size="56"><img :src="item.photoURL" alt="User"></v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ item.nomeCompleto }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="hasPermission(item.profileId)">
                  <v-speed-dial class="bottom-0"
                     v-model="item.fab"
                     :top="false"
                     :bottom="true"
                     :right="true"
                     :left="false"
                     direction="left"
                     :open-on-hover="true"
                     :transition="transition">
                    <template v-slot:activator class="my-2">
                      <v-btn fab dark small color="blue darken-2" v-model="item.fab" >
                        <v-icon size="22" v-if="item.fab">
                          mdi-close
                        </v-icon>
                        <v-icon size="22" v-else>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-btn @click="openPermissionDialog('new', item.profileId)" fab dark small color="green">
                      <v-icon size="22">mdi-dots-horizontal-circle-outline</v-icon>
                    </v-btn>
                    <v-btn @click="openPermissionDialog('del', item.profileId)" fab dark small color="red">
                      <v-icon size="22">mdi-delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.delPermission" max-width="300px">
      <v-card>
          <v-card-title class="text-h6">
              Excluir permissão?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" text @click="resetDialog">
                  Cancelar
              </v-btn>
              <v-btn color="red darken-1" text @click="setPermission">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.newPermission" max-width="300px" v-click-outside="onClickOutsideDialog" @keydown.esc="setPermission()">
      <v-card>
          <v-card-title>
            <v-menu v-model="menus.menuDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="upPermission.date" label="Modificar permissão" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="upPermission.date"
                @input="menus.menuDialog = false"
              ></v-date-picker>
            </v-menu>
          </v-card-title>
      </v-card>
    </v-dialog>
</v-card>
</template>

<script>
export default {
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    getTickets () {
      // const nArray = this.$store.state.tickets.filter(T => { return T }).sort(this.compareTickets)
      if (!this.isAdmin || this.statusTicket === 'Geral') {
        return this.$store.state.tickets
      } else {
        return this.$store.state.specifyTickets
      }
    },
    getUsers () {
      if (this.addPermission.user === null) {
        return this.$store.state.usersMembers.slice().sort(this.compare)
      } else {
        return this.$store.state.usersMembers.filter(U => {
          if (this.addPermission.user.nomeCompleto) {
            return U.nomeCompleto.toLowerCase().match(this.addPermission.user.nomeCompleto.toLowerCase()) || U.email.toLowerCase().match(this.addPermission.user.email.toLowerCase())
          } else {
            return U.nomeCompleto.toLowerCase().match(this.addPermission.user.toLowerCase()) || U.email.toLowerCase().match(this.addPermission.user.toLowerCase())
          }
        }).sort(this.compare)
      }
    },
    getPermissions () {
      return this.$store.state.permissions.filter(P => {
        return P.permsId === 'duEJNQ0_QBCrtsAvCffkLQ'
      })[0]
    },
    newPermission: {
      get () {
        return this.addPermission.user
      },
      set (value) {
        this.addPermission.user = value
      }
    },
    statusTicket: {
      get () {
        return this.items[this.typeTicket - 1]
      },
      set (value) {
        if (value === 'Geral') {
          this.typeTicket = 6
        } else {
          switch (value) {
            case 'Novo':
              this.typeTicket = 1
              break
            case 'Em Andamento':
              this.typeTicket = 2
              break
            case 'Atendimento Concluído':
              this.typeTicket = 3
              break
            case 'Reaberto':
              this.typeTicket = 4
              break
            case 'Respondido':
              this.typeTicket = 5
              break
            default:
              this.typeTicket = 1
              break
          }
          this.$store.dispatch('getSpecifyTickets', this.typeTicket)
        }
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async setPermission () {
      if (this.isAdmin) {
        if (!this.openModal.newPermission && !this.openModal.delPermission && !this.hasPermission(this.addPermission.user.profileId)) {
          await this.getPermissions.existing.push({ profileId: this.addPermission.user.profileId, criadoEm: Date.now(), expires: new Date(this.addPermission.date).getTime(), status: true })
          this.$store.dispatch('updatePermission', 'duEJNQ0_QBCrtsAvCffkLQ').then(() => {
            this.openModal.addPermission = false
            this.addPermission = {
              user: null,
              date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
          })
        } else if (this.openModal.newPermission) {
          const perm = this.getPermissions.existing.filter(P => {
            return P.profileId === this.upPermission.profileId
          })[0]
          if (perm.expires !== new Date(this.upPermission.date).getTime()) {
            perm.expires = new Date(this.upPermission.date).getTime()
            await this.$store.dispatch('updatePermission', 'duEJNQ0_QBCrtsAvCffkLQ').then(() => {
              this.resetDialog()
            })
          }
        } else if (this.openModal.delPermission) {
          this.getPermissions.existing = await this.getPermissions.existing.filter(P => {
            return P.profileId !== this.upPermission.profileId
          })
          await this.$store.dispatch('updatePermission', 'duEJNQ0_QBCrtsAvCffkLQ').then(() => {
            this.resetDialog()
          })
        }
      }
    },
    async onClickOutsideDialog (e) {
      if (this.openModal.newPermission && e.path.length === 7) {
        this.setPermission()
      }
    },
    async openPermissionDialog (btn, profileId) {
      const perm = await this.getPermissions.existing.filter(P => {
        return P.profileId === profileId
      })[0]
      this.upPermission.profileId = perm.profileId
      this.upPermission.date = (new Date(perm.expires)).toISOString().substr(0, 10)
      switch (btn) {
        case 'del':
          this.openModal.delPermission = true
          this.openModal.newPermission = false
          break
        case 'new':
          this.openModal.newPermission = true
          this.openModal.delPermission = false
          break
        default:
          break
      }
    },
    hasPermission (profileId) {
      const permission = this.getPermissions.existing.filter(P => {
        return P.profileId === profileId
      })
      if (permission.length > 0) {
        return true
      }
      return false
    },
    compareTickets (ticket1, ticket2) {
      const bandA = ticket1.criadoEm
      const bandB = ticket2.criadoEm

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    },
    compare (profile1, profile2) {
      // Use toUpperCase() to ignore character casing
      const bandA = profile1.nomeCompleto.toUpperCase()
      const bandB = profile2.nomeCompleto.toUpperCase()

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    },
    resetDialog () {
      this.openModal.delPermission = false
      this.openModal.newPermission = false
      this.upPermission = {
        profileId: null,
        date: null
      }
    },
    delTicket (ticket) {
      this.$store.dispatch('delTicket', ticket)
    },
    /* retornarUser (profileId) {
      if (this.isAdmin) {
        return this.getUsers.filter(U => {
          return U.profileId === profileId
        })[0].nomeCompleto
      } else {
        return ''
      }
    }, */
    verifyImgUser (items) {
      if (items[items.length - 1].isAdmin === true) {
        return items[items.length - 1].profilePhotoURL
      } else {
        // return 'https://img.icons8.com/color/99/000000/user-male-circle--v1.png'
        return items[items.length - 1].profilePhotoURL
      }
    }
  },
  data () {
    return {
      search: '',
      headers: [
        {
          text: '',
          value: 'ticketUserName',
          sortable: false
        },
        {
          text: '',
          value: 'id'
        },
        {
          text: 'Dificuldade',
          value: 'icon',
          sortable: true,
          align: 'center'
        },
        {
          text: '',
          value: 'ticketId'
        },
        {
          text: 'Título',
          align: 'start',
          sortable: false,
          value: 'titulo'
        },
        {
          text: 'Protocolo de Atendimento',
          value: 'protoId'
        },
        {
          text: 'Situação',
          value: 'status',
          width: '260px'
        },
        {
          text: 'Data',
          value: 'criadoEm'
        },
        {
          text: '',
          value: 'tipo',
          align: 'middle',
          sortable: false
        }
      ],
      fab: false,
      transition: 'scale-transition',
      upPermission: {
        profileId: null,
        date: null
      },
      menus: {
        menuModal: false,
        menuDialog: false
      },
      addPermission: {
        user: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      openModal: {
        addPermission: false,
        delPermission: false,
        newPermission: false
      },
      typeTicket: 1,
      items: ['Novo', 'Em Andamento', 'Atendimento Concluído', 'Reaberto', 'Respondido', 'Geral']
    }
  },
  async created () {
    if (this.isAdmin) {
      if (!this.$store.state.usersMembers.length > 0) {
        await this.$store.dispatch('getAllUsers')
      }
      if (!this.$store.state.adminMembers.length > 0) {
        await this.$store.dispatch('getAllAdmins')
      }
      if (!this.$store.state.permissions.length > 0) {
        await this.$store.dispatch('getAllPermissions')
      }
      if (!this.$store.state.tickets.length > 0) {
        await this.$store.dispatch('getAllTickets')
      }
    }
  }
}
</script>

<style>
.v-speed-dial--bottom {
  bottom: 0 !important;
}
</style>

<template>
<div>
    <v-toolbar flat>
      <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
      <v-toolbar-title>
          Cursos
      </v-toolbar-title>&nbsp;&nbsp;&nbsp;
      <v-spacer></v-spacer>
      <router-link class="link" :to="{ name: 'Cursos'}">
          <v-btn small color="secondary">
              <v-icon>mdi-arrow-left-circle</v-icon>
              &nbsp;
              Voltar
          </v-btn>
      </router-link>
      &nbsp;
      <v-tooltip bottom v-if="getPercentCurso >= 80">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="red" v-bind="attrs" v-on="on" @click="gerarCertificado()">
            <v-icon>mdi-certificate-outline</v-icon>
          </v-btn>
        </template>
        <span>Emitir Certificado</span>
      </v-tooltip>
      &nbsp;
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="pink" @click="openModal.delCourse = true" v-bind="attrs" v-on="on">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Excluir Curso</span>
      </v-tooltip>
      &nbsp;
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="#ffd438" @click="openModal.addPermission = true" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-key-outline</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Permissão</span>
      </v-tooltip>
      &nbsp;
      <router-link v-if="isAdmin" class="link" :to="{ name: 'EditarCurso', params: { idcurso: this.$route.params.idcurso }}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="link" color="white" v-bind="attrs" v-on="on">
              <v-icon>mdi-book-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Editar Curso</span>
        </v-tooltip>
      </router-link>
      &nbsp;
      <router-link v-if="isAdmin" class="link" :to="{ name: 'NovasAulas', params: { idcurso: this.$route.params.idcurso }}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-video-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Nova Aula</span>
        </v-tooltip>
      </router-link>
      &nbsp;
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark color="brown" @click="$refs.materiais.$refs.input.click()" v-bind="attrs" v-on="on">
              <v-icon>mdi-folder-upload-outline</v-icon>
          </v-btn>
        </template>
        <span>Subir Materiais</span>
      </v-tooltip>
      <v-file-input v-model="subjects" multiple ref="materiais" style="display: none" @change="uploadSubjects"></v-file-input>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row>
        <v-col cols="8" class="pr-0">
            <v-card elevation="2">
                <v-card-title>{{ getCurrentCourse.titulo }}</v-card-title>
                <v-card-subtitle>{{ getCurrentCourse.descSimples}}</v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-card-subtitle class="p-1 pb-0 pt-0">
                          <span class="align-center d-flex">
                            Postado:&nbsp;<b>{{ new Date(getCurrentCourse.criadoEm).toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric'}) }}&nbsp;&nbsp;</b>
                            <v-icon v-if="getCurrentCourse.linguagem">mdi-earth</v-icon>
                            {{ getCurrentCourse.linguagem }}&nbsp;&nbsp;
                            <v-icon v-if="captions != null">mdi-closed-caption</v-icon>{{ captions }}
                          </span>
                        </v-card-subtitle>
                    </v-row>
                    <v-row>
                        <v-card-subtitle class="p-1 pt-0 pb-0">
                          Criado por:
                          <b v-for="(item, index) in getCurrentCourse.auxiliares" :key="index">
                          <v-tooltip bottom v-if="isAdmin">
                            <template v-slot:activator="{ on, attrs }">
                               <var v-if="index !== getCurrentCourse.auxiliares.length-1" v-bind="attrs" v-on="on"><u>{{ item.nomeCompleto }},</u></var>
                               <var v-else v-bind="attrs" v-on="on">&nbsp;<u>{{ item.nomeCompleto }}</u></var>
                            </template>
                            <v-avatar color="white" class="photo" size="45"><img :src=" item.photoURL" alt="User">
                            </v-avatar>&nbsp;
                            <span>{{ item.cargo }}</span>
                          </v-tooltip>
                          </b>
                        </v-card-subtitle>
                    </v-row>
                    <v-row>
                        <v-card-subtitle class="p-1 pt-0 pb-0">
                          Curso Concluido em: <b>{{ getPercentCurso }}%</b>
                        </v-card-subtitle>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card elevation="0">
                <v-expansion-panels accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-card-title class="pa-0">Descrição do Curso</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text class="text-justify" v-html="getCurrentCourse.descHTML" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
            <v-simple-table fixed-header height="400px" v-if="getCurrentCourse.classes">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-if="isAdmin"></th>
                    <th class="text-left">
                      Título
                    </th>
                    <th class="text-left">
                      Duração
                    </th>
                    <th class="text-left">
                      Visto
                    </th>
                    <th v-if="isAdmin"></th>
                  </tr>
                </thead>
                <tbody v-if="getCurrentCourse.classes.length > 0">
                  <tr v-for="(item, index) in orderBy(getCurrentCourse.classes,'nAula')" :key="index">
                    <td v-if="isAdmin" style="width: 40px">{{ item.nAula }} </td>
                    <td class="wall-pointer" @click="optsVPlayer.videoId = item.id, openModal.vPlayer = true">{{ item.nome }}</td>
                    <td>{{ new Date(item.duration * 1000).toISOString().substr(11, 8) }}</td>
                    <td>
                      <v-icon color="green" @click="openClassDialog('reset', item)" v-if="item.views.filter(vv => { return vv.profileId === profileId}).length > 0 && item.views.filter(vv => { return vv.profileId === profileId})[0].concluded">mdi-eye-check-outline</v-icon>
                      <v-icon color="gray" v-else>mdi-eye-remove-outline</v-icon>
                    </td>
                    <td v-if="isAdmin">
                      <v-btn dark x-small color="blue" @click="openClassDialog('edit', item)">
                        <v-icon size="15">mdi-dots-horizontal-circle-outline</v-icon>
                      </v-btn>
                      &nbsp;
                      <v-btn dark x-small color="red" @click="openClassDialog('del', item)">
                        <v-icon size="15">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-col>
        <v-col cols="4" class="pl-0">
            <v-responsive class="overflow-y-auto border-container" max-height="80vh" max-width="370">
                <v-card outline>
                    <img :src="getCurrentCourse.photoURL" class="pa-0 img-container" height="250" width="370">
                    <div class="pa-1">
                        <v-card-text>
                            <v-row class="mx-0 align-center">
                              <v-col cols="3">
                                <v-avatar color="white" size="45"><img :src="getCurrentCourse.teacher.photoURL" alt="User"></v-avatar>
                              </v-col>
                              <v-col>
                                    <v-row>
                                        <h4>{{ getCurrentCourse.teacher.nomeCompleto }}</h4>
                                    </v-row>
                                    <v-row>
                                        <h5>{{ getCurrentCourse.teacher.cargo}}</h5>
                                    </v-row>
                              </v-col>
                            </v-row>
                        </v-card-text>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-1">
                      <v-list dense>
                        <v-subheader>Materiais do Curso</v-subheader>
                        <v-list-item-group color="primary" v-if="items.length > 0">
                          <v-list-item v-for="(item, i) in items" :key="i">
                            <a target="_blank" class="link" :href="item.download" download>
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="d-flex">
                                <v-icon v-if="files[item.file]" v-text="files[item.file]"></v-icon>
                                <v-icon v-else>mdi-file-document-outline</v-icon>
                                <h3 class="pt-1 pl-2">{{item.name}}</h3>
                                <v-hover v-slot="{ hover }" v-if="isAdmin">
                                  <v-icon v-if="hover" class="delMaterial" color="red" @click="openModal.delMaterial = true, indexM = i">mdi-delete</v-icon>
                                  <v-icon v-else class="delMaterial">mdi-delete</v-icon>
                                </v-hover>
                              </v-list-item-title>
                            </v-list-item-content>
                             </a>
                          </v-list-item>
                        </v-list-item-group>
                        <v-list-item-group color="primary" v-else>
                          <v-list-item class="justify-center">
                            <small>
                              <i>Não há materias no momento.</i>
                            </small>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </div>
                </v-card>
            </v-responsive>
        </v-col>
    </v-row>
    <v-dialog fullscreen v-model="openModal.vPlayer" v-click-outside="onClickOutsidePlayer" @keydown.esc="onPlayerDown()">
        <v-card style="background: black" @keydown.esc="onPlayerDown()">
            <vimeo-player @fullscreenchange="exitFullscreen()" :options="{'responsive': true}" ref="player" :video-id="optsVPlayer.videoId" @ready="onReady()" @ended="onPlayerDown()" @play="play()" @pause="pause()">
            </vimeo-player>
            <span class="vSpanPlayer1" v-if="vSpanPlayer[0]">{{profileId}}</span>
            <span class="vSpanPlayer2" v-if="vSpanPlayer[1]">{{profileId}}</span>
            <span class="vSpanPlayer3" v-if="vSpanPlayer[2]">{{profileId}}</span>
            <span class="vSpanPlayer4" v-if="vSpanPlayer[3]">{{profileId}}</span>
            <span class="vSpanPlayer5" v-if="vSpanPlayer[4]">{{profileId}}</span>
        </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.delCourse" :max-width="wModal">
      <v-card>
            <v-card-title class="text-h6">
                Tem certeza que gostaria de excuir este curso?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="resetModel()">
                    Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="delCourse(), resetModel()">
                    Confirmar
                </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.delMaterial" max-width="550px">
      <v-card>
            <v-card-title class="text-h6">
                Tem certeza que gostaria de excuir este material?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="resetModel()">
                    Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="delMaterial(), resetModel()">
                    Confirmar
                </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.addPermission" max-width="500px">
      <v-card>
          <v-card-title class="white--text blue darken-4" color="#ffd438">
            Adicionar Permissão ao Curso
            <v-spacer></v-spacer>
            <v-btn color="green" class="text--primary" fab small @click="setPermission">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="4">
                <v-menu v-model="menus.menuModal" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="addPermission.date" label="Expiração" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addPermission.date"
                    @input="menus.menuModal = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8" class="mt-4">
                <v-combobox v-model="newPermission" :items="getUsers" outline dense hide-selected>
                  <template v-slot:item="{ item }">
                    <span class="text-overline grey--text text--darken-3 mx-2">
                      {{ item.nomeCompleto + ' (' + item.email +')' }}
                     </span>
                   </template>
                   <template v-slot:selection="{ item }">
                     <span class="text-overline grey--text text--darken-3 mx-2">
                       {{ item.nomeCompleto }}
                     </span>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-virtual-scroll :items="getUsers" :item-height="50" height="300">
            <template v-slot:default="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar color="white" class="white--text" size="56"><img :src="item.photoURL" alt="User"></v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ item.nomeCompleto }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="hasPermission(item.profileId)">
                  <v-speed-dial class="bottom-0"
                     v-model="item.fab"
                     :top="false"
                     :bottom="true"
                     :right="true"
                     :left="false"
                     direction="left"
                     :open-on-hover="true"
                     :transition="transition">
                    <template v-slot:activator class="my-2">
                      <v-btn fab dark small color="blue darken-2" v-model="item.fab" >
                        <v-icon size="22" v-if="item.fab">
                          mdi-close
                        </v-icon>
                        <v-icon size="22" v-else>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-btn @click="openPermissionDialog('new', item.profileId)" fab dark small color="green">
                      <v-icon size="22">mdi-dots-horizontal-circle-outline</v-icon>
                    </v-btn>
                    <v-btn @click="openPermissionDialog('del', item.profileId)" fab dark small color="red">
                      <v-icon size="22">mdi-delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.delPermission" max-width="300px">
      <v-card>
          <v-card-title class="text-h6">
              Excluir permissão?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" text @click="resetDialog">
                  Cancelar
              </v-btn>
              <v-btn color="red darken-1" text @click="setPermission">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.newPermission" max-width="300px" v-click-outside="onClickOutsideDialog" @keydown.esc="setPermission()">
      <v-card>
          <v-card-title>
            <v-menu v-model="menus.menuDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="upPermission.date" label="Modificar permissão" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="upPermission.date"
                @input="menus.menuDialog = false"
              ></v-date-picker>
            </v-menu>
          </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.delClass" max-width="300px">
        <v-card v-if="upClass.nClass">
          <v-card-title class="text-h6">
              Excluir Aula {{ upClass.class.nAula }}?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" text @click="resetModel()">
                  Cancelar
              </v-btn>
              <v-btn color="red darken-1" text @click="updateClass('del')">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.editClass" max-width="500px">
      <v-card v-if="upClass.nClass">
          <v-card-text class="pa-0 pt-10">
            <v-row class="justify-center">
              <v-col cols="5" class="py-0">
                  <v-text-field type="text" outlined v-model="upClass.nClass.id" label="ID "></v-text-field>
              </v-col>
              <v-col cols="5" class="py-0">
                  <v-text-field type="number" outlined v-model="upClass.nClass.duration" label="Duração (S) "></v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="5" class="py-0">
                  <v-text-field type="text" outlined v-model="upClass.nClass.nome" label="Nome "></v-text-field>
              </v-col>
              <v-col cols="5" class="py-0">
                  <v-text-field type="number" outlined v-model="upClass.nClass.nAula" label="N°"/>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10" class="py-0">
                  <v-text-field type="text" outlined v-model="upClass.nClass.desc" label="Descrição "></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="gray darken-1" text @click="resetModel()">
              Cancelar
            </v-btn>
            <v-btn color="primary darken-1" text @click="updateClass('up')">
              Atualizar
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openModal.resetClass" max-width="350px">
      <v-card v-if="upClass.nClass">
        <v-card-title class="text-h6">
            Resetar Visualização da Aula {{ upClass.class.nAula }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="resetModel()">
              Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="updateClass('reset')">
              Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import Vimeo from '@vimeo/player'
import Vue2Filters from 'vue2-filters'
export default {
  mixins: [Vue2Filters.mixin],
  computed: {
    profileId () {
      return this.$store.state.profileId
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getCurrentCourse () {
      return this.$store.state.courses.filter(C => {
        return C.courseId === this.$route.params.idcurso
      })[0]
    },
    getPercentCurso () {
      const videosV = []
      if (this.getCurrentCourse.classes.length > 0) {
        this.getCurrentCourse.classes.forEach(C => {
          const video = C.views.filter(V => {
            return V.profileId === this.profileId && V.concluded === true
          })
          if (video.length > 0) {
            videosV.push(video[0])
          }
        })
      } else {
        return 0
      }
      const PC = (videosV.length / this.getCurrentCourse.classes.length) * 100
      return PC.toFixed(2)
    },
    getUsers () {
      if (this.addPermission.user === null) {
        return this.$store.state.usersMembers.slice().sort(this.compareUsers)
      } else {
        return this.$store.state.usersMembers.filter(U => {
          if (this.addPermission.user.nomeCompleto) {
            return U.nomeCompleto.toLowerCase().match(this.addPermission.user.nomeCompleto.toLowerCase()) || U.email.toLowerCase().match(this.addPermission.user.email.toLowerCase())
          } else {
            return U.nomeCompleto.toLowerCase().match(this.addPermission.user.toLowerCase()) || U.email.toLowerCase().match(this.addPermission.user.toLowerCase())
          }
        }).sort(this.compareUsers)
      }
    },
    getPermissions () {
      return this.$store.state.permissions.filter(P => {
        return P.permsId === this.$route.params.idcurso
      })[0]
    },
    captions () {
      if (this.getCurrentCourse.legendas.length > 0) {
        var Legendas = ''
        for (let i = 0; i < this.getCurrentCourse.legendas.length; i++) {
          if (i === 0) {
            Legendas += this.getCurrentCourse.legendas[i]
          } else {
            Legendas += ', ' + this.getCurrentCourse.legendas[i]
          }
        }
        return Legendas
      }
      return null
    },
    wModal () {
      if (this.openModal.vPlayer) {
        return 640
      } else {
        return 500
      }
    },
    items () {
      const Nos = []
      if (this.getCurrentCourse.materiais) {
        this.getCurrentCourse.materiais.forEach(M => {
          Nos.push({
            name: M.nome,
            file: M.ex,
            download: M.arquivoURL
          })
        })
      }
      return Nos
    },
    newPermission: {
      get () {
        return this.addPermission.user
      },
      set (value) {
        this.addPermission.user = value
      }
    },
    subjects: {
      get () {
        return this.materiais
      },
      set (value) {
        this.materiais = value
      }
    }
  },
  data () {
    return {
      upPermission: {
        profileId: null,
        date: null
      },
      upClass: {
        class: null,
        nClass: null
      },
      fab: false,
      transition: 'scale-transition',
      indexM: 0,
      menus: {
        menuModal: false,
        menuDialog: false
      },
      addPermission: {
        user: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      materiais: null,
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-code-json',
        md: 'mdi-language-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        jpg: 'mdi-file-image',
        jpeg: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
        dwg: 'mdi-table'
      },
      openModal: {
        delCourse: false,
        vPlayer: false,
        addPermission: false,
        delPermission: false,
        newPermission: false,
        editClass: false,
        delClass: false,
        resetClass: false,
        delMaterial: false
      },
      optsVPlayer: {
        videoId: null,
        options: {
          muted: true,
          autoplay: true
        },
        playerReady: false
      },
      vSpanPlayer: [false, false, false, false, false],
      interval: null
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async setPermission () {
      if (this.isAdmin) {
        if (!this.openModal.newPermission && !this.openModal.delPermission && !this.hasPermission(this.addPermission.user.profileId)) {
          await this.getPermissions.existing.push({ profileId: this.addPermission.user.profileId, criadoEm: Date.now(), expires: new Date(this.addPermission.date).getTime(), status: true })
          this.$store.dispatch('updatePermission', this.getCurrentCourse.courseId).then(() => {
            this.resetModel()
            this.addPermission = {
              user: null,
              date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
          })
        } else if (this.openModal.newPermission) {
          const perm = this.getPermissions.existing.filter(P => {
            return P.profileId === this.upPermission.profileId
          })[0]
          if (perm.expires !== new Date(this.upPermission.date).getTime()) {
            await this.$store.dispatch('updatePermission', this.getCurrentCourse.courseId).then(() => {
              this.resetDialog()
            })
          }
        } else if (this.openModal.delPermission) {
          this.getPermissions.existing = await this.getPermissions.existing.filter(P => {
            return P.profileId !== this.upPermission.profileId
          })
          await this.$store.dispatch('updatePermission', this.getCurrentCourse.courseId).then(() => {
            this.resetDialog()
          })
        }
      }
    },
    async onClickOutsideDialog (e) {
      if (this.openModal.newPermission && e.path.length === 7) {
        this.setPermission()
      }
    },
    async onClickOutsidePlayer (e) {
      if (this.openModal.vPlayer) {
        if (e.path.length >= 17) {
          const videos = this.getCurrentCourse.classes.filter(CC => {
            return CC.id === this.optsVPlayer.videoId
          })[0]
          const views = videos.views.filter(V => {
            return V.profileId === this.profileId
          })
          if (views.length > 0) {
            new Vimeo(this.$refs.player.player.element).setCurrentTime(views[0].timing)
          }
        } else {
          this.onPlayerDown()
        }
      }
    },
    async onPlayerDown () {
      this.stopSpan()
      if (this.optsVPlayer.videoId !== null) {
        const videos = await this.getCurrentCourse.classes.filter(CC => {
          return CC.id === this.optsVPlayer.videoId
        })[0]
        const views = await videos.views.filter(V => {
          return V.profileId === this.profileId
        })
        const durationV = await new Vimeo(this.$refs.player.player.element).getDuration()
        videos.duration = await durationV
        await new Vimeo(this.$refs.player.player.element).getCurrentTime().then(CT => {
          let concludedV = false
          if (((CT / durationV) * 100) >= 80) {
            concludedV = true
          }
          // console.log(new Date(CT * 1000).toISOString().substr(11, 8))
          if (views.length > 0) {
            if (views[0].concluded === false) {
              views[0].concluded = concludedV
            }
            views[0].timing = CT
          } else {
            videos.views.push({ profileId: this.profileId, timing: CT, concluded: concludedV })
          }
          this.$store.dispatch('updateTimingClass', this.getCurrentCourse).then(() => {
            this.openModal.vPlayer = false
            this.pause()
          })
        })
      } else {
        this.openModal.vPlayer = false
      }
    },
    async openPermissionDialog (btn, profileId) {
      const perm = await this.getPermissions.existing.filter(P => {
        return P.profileId === profileId
      })[0]
      this.upPermission.profileId = perm.profileId
      this.upPermission.date = (new Date(perm.expires)).toISOString().substr(0, 10)
      switch (btn) {
        case 'del':
          this.openModal.delPermission = true
          this.openModal.newPermission = false
          break
        case 'new':
          this.openModal.newPermission = true
          this.openModal.delPermission = false
          break
        default:
          break
      }
    },
    async openClassDialog (btn, nClass) {
      this.upClass.class = nClass
      this.upClass.nClass = {
        criadoEm: nClass.criadoEm,
        desc: nClass.desc,
        duration: nClass.duration,
        id: nClass.id,
        nAula: nClass.nAula,
        nome: nClass.nome
      }
      switch (btn) {
        case 'del':
          this.openModal.delClass = true
          this.openModal.editClass = false
          this.openModal.resetClass = false
          break
        case 'edit':
          this.openModal.editClass = true
          this.openModal.delClass = false
          this.openModal.resetClass = false
          break
        case 'reset':
          this.openModal.resetClass = true
          this.openModal.editClass = false
          this.openModal.delClass = false
          break
        default:
          break
      }
    },
    async updateClass (action) {
      switch (action) {
        case 'del':
          this.getCurrentCourse.classes = this.getCurrentCourse.classes.filter(C => {
            return C.id !== this.upClass.class.id
          })
          break
        case 'up':
          this.upClass.class.criadoEm = this.upClass.nClass.criadoEm
          this.upClass.class.desc = this.upClass.nClass.desc
          this.upClass.class.duration = this.upClass.nClass.duration
          this.upClass.class.id = this.upClass.nClass.id
          this.upClass.class.nAula = this.upClass.nClass.nAula
          this.upClass.class.nome = this.upClass.nClass.nome
          break
        case 'reset':
          this.upClass.class.views.filter(V => {
            return V.profileId === this.profileId
          })[0].concluded = false
          break
        default:
          break
      }
      await this.$store.dispatch('updateClass', this.getCurrentCourse).then(() => {
        this.resetModel()
      })
    },
    hasPermission (profileId) {
      const permission = this.getPermissions.existing.filter(P => {
        return P.profileId === profileId
      })
      if (permission.length > 0) {
        return true
      } else {
        return false
      }
    },
    compareUsers (profile1, profile2) {
      // Use toUpperCase() to ignore character casing
      const bandA = profile1.nomeCompleto.toUpperCase()
      const bandB = profile2.nomeCompleto.toUpperCase()

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    },
    resetDialog () {
      this.openModal.delPermission = false
      this.openModal.newPermission = false
      this.upPermission = {
        profileId: null,
        date: null
      }
    },
    resetModel () {
      this.openModal.delCourse = false
      this.openModal.vPlayer = false
      this.openModal.addPermission = false
      this.openModal.editClass = false
      this.openModal.delClass = false
      this.openModal.resetClass = false
      this.openModal.delMaterial = false
      this.upClass = {
        class: null,
        nClass: null
      }
    },
    delCourse () {
      if (this.isAdmin) {
        this.$store.dispatch('delCourse', this.getCurrentCourse)
      }
    },
    delMaterial () {
      if (this.isAdmin) {
        this.$store.dispatch('delMaterial', { course: this.getCurrentCourse, indexM: this.indexM })
      }
    },
    createCertified () {
      this.$store.dispatch('createCertified', this.getCurrentCourse)
    },
    gerarCertificado () {
      this.$store.dispatch('gerarCertificado', this.getCurrentCourse)
    },
    uploadSubjects () {
      this.$store.dispatch('uploadSubjects', { course: this.getCurrentCourse, files: this.subjects })
    },
    onReady () {
      this.playerReady = false
    },
    showSpan () {
      this.vSpanPlayer = [false, false, false, false, false]
      this.vSpanPlayer[Math.floor(Math.random() * (5))] = true
    },
    stopSpan () {
      clearInterval(this.interval)
    },
    play () {
      this.$refs.player.play()
      this.interval = setInterval(this.showSpan, 3000)
    },
    pause () {
      this.$refs.player.pause()
      this.stopSpan()
    },
    exitFullscreen () {
      if (document.fullscreenElement) {
        document.exitFullscreen().then(() => { this.onPlayerDown() })
      }
    }
  },
  async created () {
    if (!this.$store.state.permissions.length > 0) {
      await this.$store.dispatch('getAllPermissions')
    }
    if (this.isAdmin && !this.$store.state.usersMembers.length > 0) {
      await this.$store.dispatch('getAllUsers')
    }
  }
}
</script>
<style>
.img-container{
    border-radius: 0%;
}
.border-container{
  border-width: 0.1px;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: none solid solid solid;
}
.sublinhado{
    text-decoration: line-through;
}
.btn-Buy{
    color: #1c1d1f !important;
    background-color: transparent !important;
    border: 1px solid #1c1d1f !important;
    font-weight: bold !important;
}.opts-size{
    font-size: 13px;
}
.cs-points{
    font-size: 0.8rem;
}
.title-course-card{
    font-size: 17px;
}
.card-aula {
    cursor: pointer;
    transition: opacity .4s ease-in-out;
}
.card-aula:not(.on-hover) {
    opacity: 0.6;
 }
.timeline-aulas {
    width: 60%;
 }
.wall-pointer {
    cursor: pointer;
}
div::-webkit-scrollbar {
    width: 12px;
    display: block !important;
}
div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);
}
::-webkit-scrollbar {
    display: none ;
}
.hover-icon:hover {
  color: rgb(53, 8, 255)
}
.v-speed-dial--bottom {
  bottom: 0 !important;
}
.delMaterial {
  position: absolute !important;
  right: 10px;
  z-index: 99;
}
.embed-container {
  width: 100% !important;
  left: 0;
  top: 0;
}
.vSpanPlayer1 {
  position: absolute !important;
  color: cyan;
  top: 0;
  left: 0;
}
.vSpanPlayer2 {
  position: absolute !important;
  color: cyan;
  top: 0;
  right: 0;
}
.vSpanPlayer3 {
  position: absolute !important;
  color: cyan;
  bottom: 5%;
  left: 0;
}
.vSpanPlayer4 {
  position: absolute !important;
  color: cyan;
  bottom: 5%;
  right: 0;
}
.vSpanPlayer5 {
  position: absolute !important;
  color: cyan;
  top: 50%;
  right: 40%;
}
</style>

 <template>
<div>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            Novo Usuário
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link class="link" :to="{ name: 'Usuarios'}">
            <v-btn small color="secondary">
                <v-icon>mdi-arrow-left-circle</v-icon>
                &nbsp;
                Voltar
            </v-btn>
        </router-link>
        &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="createUser()" small dark color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Enviar</span>
        </v-tooltip>
    </v-toolbar>
    <v-card elevation="0">
        <v-card-title class="py-0">
          <v-row class="justify-start">
          <v-spacer></v-spacer>
          <v-radio-group v-model="profileRules" row v-if="isMaster">
            <v-radio label="Administrador" value="Administrador"></v-radio>
            <v-radio label="Membro Comum" value="Membro Comum"></v-radio>
          </v-radio-group>
          </v-row>
        </v-card-title>
        <v-card-title class="pt-0">
          <v-row class="justify-center">
            <v-avatar size="150"><img :src="profile.photoURL" alt="User"></v-avatar>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.nome" label="Nome" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.sobreNome" label="Sobrenome" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                <v-text-field type="email" outlined v-model="profile.email" label="Endereço de Email" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                <v-text-field v-mask="'###.###.###-##'" type="text" outlined v-model="profile.CPF" label="CPF" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field v-mask="'##.###.###-#'" type="text" outlined v-model="profile.RG" label="RG" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.empresa" label="Empresa" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                <v-select @change="changePhotoURL" outlined :items="sexualidades" v-model="profile.sexo" label="Sexualidade" required></v-select>
            </v-col>
            <v-col cols="2">
                <v-text-field v-mask="'(##)#####-####'" type="text" outlined v-model="profile.telCelular" label="Tel.Celular" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.cargo" label="Cargo" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field v-mask="'(##)####-####'" type="text" outlined v-model="profile.telComercial" label="Tel.Comercial"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
                    <v-text-field v-mask="'#####-###'" type="text" outlined v-model="profileCEP" label="CEP" :rules="rules" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="1">
                <v-select outlined :items="estados" v-model="profile.estado" label="Estado"></v-select>
            </v-col>
            <v-col cols="2">
                <v-text-field type="text" outlined v-model="profile.cidade" label="Cidade"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
              <v-col cols="3">
                  <v-text-field type="text" outlined v-model="profile.logradouro" label="Logradouro"></v-text-field>
              </v-col>
              <v-col cols="3">
                  <v-text-field type="text" outlined v-model="profile.bairro" label="Bairro"></v-text-field>
              </v-col>
          </v-row>
          <v-row class="justify-center">
              <v-col cols="6">
                  <v-textarea type="text" outlined v-model="profile.bio" label="Biografria"></v-textarea>
              </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      rules: [
        value => !!value || 'CEP Inválido.',
        value => (value && value.length >= 8 && value.length <= 9) || 'Refazer CEP'
      ],
      estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      sexualidades: ['Masculino', 'Feminino', 'Não-binário'],
      profile: {
        CEP: null,
        CPF: null,
        RG: null,
        nome: null,
        sobreNome: null,
        bairro: null,
        cargo: null,
        cidade: null,
        criadoEm: null,
        email: null,
        empresa: null,
        estado: null,
        logradouro: null,
        rules: {
          isAdmin: false
        },
        photoURL: 'https://img.icons8.com/color/99/000000/circled-user-female-skin-type-5--v1.png',
        sexo: 'Feminino',
        telCelular: null,
        telComercial: null,
        bio: null
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    changePhotoURL () {
      if (this.profile.sexo === 'Feminino') {
        this.profile.photoURL = 'https://img.icons8.com/color/99/000000/circled-user-female-skin-type-5--v1.png'
      } else if (this.profile.sexo === 'Masculino') {
        this.profile.photoURL = 'https://img.icons8.com/color/99/000000/user-male-circle--v1.png'
      } else {
        this.profile.photoURL = 'https://img.icons8.com/material-rounded/99/000000/user-male-circle.png'
      }
    },
    createUser () {
      if (this.isAdmin && this.nome !== null && this.email !== null) {
        this.profile.criadoEm = Date.now()
        this.$store.dispatch('createUser', this.profile).then(() => this.$router.push({ name: 'Usuarios' }))
      } else {
        alert('Prencher campos Nome e Email.')
      }
    }
  },
  computed: {
    profileCEP: {
      get () {
        return this.profile.CEP
      },
      set (value) {
        value = value.replace('-', '')
        if (value.length === 8) {
          axios.get(`https://viacep.com.br/ws/${value}/json/`)
            .then(response => {
              this.profile.CEP = response.data.cep
              this.profile.estado = response.data.uf
              this.profile.cidade = response.data.localidade
              this.profile.logradouro = response.data.logradouro
              this.profile.bairro = response.data.bairro
            }).catch(error => console.log(error))
        }
      }
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    profileRules: {
      get () {
        return 'Membro Comum'
      },
      set (value) {
        if (value === 'Administrador') {
          this.profile.rules.isAdmin = true
        } else {
          this.profile.rules.isAdmin = false
        }
      }
    }
  }
}
</script>

<style>
.camera {
    cursor: pointer;
}
</style>

<template>
    <v-card>
        <v-toolbar flat>
            <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
            <v-toolbar-title>
                &nbsp;
                Sugestões
            </v-toolbar-title>
        </v-toolbar>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="Buscar" single-line hide-details outlined></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="getSuggestions" sort-by="criadoEm" :items-per-page="20" :sort-desc="true" :search="search" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há sugestões no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-slot:[`item.nomeUser`]="{ item }">
          {{ item.nomeUser }}
        </template>
        <template v-slot:[`item.mensagem`]="{ item }">
          <b><i>{{ item.mensagem }}</i></b>
        </template>
        <template v-slot:[`item.criadoEm`]="{ item }">
          {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.buttons`]="{ item }">
          <v-btn text small @click="openModalSugestion(item)">
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
    </v-data-table>
        <v-dialog v-model="modalDelSuggestion" max-width="400px">
      <v-card>
          <v-card-title class="text-h6">
              Excluir sugestão?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="delSuggestion()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
    </template>

<script>
export default {
  data () {
    return {
      item: null,
      modalDelSuggestion: false,
      search: '',
      headers: [
        {
          text: 'Usuário',
          align: 'start',
          sortable: true,
          value: 'nomeUser'
        },
        {
          text: 'Mensagem',
          align: 'start',
          sortable: true,
          value: 'mensagem',
          width: '500'
        },
        {
          text: 'Data de Envio',
          align: 'center',
          sortable: true,
          value: 'criadoEm'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'buttons'
        }
      ]
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async openModalSugestion (item) {
      this.item = item
      this.modalDelSuggestion = true
    },
    async delSuggestion () {
      await this.$store.dispatch('delSuggestion', this.item.docId).then(() => {
      })
    }
  },
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getSuggestions () {
      return this.$store.state.suggestions
    }
  },
  async created () {
    if (this.isAdmin) {
      if (!this.$store.state.suggestions.length > 0) {
        await this.$store.dispatch('getAllSuggestions')
      }
    }
  }
}
</script>

    <style>

    </style>

<template>
<div>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            &nbsp;
            Protocolo de Atendimento: {{ currentTicket.protoId }}
            <v-spacer></v-spacer>
        </v-toolbar-title>
        &nbsp;
        <v-col class="d-flex mt-5" cols="4" sm="2" v-if="isAdmin">
          <v-select @change="changeLvL" :items="lvls" label="Dificuldade" v-model="currentTicket.lvl"></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <router-link class="link" :to="{ name: 'Chamados'}">
            <v-btn small color="secondary">
                <v-icon>mdi-arrow-left-circle</v-icon>
                &nbsp;
                Voltar
            </v-btn>
        </router-link>
        &nbsp;
        <v-tooltip bottom v-if="isAdmin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="modal = true" small dark color="success" v-bind="attrs" v-on="on">
              <v-icon v-if="currentTicket.status !== 3 && currentTicket.status !== 4">mdi-check</v-icon>
              <v-icon v-else>mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span v-if="currentTicket.status !== 3 && currentTicket.status !== 4">Finalizar</span>
          <span v-else>Finalização</span>
        </v-tooltip>
    </v-toolbar>
    <v-row>
        <v-col cols="12">
            <v-chip text-color="white" class="ma-3" color="red" label v-if="currentTicket.status === 1">
                <strong> Situação: &nbsp; </strong>
                Novo
            </v-chip>
            <v-chip text-color="white" class="ma-3" color="orange" label v-if="currentTicket.status === 2">
                <strong> Situação: &nbsp; </strong>
                Em Andamento
            </v-chip>
            <v-chip text-color="white" class="ma-3" color="green" label v-if="currentTicket.status === 3">
                <strong> Situação: &nbsp; </strong>
                Atendimento Concluído
            </v-chip>
            <v-chip text-color="white" class="ma-3" color="#34c0eb" label v-if="currentTicket.status === 4">
                <strong> Situação: &nbsp; </strong>
                Reaberto
            </v-chip>
            <v-chip text-color="white" class="ma-3" color="#17a2b8" label v-if="currentTicket.status === 5">
                <strong> Situação: &nbsp; </strong>
                Respondido
            </v-chip>
            <v-chip class="ma-3" color="#ffffcc" label>
                <strong> Data de Postagem: &nbsp; </strong>
                {{ new Date(currentTicket.criadoEm).toLocaleString('pt-BR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", hour12: false }) }}
            </v-chip>
            <v-chip class="ma-3" color="#ddccff" label>
                <strong> Tipo: &nbsp; </strong>
                {{ currentTicket.tipo }}
            </v-chip>
        </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
        <v-col cols="12">
            <v-responsive class="overflow-y-auto pa-3" max-height="80vh">
                <v-timeline>
                    <div v-if="currentTicket.status !== 3">
                        <v-timeline-item large right>
                            <template v-slot:icon>
                                <v-avatar>
                                    <img :src="profilePhotoURL">
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h5">
                                </v-card-title>
                                <v-card-text>
                                    <vue-editor :editorToolbar="customToolbar" v-model="newTicket.descHTML" />
                                </v-card-text>
                                <v-card-actions>
                                    <v-file-input v-model="newTicket.files" label="Anexar Arquivos" multiple prepend-icon="mdi-paperclip" outlined :show-size="1000">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip v-if="index < 2" color="secondary" dark label small>
                                                {{ text }}
                                            </v-chip>
                                            <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                                                +{{ files.length - 2 }} Arquivo(s)
                                            </span>
                                        </template>
                                    </v-file-input>
                                    <v-btn class="btn-send" @click="updateTicket()" color="primary">
                                        <v-icon>mdi-send-check</v-icon>
                                        &nbsp;
                                        Enviar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-timeline-item>
                    </div>
                    <div v-for="(item, n) in currentTicket.interactions.slice().reverse()" :key="n">
                        <v-timeline-item large right v-if='item.profileId === profileId'>
                            <template v-slot:icon>
                                <v-avatar>
                                    <img :src="item.profilePhotoURL">
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span> {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h5">
                                    {{ item.profileName }}
                                    <v-icon color="red" v-if="profileId === item.profileId && isAdmin" class="trash" @click="delComment(item)">mdi-delete</v-icon>
                                </v-card-title>
                                <v-card-text v-html="item.descHTML"></v-card-text>
                                <v-chip-group>
                                    <a v-for="(arq, index) in item.arquivosURL" target="_blank" class="link" :href="arq.link" :key="index" download>
                                        <v-chip class="ma-3" color="#7722c7" outlined>{{arq.nome}}</v-chip>
                                    </a>
                                </v-chip-group>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item large left v-else>
                            <template v-slot:icon>
                                <v-avatar>
                                    <img :src="item.profilePhotoURL">
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span> {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h5">
                                    {{ item.profileName }}
                                    <v-icon color="red" v-if="profileId === item.profileId" class="trash" @click="delComment(item)">mdi-delete</v-icon>
                                </v-card-title>
                                <v-card-text v-html="item.descHTML"></v-card-text>
                                <v-chip-group>
                                    <a v-for="(arq, index) in item.arquivosURL" target="_blank" class="link" :href="arq.link" :key="index" download>
                                        <v-chip class="ma-3" color="#7722c7" outlined>{{arq.nome}}</v-chip>
                                    </a>
                                </v-chip-group>
                            </v-card>
                        </v-timeline-item>
                    </div>
                </v-timeline>
            </v-responsive>
        </v-col>
    </v-row>
    <v-dialog v-model="modal" max-width="500px">
        <v-card>
            <v-card-text class="pt-4">
              <vue-editor :editorToolbar="customToolbar" v-model="currentTicket.desfecho.solucao" />
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn color="secondary" text @click="modal = false">
                    Fechar
                </v-btn>
                <v-btn v-if="currentTicket.status !== 3 && currentTicket.status !== 4" v-show="currentTicket.desfecho.solucao.length >= 10" color="green" text @click="finalizarTicket()">
                    Finalizar
                </v-btn>
                <v-btn v-else v-show="currentTicket.desfecho.solucao.length >= 10" color="primary" text @click="finalizarTicket()">
                    Atualizar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
  computed: {
    profileId () {
      return this.$store.state.profileId
    },
    profilePhotoURL () {
      return this.$store.state.profile.photoURL
    },
    profileName () {
      return this.$store.state.profile.nomeCompleto
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getTickets () {
      return this.$store.state.tickets
    },
    getNotifications () {
      return this.$store.state.notifications.filter(N => {
        return N.redirectId === this.$route.params.idchamado && N.status === 0
      })
    }
  },
  data () {
    return {
      rules: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 10) || 'Mínimo de 10 Characteres'
      ],
      modal: false,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }],
        [{
          color: []
        }]
      ],
      newTicket: {
        descHTML: null,
        files: []
      },
      currentTicket: {
        profileId: null,
        status: null,
        criadoEm: null,
        titulo: null,
        protoId: null,
        tipo: null,
        ticketId: null,
        lvl: null,
        desfecho: {
          solucao: ''
        },
        interactions: []
      },
      lvls: ['Normal', 'Intermediário', 'Complicado']
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async delComment (comment) {
      this.$store.dispatch('delComment', { ticket: this.currentTicket, comment })
    },
    async finalizarTicket () {
      this.$store.dispatch('finalizarTicket', { ticket: this.currentTicket })
    },
    async updateTicket () {
      this.$store.dispatch('updateTicket', { ticket: this.currentTicket, new: this.newTicket })
    },
    compare (interaction1, interaction2) {
      const bandA = interaction1.criadoEm
      const bandB = interaction2.criadoEm

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    },
    async changeLvL () {
      this.$store.dispatch('updateLvLTicket', this.currentTicket)
    }
  },
  async mounted () {
    const currentTickets = await this.getTickets.filter(ticket => {
      return ticket.ticketId === this.$route.params.idchamado
    })
    if (currentTickets.length > 0) {
      this.currentTicket.profileId = currentTickets[0].profileId
      this.currentTicket.status = currentTickets[0].status
      this.currentTicket.criadoEm = currentTickets[0].criadoEm
      this.currentTicket.titulo = currentTickets[0].titulo
      this.currentTicket.protoId = currentTickets[0].protoId
      this.currentTicket.tipo = currentTickets[0].tipo
      this.currentTicket.ticketId = currentTickets[0].ticketId
      this.currentTicket.interactions = currentTickets[0].interactions.slice().sort(this.compare)
      if (currentTickets[0].desfecho) {
        this.currentTicket.desfecho = currentTickets[0].desfecho
      }
      if (currentTickets[0].lvl) {
        this.currentTicket.lvl = currentTickets[0].lvl
      }
    }
  },
  async created () {
    this.$store.dispatch('loading', false)
    if (this.getNotifications.length > 0) {
      await this.$store.dispatch('updateAllNotifications', this.$route.params.idchamado)
    }
  }
}
</script>

<style>
.container {
    max-width: 100%;
    padding: 60px 25px;
    max-height: 100%;
}

.btn-send {
    margin: 0px 10px 30px 10px !important;
}

.trash {
    position: absolute !important;
    right: 10px;
}
img {
  max-width: 600px !important;
}
</style>

<template>
    <Bar :chart-data="chartData" :chart-options="chartOptions" />
  </template>
<script>
import { Bar } from 'vue-chartjs/legacy'

export default {
  components: { Bar },
  computed: {
    chartData () {
      return {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Vencimentos CADInfra™',
            backgroundColor: 'rgba(0, 167, 222, 255)',
            borderColor: '#005c8b',
            borderWidth: 1.5,
            data: [
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 0 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 1 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 2 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 3 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 4 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 5 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 6 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 7 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 8 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 9 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 10 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsInfra.filter(T => { return new Date(T.expires).getMonth() === 11 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length
            ]
          },
          {
            label: 'Vencimentos C3DFarm™',
            backgroundColor: 'rgba(101, 134, 87, 255)',
            borderColor: '#283623',
            borderWidth: 1.5,
            data: [
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 0 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 1 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 2 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 3 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 4 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 5 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 6 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 7 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 8 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 9 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 10 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length,
              this.licsFarm.filter(T => { return new Date(T.expires).getMonth() === 11 && new Date(T.expires).getFullYear() === new Date().getFullYear() }).length
            ]
          }
        ]
      }
    },
    chartOptions () {
      return { responsive: true, maintainAspectRatio: false }
    },
    getLics () {
      return this.$store.state.masterHome.licenses.values
    },
    licsInfra () {
      return this.getLics.filter(F => {
        return F.licType === 'DRENESGINFRA' || F.licType === 'CADInfra'
      })
    },
    licsFarm () {
      return this.getLics.filter(F => {
        return F.licType === 'C3DFarm'
      })
    }
  }
}
</script>
  <style>
  </style>

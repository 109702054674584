var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.changeNav()}}}),_c('v-toolbar-title',[_vm._v(" Editar Curso ")]),_c('v-spacer'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'VisualizarCurso', params: { idcurso: this.$route.params.idcurso } }}},[_c('v-btn',{attrs:{"small":"","color":"secondary"}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle")]),_vm._v("   Voltar ")],1)],1),_vm._v("   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.updateCurso()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send-check")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])])],1),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","label":"Título"},model:{value:(_vm.editCourse.titulo),callback:function ($$v) {_vm.$set(_vm.editCourse, "titulo", $$v)},expression:"editCourse.titulo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Tipo","outlined":""},model:{value:(_vm.editCourse.tipo),callback:function ($$v) {_vm.$set(_vm.editCourse, "tipo", $$v)},expression:"editCourse.tipo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.adminMembers,"label":"Instrutor","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(item.nomeCompleto + ' (' + item.email +')')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(item.nomeCompleto)+" ")])]}}]),model:{value:(_vm.editCourse.teacher),callback:function ($$v) {_vm.$set(_vm.editCourse, "teacher", $$v)},expression:"editCourse.teacher"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","label":"Descrição (Simples)","required":""},model:{value:(_vm.editCourse.descSimples),callback:function ($$v) {_vm.$set(_vm.editCourse, "descSimples", $$v)},expression:"editCourse.descSimples"}})],1)],1),_c('v-row',{staticClass:"CBS"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"items":_vm.languages,"label":"Linguagem do Vídeo","outlined":"","dense":"","hide-selected":""},model:{value:(_vm.editCourse.linguagem),callback:function ($$v) {_vm.$set(_vm.editCourse, "linguagem", $$v)},expression:"editCourse.linguagem"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"items":_vm.languages,"label":"Legendas Disponíveis","multiple":"","outlined":"","dense":"","hide-selected":""},model:{value:(_vm.editCourse.legendas),callback:function ($$v) {_vm.$set(_vm.editCourse, "legendas", $$v)},expression:"editCourse.legendas"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"label":"Auxiliares","items":_vm.adminMembers,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(item.nomeCompleto + ' (' + item.email +')')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(item.nomeCompleto)+" ")])]}}]),model:{value:(_vm.editCourse.auxiliares),callback:function ($$v) {_vm.$set(_vm.editCourse, "auxiliares", $$v)},expression:"editCourse.auxiliares"}})],1)],1)],1),_c('v-card-text',[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.editCourse.descHTML),callback:function ($$v) {_vm.$set(_vm.editCourse, "descHTML", $$v)},expression:"editCourse.descHTML"}})],1),_c('v-card-actions',[_c('v-file-input',{attrs:{"label":"Capa (370x250)","prepend-icon":"mdi-image-album","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"secondary","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.editCourse.file),callback:function ($$v) {_vm.$set(_vm.editCourse, "file", $$v)},expression:"editCourse.file"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
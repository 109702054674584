<template>
  <Pie :chart-options="chartOptions" :chart-data="chartData" />
</template>
<script>
/* import { Doughnut } from 'vue-chartjs/legacy' */
import { Pie } from 'vue-chartjs/legacy'

export default {
  components: { Pie },
  computed: {
    chartOptions () {
      return {
        responsive: true, maintainAspectRatio: false
      }
    },
    chartData () {
      const ATickets = this.getTickets.filter(T => {
        return T.status === 2
      })
      const FTickets = this.getTickets.filter(T => {
        return T.status === 3
      })
      const RTickets = this.getTickets.filter(T => {
        return T.status === 4
      })
      const RSTickets = this.getTickets.filter(T => {
        return T.status === 5
      })
      return {
        labels: [
          'Novos',
          'Em Aberto',
          'Finalizados',
          'Reabertos',
          'Respondidos'
        ],
        datasets: [{
          label: 'Chamados',
          data: [this.getTickets.length, ATickets.length, FTickets.length, RTickets.length, RSTickets.length],
          backgroundColor: ['rgba(255, 100, 130, 0.7)', 'rgba(220, 170, 64, 0.7)', 'rgba(100, 192, 192, 0.7)', 'rgba(0, 255, 255, 0.7)', 'rgba(80, 162, 235, 0.7)'],
          borderColor: ['red', 'orange', 'green', '#34c0eb', '#17a2b8'],
          borderWidth: 2,
          hoverOffset: 5
        }]
      }
    },
    getTickets () {
      return this.$store.state.masterHome.tickets.values
    }
  }
}
</script>
<style>
</style>

<template>
    <Pie :chart-options="chartOptions" :chart-data="chartData" />
  </template>
<script>
import { Pie } from 'vue-chartjs/legacy'

export default {
  components: { Pie },
  computed: {
    chartOptions () {
      return {
        responsive: true, maintainAspectRatio: false
      }
    },
    chartData () {
      const licsInfra = this.getLics.filter(F => {
        return F.licType === 'DRENESGINFRA'
      })
      const licsFarm = this.getLics.filter(F => {
        return F.licType === 'C3DFarm'
      })

      var AtvsInfra = 0
      var AtvsFarm = 0

      licsInfra.forEach(F => {
        AtvsInfra += F.stations
      })

      licsFarm.forEach(F => {
        AtvsFarm += F.stations
      })

      return {
        labels: [
          'Ativações CADInfra™',
          'Ativações C3DFarm™'
        ],
        datasets: [{
          label: 'Licenças',
          data: [AtvsInfra, AtvsFarm],
          backgroundColor: ['rgba(0, 167, 222, 255)', 'rgba(101, 134, 87, 255)'],
          borderColor: ['#005c8b', '#283623'],
          borderWidth: 2,
          hoverOffset: 5
        }]
      }
    },
    getLics () {
      return this.$store.state.masterHome.licenses.values
    }
  }
}
</script>
  <style>
  </style>

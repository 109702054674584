<template>
<div>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            &nbsp;
            Cursos
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link v-if="isAdmin" class="link" :to="{ name: 'NovoCurso'}">
        &nbsp;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-chair-school</v-icon>
            </v-btn>
          </template>
          <span>Novo Curso</span>
        </v-tooltip>
      </router-link>
    </v-toolbar>
    <v-card elevation="0" v-if="getMyCourses.length > 0">
      <v-card-title class="ml-5 py-0">Disponíveis</v-card-title>
      <v-card-text>
        <v-row class="d-flex justify-center">
            <v-card class="text-justify my-6 mx-3" max-width="370" height="500" v-for="(item, n) in getMyCourses" :key="n">
            <v-img height="200" :src="item.photoURL"></v-img>
            <v-card-title class="justify-center text-justify pa-2"><h5>{{ item.titulo }}</h5></v-card-title>
            <v-card-subtitle class="mt-1">{{ item.descSimples }}</v-card-subtitle>
            <v-card elevation="0" class="position-absolute b-0" width="100%">
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row class="mx-0 align-center">
                    <v-avatar color="white" size="45"><img :src="item.teacher.photoURL" alt="User"></v-avatar>
                    <div class="ml-5">
                        <v-row>
                            <h4>{{ item.teacher.nomeCompleto }}</h4>
                        </v-row>
                        <v-row>
                            <h5>{{ item.teacher.cargo }}</h5>
                        </v-row>
                    </div>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <router-link class="link" :to="{ name: 'VisualizarCurso', params: { idcurso: item.courseId }}">
                  <v-btn color="deep-purple" text>
                      Acessar
                  </v-btn>
              </router-link>
            </v-card-actions>
            </v-card>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" v-if="getAllCourses.length > 0">
        <v-row class="d-flex justify-center">
            <v-card class="text-justify my-6 mx-3" max-width="370" height="500" v-for="(item, n) in getAllCourses" :key="n">
                <v-img height="200" :src="item.photoURL"></v-img>
                <v-card-title class="justify-center text-justify pa-2"><h5>{{ item.titulo }}</h5></v-card-title>
<!--                             <v-row>
                        <v-col cols="12">
                            <div class="d-flex justify-center">
                                ---
                            </div>
                        </v-col>
                    </v-row> -->
                <v-card-subtitle class="mt-1">{{ item.descSimples }}</v-card-subtitle>
                <v-card elevation="0" class="position-absolute b-0" width="100%">
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                    <v-row class="mx-0 align-center">
                        <v-avatar color="white" size="45"><img :src="item.teacher.photoURL" alt="User"></v-avatar>
                        <div class="ml-5">
                            <v-row>
                                <h4>{{ item.teacher.nomeCompleto }}</h4>
                            </v-row>
                            <v-row>
                                <h5>{{ item.teacher.cargo }}</h5>
                            </v-row>
                        </div>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                  <router-link v-if="isAdmin" class="link" :to="{ name: 'VisualizarCurso', params: { idcurso: item.courseId }}">
                      <v-btn color="deep-purple" text>
                          Acessar
                      </v-btn>
                  </router-link>
                  <v-btn v-else color="deep-purple" text>
                      Adquirir
                  </v-btn>
                </v-card-actions>
                </v-card>
            </v-card>
        </v-row>
    </v-card>
</div>
</template>

<script>
export default {
  computed: {
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    getMyCourses () {
      return this.$store.state.courses.filter(c => {
        return c.itsMine === true
      })
    },
    getAllCourses () {
      return this.$store.state.courses.filter(c => {
        return c.itsMine === null
      }).sort(this.compare)
    }
  },
  async created () {
    if (!this.$store.state.courses.length > 0) {
      await this.$store.dispatch('getAllCourses')
    }
    if (!this.$store.state.adminMembers.length > 0) {
      await this.$store.dispatch('getAllAdmins')
    }
    if (!this.$store.state.permissions.length > 0) {
      await this.$store.dispatch('getAllPermissions')
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    compare (Course1, Course2) {
      // Use toUpperCase() to ignore character casing
      const bandA = Course1.titulo.toUpperCase()
      const bandB = Course2.titulo.toUpperCase()

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    }
  }
}
</script>

<style>
.course-time {
    position: absolute !important;
    left: 16px !important;
}
.b-0{
    bottom: 0 !important;
}
</style>

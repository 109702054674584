<template>
    <LineChartGenerator :chart-data="chartData" :chart-options="chartOptions" />
  </template>
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
export default {
  components: { LineChartGenerator },
  computed: {
    chartData () {
      return {
        labels: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        datasets: [
          {
            label: 'Contatos Criados',
            borderColor: 'blue',
            pointBackgroundColor: 'red',
            borderWidth: 2,
            radius: 2,
            fill: true,
            pointBorderColor: 'red',
            backgroundColor: 'rgba(54, 162, 235, 0.4)',
            tension: 0.3,
            data: [
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getFollowUp.filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Contatos Fechados',
            borderColor: 'lightgreen',
            pointBackgroundColor: 'red',
            borderWidth: 3,
            radius: 2,
            fill: true,
            pointBorderColor: 'red',
            backgroundColor: 'rgba(100, 192, 192, 0.4)',
            tension: 0.3,
            data: [
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 0 && T.closure }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 1 && T.closure }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 2 && T.closure }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 3 && T.closure }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 4 && T.closure }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 5 && T.closure }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 6 && T.closure }).length
            ]
          },
          {
            label: 'Propostas Enviadas',
            borderColor: 'orange',
            pointBackgroundColor: 'red',
            borderWidth: 2,
            radius: 2,
            fill: true,
            pointBorderColor: 'red',
            backgroundColor: 'rgba(220, 170, 64, 0.4)',
            tension: 0.3,
            data: [
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 0 && T.isCalled }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 1 && T.isCalled }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 2 && T.isCalled }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 3 && T.isCalled }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 4 && T.isCalled }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 5 && T.isCalled }).length,
              this.getFollowUp.filter(T => { return new Date(T.fechadoEm).getDay() === 6 && T.isCalled }).length
            ]
          }
        ]
      }
    },
    chartOptions () {
      return { responsive: true, maintainAspectRatio: false }
    },
    getFollowUp () {
      return this.$store.state.masterHome.followUp.values
    }
  }
}
</script>
  <style>
  </style>

<template>
<div>
    <v-card elevation="0" class="mt-2 centralizado">
        <v-row>
            <v-col cols="6" sm="6" class="d-flex justify-end">
                <img src="../assets/CorteC.png">
            </v-col>
            <v-col cols="6" sm="3">
                <v-card-title><h3>Preencha o Formulário</h3></v-card-title>
                <v-text-field v-model="form.nome" filled label="Nome" prepend-inner-icon="mdi-account"></v-text-field>
                <v-text-field v-model="form.telCelular" v-mask="'(##)#####-####'" filled label="Tel.Celular" prepend-inner-icon="mdi-cellphone"></v-text-field>
                <v-text-field v-model="form.email" :rules="emailRules" filled label="Email" prepend-inner-icon="mdi-email"></v-text-field>
                <v-text-field v-model="form.curso" filled label="Curso" prepend-inner-icon="mdi-school"></v-text-field>
                <v-btn width="100%" color="primary" @click="sendForm()">
                    <v-icon>mdi-send-check</v-icon>
                    &nbsp;Enviar
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</div>
</template>
<script>
export default {
  data () {
    return {
      emailRules: [
        v => !!v || 'O email é obrigatório',
        v => /.+@.+\..+/.test(v) || 'O email não é válido'
      ],
      form: {
        nome: null,
        telCelular: null,
        email: null,
        curso: null
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async sendForm () {
      if (this.form.nome !== null && this.form.telCelular !== null && this.form.email !== null && this.form.curso !== null) {
        this.$store.dispatch('createUserRaffle', this.form)/* .then(() => this.$router.push({ name: 'Login' })) */
      }
    }
  }
}
</script>
<style>
.centralizado {
    width: 100%;
    position: absolute;
    top: 25%;
}
</style>

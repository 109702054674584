<template>
  <v-card style="height: 100%;">
      <v-toolbar flat>
        <v-toolbar-title>
            &nbsp;
            <b>Ativações <i v-show="lic !== null">({{filtMachines.length}} / {{getStations}})</i></b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
        <v-card class="pa-5" outlined v-if="lic === null" style="height: 100%;">
          <v-timeline align="start">
          <v-timeline-item v-for="(item, i) in lics" :key="i" hide-dot dot-color="pink" size="small" class="height: 100% !important">
            <v-card outlined>
              <v-card-title class="d-flex justify-center" :class="['text-h6', 'blue']">
                {{ changeFrontName(item.licType) }}
              </v-card-title>
              <v-card-text class="bg-white text--primary pt-4 text-center">
                <p>{{ item.serialKey }}</p>
                <v-btn color="black" outlined @click="openLic(item)">
                  Selecionar
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        </v-card>

        <v-card class="pa-5" v-if="lic !== null" style="height: 100%;" outlined>
          <v-row>
            <v-col v-for="(item, index) in filtMachines" :key="index" cols="6" sm="6" md="4" lg="3">
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }).replace(',','') }} <v-spacer></v-spacer> <v-icon v-if="item.machineName === getInfoLicenseApp.MachineCode" color="primary">mdi-desktop-classic</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-list dense class="text-center">
                  <v-list-item v-for="(key, index) in filteredKeys" :key="index" class="text-start">
                    <v-list-item-content>
                      {{ nKeys[key] }}:
                    </v-list-item-content>
                    <v-list-item-content v-if="key !== 'status'" class="align-end">
                      {{ item[key] }}
                    </v-list-item-content>
                    <v-list-item-content v-else class="align-end">
                      <v-chip x-small v-if="item[key] === 'desactive'" color="red"></v-chip>
                      <v-chip x-small v-else color="green"></v-chip>
                    </v-list-item-content>
                  </v-list-item>
                  <v-btn color="black" v-if="item.machineName === getInfoLicenseApp.MachineCode" disabled outlined>
                    Este Computador
                  </v-btn>
                  <v-btn color="black" v-else :disabled="containsMachineInLic" outlined @click="desativeActivation(item)">
                    Selecionar
                  </v-btn>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
  </v-card>
  </template>

<script>

export default {
  computed: {
    getInfoLicenseApp () {
      return this.$store.state.infoLicenseApp
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'criadoEm')
    },
    lics () {
      return this.$store.state.licensesApp
    },
    getStations () {
      if (this.lic !== null) {
        return this.lic.stations
      } else {
        return 0
      }
    },
    filtMachines () {
      if (this.lic !== null) {
        return this.lic.activates.filter(A => {
          return A.status === 'active'
        })
      }
      return []
    },
    filtLimitMachines () {
      if (this.lic.stations > this.filtMachines.length) {
        return false
      } else {
        return true
      }
    },
    containsMachineInLic () {
      const filtro = this.filtMachines.filter(M => {
        return this.getInfoLicenseApp.MachineCode === M.machineName
      })
      if (filtro.length > 0) {
        return true
      }
      return false
    },
    lic: {
      get () {
        return this.$store.state.licUsing
      },
      set (value) {
        this.$store.state.licUsing = value
      }
    }
  },
  methods: {
    async openLic (item) {
      this.lic = item
      this.lic.activates = this.lic.activates.filter(A => {
        return A.machineName !== this.getInfoLicenseApp.MachineCode
      })
      /* const filtro = this.lic.activates.filter(A => {
        return A.machineName === this.getInfoLicenseApp.MachineCode
      }) */

      /* if (!filtro.length > 0) { */
      if (!this.filtLimitMachines) {
        await this.lic.activates.push({
          city: this.getInfoLicenseApp.City,
          coords: this.getInfoLicenseApp.Location,
          criadoEm: new Date().getTime(),
          hardDisk: this.getInfoLicenseApp.HD,
          machineName: this.getInfoLicenseApp.MachineCode,
          motherBoard: this.getInfoLicenseApp.PM,
          osName: this.getInfoLicenseApp.MachineName,
          status: 'active'
        })
        await this.$store.dispatch('updateLicense', [this.lic])
      }
      /* } else {
        if (!this.filtLimitMachines) {
          filtro[0].status = 'active'
        }
        if (filtro[0].status === 'active') {
          var tokenId = ''
          await this.$store.state.user.getIdToken().then(idToken => {
            tokenId = idToken
          })
          const newDocLic = {
            expires: this.lic.expires,
            dataServidor: new Date().getTime(),
            message: this.lic.message,
            perpetual: this.lic.perpetual,
            statusLic: this.lic.statusLic,
            licType: this.lic.licType,
            activate: filtro[0],
            profileId: this.$store.state.profileId,
            tokenId: tokenId
          }
          filtro[0].city = this.getInfoLicenseApp.City
          filtro[0].coords = this.getInfoLicenseApp.Location
          filtro[0].criadoEm = new Date().getTime()
          filtro[0].hardDisk = this.getInfoLicenseApp.HD
          filtro[0].motherBoard = this.getInfoLicenseApp.PM
          filtro[0].osName = this.getInfoLicenseApp.MachineName

          await this.$store.dispatch('updateLicense', [this.lic])
          await this.exportToJsonFile(newDocLic)
        }
      } */
    },
    async desativeActivation (item) {
      const copyLic = Object.assign({}, this.lic)

      const filtro = await copyLic.activates.filter(A => {
        return A.machineName === this.getInfoLicenseApp.MachineCode
      })
      if (filtro.length > 0) {
        filtro[0].status = 'active'
      } else {
        await copyLic.activates.push({
          city: this.getInfoLicenseApp.City,
          coords: this.getInfoLicenseApp.Location,
          criadoEm: new Date().getTime(),
          hardDisk: this.getInfoLicenseApp.HD,
          machineName: this.getInfoLicenseApp.MachineCode,
          motherBoard: this.getInfoLicenseApp.PM,
          osName: this.getInfoLicenseApp.MachineName,
          status: 'active'
        })
      }
      const filtroAtv = copyLic.activates.filter(A => {
        return A.machineName === item.machineName
      })
      filtroAtv[0].status = 'desactive'
      await this.$store.dispatch('updateLicense', [copyLic])
    },
    changeFrontName (licType) {
      switch (licType) {
        case 'CADInfra':
          return 'CADInfra™ (Básico)'
        case 'DRENESGINFRA':
          return 'CADInfra™ (Completo)'
        case 'DRENESG':
          return 'Drenagem e Esgoto'
        case 'CortexForNavis':
          return 'CortexForNavis™'
        case 'C3DFarm':
          return 'C3DFarm™'
        default:
          return licType
      }
    },
    async exportToJsonFile (fileJson) {
      if (fileJson !== null) {
        const dataStr = JSON.stringify(fileJson)
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr)

        const exportFileDefaultName = 'data.json'

        const linkElement = document.createElement('a')
        linkElement.setAttribute('href', dataUri)
        linkElement.setAttribute('download', exportFileDefaultName)
        linkElement.click()
      }
    }
  },
  data () {
    return {
      keys: [
        'machineName',
        'osName',
        'criadoEm',
        'status'
      ],
      nKeys: {
        machineName: 'Computador',
        osName: 'Nome do Dispositivo',
        criadoEm: 'Criado Em',
        status: 'Status'
      },
      licAtual: null
    }
  }
}
document.addEventListener('contextmenu', event => event.preventDefault())
document.addEventListener('keydown', (e) => {
  e = e || window.event
  if (e.keyCode === 116) {
    e.preventDefault()
  }
})
</script>
  <style>
  ::-webkit-scrollbar {
      display: none;
  }
  </style>

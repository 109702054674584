<template>
  <v-card>
    <v-toolbar flat>
        <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
        <v-toolbar-title>
            &nbsp;
            Mapas
        </v-toolbar-title>
    </v-toolbar>
    <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" label="Buscar" single-line hide-details outlined></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="geoJsonSources" :search="search" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há Arquivos no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-slot:[`item.docId`]="{ item }">
            <router-link class="link" :to="{ name: 'VisualizarMapa', params: { docId: item.docId } }">
                <v-btn text small>
                    <v-icon color="secondary">mdi-eye-circle</v-icon>
                    &nbsp; Visualizar
                </v-btn>
            </router-link>
        </template>
        <template v-slot:[`item.criadoEm`]="{ item }">
            {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
            <v-btn text small @click="delMap(item)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      headers: [{
        text: '',
        align: 'center',
        value: 'docId',
        sortable: false
      },
      {
        text: 'Nome',
        align: 'left',
        value: 'name'
      },
      {
        text: 'Quantidade de Objetos',
        align: 'center',
        value: 'qtdObjects'
      },
      {
        text: 'Data de Criação',
        align: 'center',
        value: 'criadoEm'
      },
      {
        text: '',
        value: 'tipo',
        align: 'center',
        sortable: false
      }]
    }
  },
  computed: {
    geoJsonSources () {
      return this.$store.state.jsonSources
    }
  },
  methods: {
    delMap (map) {
      this.$store.dispatch('delMap', map)
    }
  },
  async mounted () {
    if (!this.$store.state.jsonSources.length > 0) {
      await this.$store.dispatch('getAllGeoJsons')
    }
  }
}
</script>
<style scoped>
</style>

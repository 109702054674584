<template>
    <v-card>
        <v-toolbar flat>
            <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
            <v-toolbar-title>
                Downloads
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <router-link v-if="isAdmin" class="link" :to="{ name: 'NovoDownload'}">
              &nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small dark color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </template>
                <span>Novo Download</span>
              </v-tooltip>
            </router-link>
        </v-toolbar>
        <template>
          <v-treeview
            v-model="tree"
            :open="initiallyOpen"
            :items="items"
            activatable
            item-key="name"
            open-on-click>
            <template v-slot:prepend="{ item, open }">
              <template v-if="!item.file">
                  <v-icon>
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                  <v-btn active-class right icon class="trash" v-if="item.folderV && isAdmin" @click="modal = true"><v-icon color="red" @click="download = item.download, action = true">mdi-delete</v-icon></v-btn>
              </template>
              <template v-else>
                  <a target="_blank" class="link" :href="item.download.arquivoURL" download>
                    <v-icon class="hover-icon">{{ files[item.file] }}</v-icon>
                   </a>
                   <v-btn right icon class="trash" v-if="isAdmin" @click="modal = true"><v-icon color="blue" @click="download = item.download, action = false">mdi-delete</v-icon></v-btn>
                   <v-btn right icon class="date" v-if="item.download.criadoEm">{{ new Date(item.download.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}</v-btn>
              </template>
            </template>
          </v-treeview>
    </template>
      <v-dialog v-model="modal" max-width="500px">
        <v-card>
            <v-card-title>
              <v-card-text class="font-title">Deseja Continuar com a Exclusão do Documento?</v-card-text>
            </v-card-title>
            <v-card-actions class="justify-end">
                <v-btn color="primary" text @click="modal = false">
                    Fechar
                </v-btn>
                <v-btn color="success" text @click="modal = false, delDownloads()">
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-card>
</template>

<script>
export default {
  computed: {
    getDownloads () {
      return this.$store.state.downloads
    },
    getProdutos () {
      return this.$store.state.produtos
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    items () {
      const nItems = []
      this.getProdutos.forEach(P => {
        let NP = P
        if (P === 'Catálogo de Drenagem e Esgoto') {
          NP = 'CatDrenEsg'
        } else if (P === 'Catálogo de Água') {
          NP = 'CatAgua'
        }
        const FiltP = this.getDownloads.filter(D => {
          return D.produto === NP
        })
        if (FiltP.length > 0) {
          const NProduto = []
          const versoes = []

          FiltP.forEach(FP => {
            if (!versoes.includes(FP.versaoProduto)) {
              const Exes = []
              const KK = FiltP.filter(T => {
                return T.versaoProduto === FP.versaoProduto
              })
              KK.forEach(UU => {
                Exes.push({
                  name: UU.nomeArq,
                  download: UU,
                  file: 'exe'
                })
              })

              NProduto.push({
                name: FP.versaoProduto,
                download: FP,
                children: Exes,
                folderV: true
              })
              versoes.push(FP.versaoProduto)
            }
          })
          this.initiallyOpen.push(P)
          nItems.push({
            name: P,
            children: NProduto
          })
        }
      })
      return nItems
    }
  },
  data () {
    return {
      modal: false,
      action: null,
      download: null,
      initiallyOpen: [],
      files: {
        exes: 'mdi-cube-outline',
        exe: 'mdi-file-download'
      },
      tree: []
    }
  },
  async created () {
    if (!this.$store.state.downloads.length > 0) {
      await this.$store.dispatch('getAllDownloads')
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    delDownloads () {
      if (this.action === true) {
        this.$store.dispatch('delDownloads', this.download).then(() => { this.action = null })
      } else if (this.action === false) {
        this.$store.dispatch('delDownload', this.download).then(() => { this.action = null })
      }
    }
  }
}
</script>

<style>
.trash {
    position: absolute !important;
    right: 10px;
    top: 6px;
}
.date{
  position: absolute !important;
  right: 120px;
  top: 6px;
}
.font-title{
  font-size: 16px !important;
}
.hover-icon:hover {
  color: rgb(53, 8, 255)
}
</style>

<template>
    <v-card>
        <v-toolbar flat>
            <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
            <v-toolbar-title>
                &nbsp;
                CRM
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark color="black" v-bind="attrs" v-on="on" @click="createProposalExcel()">
                      <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                  </template>
                  <span>Upload Json</span>
                </v-tooltip>
                &nbsp;
                <v-tooltip bottom v-if="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark color="green" v-bind="attrs" v-on="on">
                      <!-- <v-icon>mdi-microsoft-excel</v-icon> -->
                      <label for="excel" class="cursor-pointer"><v-icon>mdi-microsoft-excel</v-icon></label>
                      <input type="file" class="d-none" id="excel" ref="excel" label="excel file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="addfile" outlined />
                    </v-btn>
                  </template>
                  <span>Upload Excel</span>
                </v-tooltip>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark color="green" v-bind="attrs" v-on="on" @click="modalExpExcel = true">
                      <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                  </template>
                  <span>Exportar Planilha</span>
                </v-tooltip>
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark color="primary" v-bind="attrs" v-on="on" @click="isUpdate = false, modalProposal = true">
                      <v-icon>mdi-card-account-phone</v-icon>
                    </v-btn>
                  </template>
                  <span>Novo Cliente</span>
                </v-tooltip>
        </v-toolbar>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="Buscar" single-line hide-details outlined></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="getProposals" sort-by="criadoEm" :items-per-page="20" :sort-desc="true" :search="search" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há contatos no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-slot:[`item.docId`]="{ index }">
            {{ getProposals.length - index }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
          {{ item.email }}
        </template>
        <template v-slot:[`item.content`]="{ item }">
          {{ item.content }}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          {{ item.phone }}
        </template>
        <template v-slot:[`item.nextContact`]="{ item }">
          {{ item.nextContact }}
        </template>
        <template v-slot:[`item.Type`]="{ item }">
          {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.buttons`]="{ item }">
          <v-btn text small>
            <v-icon color="primary" @click="openProposals(item, 0)">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn text small @click="openProposals(item, 1)">
            <v-icon color="purple">mdi-dots-horizontal-circle-outline</v-icon>
          </v-btn>
          <v-btn text small @click="openProposals(item, 2)">
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
    </v-data-table>
    <v-dialog v-model="modalProposalList" max-width="1400px">
      <v-card v-if="currentProposal !== null">
        <v-data-iterator :items="currentProposal.followUp" :items-per-page.sync="itemsPerPage" :page.sync="page" :search="searchProposal"
        :sort-by="sortBy" :sort-desc="sortDesc" hide-default-footer>
          <template v-slot:header>
            <v-toolbar dark color="blue darken-3" class="mb-1">
              <v-text-field v-model="searchProposal" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Procurar Proposta"></v-text-field>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                &nbsp;
                <v-select v-model="sortBy" flat solo-inverted hide-details :items="uKeys" prepend-inner-icon="mdi-magnify" label="ordenar por"></v-select>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn large depressed dark class="link" color="cyan" v-bind="attrs" v-on="on" @click="modalFollowUp = true">
                      <v-icon size="28">mdi-notebook-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Novo Contato</span>
                </v-tooltip>
                &nbsp;
                <v-btn-toggle v-model="sortDesc" mandatory>
                  <v-btn large depressed color="blue" :value="false">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn large depressed color="blue" :value="true">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:default="props">
            <v-row class="px-5 pt-2">
              <v-col v-for="item in props.items" :key="item.criadoEm" cols="12" sm="6" md="4" lg="6">
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    <v-btn text small @click="delFollowUp(item)">
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                    &nbsp;
                    {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
                    <v-spacer></v-spacer>
                    <v-select v-if="isMaster || isSales" @change="updateFollowUp(0, item)" :items="niveis" label="Qualificação" v-model="item.qualification" style="max-width: 200px;"></v-select>
                    <v-spacer></v-spacer>
                    <v-hover v-slot="{ hover }" class="cursor-pointer" v-if="item.isCalled === true">
                      <v-icon color="red" v-if="hover" @click="updateSendContact(item, false)">mdi-phone-cancel</v-icon>
                      <v-icon color="green" v-else @click="updateSendContact(item, false)">mdi-phone-check</v-icon>
                    </v-hover>
                    <v-hover v-slot="{ hover }" class="cursor-pointer" v-else>
                      <v-icon color="green" v-if="hover" @click="updateSendContact(item, true)">mdi-phone-check</v-icon>
                      <v-icon color="red" v-else @click="updateSendContact(item, true)">mdi-phone-cancel</v-icon>
                    </v-hover>
                    <v-spacer></v-spacer>
                    <v-switch v-if="isMaster || isSales" @change="updateFollowUp(1, item)" v-model="item.closure" class="ma-0" color="green darken-2" hide-details></v-switch>
                    </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                      <v-list-item-content :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        {{ nKeys[key] }}:
                      </v-list-item-content>
                      <v-list-item-content v-if="key === 'closure'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        <v-chip x-small v-if="item[key] === false" color="red"></v-chip>
                        <v-chip x-small v-else color="green"></v-chip>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="key === 'qualification'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        <v-icon large color="cyan darken-2" v-if="item[key] === 'Frio'">mdi-snowflake</v-icon>
                        <v-icon large color="gray darken-2" v-if="item[key] === 'Morno'">mdi-sun-snowflake-variant</v-icon>
                        <v-icon large color="orange darken-2" v-if="item[key] === 'Quente'">mdi-fire</v-icon>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="key === 'category'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        {{ allReplace(item[key].toString(), { ',': ' / '} ) }}
                      </v-list-item-content>
                      <v-list-item-content v-else-if="key === 'items'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        {{ allReplace(item[key].toString(), { ',': ' / '} ) }}
                      </v-list-item-content>
                      <v-list-item-content v-else-if="key === 'nextContact'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                          {{ new Date(item[key]).toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}
                      </v-list-item-content>
                      <v-list-item-content v-else-if="key === 'reason'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        <span v-if="item[key] !== ''">{{ item[key]}}</span>
                        <v-chip v-else x-small color="gray"></v-chip>
                      </v-list-item-content>
                      <v-list-item-content v-else class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        {{ item[key] }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:footer>
            <v-row class="mx-5 pb-5" align="center" justify="center">
              <span class="grey--text">Ativações por Página</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(number, index) in [2, 4, 8]" :key="index" @click="updateItemsPerPage(number)">
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <span class="mr-4 grey--text">
                Page {{ page }} of {{ numberOfPages }}
              </span>
              <v-btn fab dark color="blue darken-3" class="mr-1" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab dark color="blue darken-3" class="ml-1" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template v-slot:no-data>
             <v-card-title class="justify-center">Não há contatos no momento.</v-card-title>
          </template>
        </v-data-iterator>
      </v-card>
      </v-dialog>
        <v-dialog v-model="modalProposal" max-width="70%">
          <v-card>
            <v-card-title>
              Informações do Cliente&nbsp;|&nbsp; {{ new Date(proposal.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
              <v-spacer></v-spacer>
            <v-card-actions>
                <v-btn v-if="!isUpdate" color="primary" outlined @click="createProposal()">
                      Adicionar
                </v-btn>
                <v-btn v-else color="primary" outlined @click="updateProposal()">
                      Atualizar
                </v-btn>
            </v-card-actions>
            </v-card-title>
            <v-card-text>
              <v-row class="justify-start">
                <v-spacer></v-spacer>
                  <v-radio-group v-model="proposal.client" row>
                    <v-radio label="Pessoa Física" value="PF"></v-radio>
                    <v-radio label="Pessoa Jurídica" value="PJ"></v-radio>
                  </v-radio-group>
                  <v-spacer></v-spacer>
              </v-row>
              <v-row class="position-absolute" style="float: right; right: 5%;">
                  <v-radio-group v-model="proposal.type">
                    <v-radio label="Prospecção" value="Prospecção"></v-radio>
                    <v-radio label="Paving" value="Paving"></v-radio>
                    <v-radio label="Indicação" value="Indicação"></v-radio>
                    <v-radio label="Site" value="Site"></v-radio>
                    <v-radio label="Rede Sociais" value="Rede Sociais"></v-radio>
                    <v-radio label="Renovação" value="Renovação"></v-radio>
                  </v-radio-group>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="3">
                    <v-text-field type="text" outlined v-model="proposal.name" label="Nome" required></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field type="email" outlined v-model="proposal.email" label="Endereço de Email" required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="2">
                    <v-text-field v-mask="['(##) #####-####']" type="text" outlined v-model="proposal.phone" label="Telefone" required></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field v-if="proposal.client === 'PF'" v-mask="'###.###.###-##'" type="text" outlined v-model="proposal.info.cpf" label="CPF" required></v-text-field>
                    <v-text-field v-else v-mask="'##.###.###/####-##'" type="text" outlined v-model="proposal.info.cnpj" label="CNPJ" required></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field type="text" outlined v-model="proposal.infoType" label="Mais Informações" required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center" v-if="proposal.client === 'PJ'">
                <v-col cols="3">
                    <v-text-field type="text" outlined v-model="proposal.info.contact" label="Contato" required></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field type="text" outlined v-model="proposal.info.socialReason" label="Razão Social" required></v-text-field>
                </v-col>
              </v-row>
                <v-row class="justify-center">
                    <v-col cols="2">
                            <v-text-field v-mask="'#####-###'" type="text" outlined v-model="proposalCEP" label="CEP" :rules="rules" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-select outlined :items="estados" v-model="proposal.info.address.state" label="Estado"></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="text" outlined v-model="proposal.info.address.city" label="Cidade"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="justify-center">
                    <v-col cols="3">
                        <v-text-field type="text" outlined v-model="proposal.info.address.publicPlace" label="Logradouro"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field type="text" outlined v-model="proposal.info.address.district" label="Bairro"></v-text-field>
                    </v-col>
              </v-row>
              <v-row class="justify-center">
                  <v-col cols="6">
                      <v-textarea type="text" outlined v-model="proposal.info.additionalData" label="Informações Adicionais"></v-textarea>
                  </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="modalFollowUp" max-width="70%">
          <v-card>
            <v-card-title>Acompanhamento
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn color="primary" outlined @click="addFollowUp()">
                      Adicionar
                </v-btn>
            </v-card-actions>
            </v-card-title>
            <v-card-text class="pt-5">
                    <v-row class="justify-center">
                        <v-col cols="3">
                            <v-text-field type="text" outlined v-model="followUp.name" label="Primeiro Contato" required></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-select outlined :items="options" v-model="followUp.interest" label="Demonstrou Interesse?"></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select outlined :items="options" v-model="followUp.material" label="Enviou Material"></v-select>
                        </v-col>
                        <v-col cols="3" v-if="followUp.interest === 'Não' || followUp.material === 'Não'">
                            <v-text-field type="text" outlined v-model="followUp.reason" label="Motivo"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center">
                        <v-col cols="3">
                            <v-combobox v-model="followUp.category" :items="categorias" label="Categorias" multiple outlined persistent-hint></v-combobox>
                        </v-col>
                        <v-col cols="2">
                            <v-combobox v-model="followUp.items" :items="itemsProducts" label="Itens" multiple outlined persistent-hint></v-combobox>
                        </v-col>
                        <v-col cols="2">
                            <v-select outlined :items="niveis" v-model="followUp.qualification" label="Qualificação"></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-dialog ref="dialog" v-model="modal" :return-value.sync="followUp.nextContact" persistent width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="followUp.nextContact" label="Próximo Contato" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="followUp.nextContact" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">
                                  Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(followUp.nextContact)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="modalDelProposal" max-width="400px">
      <v-card>
          <v-card-title class="text-h6">
              Excluir Cliente e suas CRMs?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="delProposals()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
        </v-dialog>
        <v-dialog v-model="modalExpExcel" max-width="500px">
          <v-card>
              <v-card-title class="text-h6">
                  Escolha um produto para o filtro de exportação:
              </v-card-title>
              <v-card-text class="pb-0 pt-4">
                <v-combobox v-model="selectProductExp" :items="itemsProducts" label="Itens" outlined persistent-hint></v-combobox>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary darken-1" text @click="exportExcel()">
                      Confirmar
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </v-card>
    </template>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx'
export default {
  data () {
    return {
      modalFollowUp: false,
      modalProposal: false,
      modalProposalList: false,
      modalDelProposal: false,
      modalExpExcel: false,
      modal: false,
      isUpdate: false,
      options: ['Sim', 'Não'],
      niveis: ['Quente', 'Morno', 'Frio'],
      categorias: ['Produto', 'Solução', 'Serviço'],
      itemsProducts: ['CADInfra', 'C3DFarm', 'Projetos Multiciplinares', 'LZ BIMInfra', 'Suporte Técnico', 'Vídeo Aulas', 'Template', 'Projetos Infraestrutura'],
      selectProductExp: null,
      followUp: {
        docRef: null,
        creatorEmail: null,
        qualification: '', // Nivel de calor
        category: [],
        interest: 'Não',
        material: 'Não',
        name: '',
        nextContact: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        reason: '',
        items: '',
        criadoEm: null,
        closure: false,
        fechadoEm: null
      },
      rules: [
        value => !!value || 'CEP Inválido.',
        value => (value && value.length >= 8 && value.length <= 9) || 'Refazer CEP'
      ],
      estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      proposal: {
        client: 'PF',
        email: '',
        name: '',
        phone: '',
        type: 'Prospecção',
        infoType: '',
        criadoEm: null,
        followUp: [],
        info: {
          additionalData: '',
          address: {
            cep: '',
            city: '',
            district: '',
            publicPlace: '',
            state: ''
          }
        }
      },
      search: '',
      headers: [{
        text: '',
        value: 'docId',
        align: 'center'
      },
      {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Produtos',
        sortable: true,
        value: 'content'
      },
      {
        text: 'Email',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Telefone',
        align: 'center',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Próximo Contato',
        align: 'center',
        sortable: true,
        value: 'nextContact'
      },
      {
        text: 'Tipo',
        align: 'center',
        sortable: true,
        value: 'type'
      },
      {
        text: '',
        align: 'center',
        sortable: false,
        value: 'buttons'
      }
      ],
      itemsPerPageArray: [2, 4, 6],
      searchProposal: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 2,
      sortBy: 'Criado Em',
      keys: [
        'qualification',
        'closure',
        'name',
        'creatorEmail',
        'criadoEm',
        'interest',
        'material',
        'nextContact',
        'category',
        'items',
        'reason'
      ],
      nKeys: {
        closure: 'Proposta Fechada',
        qualification: 'Qualificação',
        name: 'Nome',
        creatorEmail: 'Email do Criador',
        criadoEm: 'Criado Em',
        interest: 'Interesse',
        material: 'Material',
        nextContact: 'Próximo Contato',
        category: 'Categoria',
        items: 'Itens',
        reason: 'Razão'
      },
      uKeys: [
        'Criado Em',
        'Proposta Fechada',
        'Qualificação',
        'Email do Criador',
        'Nome',
        'Interesse',
        'Material',
        'Próximo Contato',
        'Categoria',
        'Itens',
        'Razão'
      ],
      tab: 0,
      currentProposal: null,
      upNextCreator: null,
      ArrayProposals: []
    }
  },
  methods: {
    async updateSendContact (item, value) {
      const filt = this.getFollowUp.filter(F => {
        return F.criadoEm === item.criadoEm
      })
      if (filt.length > 0) {
        item.isCalled = value
        if (value === false) {
          filt[0].chamadoEm = null
          filt[0].isCalled = value
        } else {
          filt[0].chamadoEm = new Date().getTime()
          filt[0].isCalled = value
        }

        await this.$store.dispatch('updateFollowUp', Object.assign({}, filt[0])).then(() => {
          this.modalProposalList = false
          this.openProposals(this.currentProposal, 1)
        })
      }
    },
    async transferFollowUp () {
      await this.$store.dispatch('transferFollowUp', this.getProposals)
    },
    addfile (event) {
      this.clearProposal()
      this.ArrayProposals = []
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(event.target.files[0])
      fileReader.onload = async (e) => {
        var data = new Uint8Array(fileReader.result)
        var arr = []
        for (var i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]) }
        var bstr = arr.join('')
        var workbook = XLSX.read(bstr, { type: 'binary' })
        var firstSheetName = workbook.SheetNames[0]
        var worksheet = workbook.Sheets[firstSheetName]
        const ArrayJsonExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true })

        ArrayJsonExcel.forEach(async OBJ => {
          if (OBJ.Email && OBJ.Telefone && OBJ.CNPJ) {
            const QTD = await this.getProposals.filter(P => {
              return P.email === OBJ.Email
            })
            if (await QTD.length <= 0) {
              var proposal = {
                client: 'PJ',
                email: await OBJ.Email,
                name: '---',
                phone: await OBJ.Telefone.split('/')[0].trim(),
                type: 'Prospecção',
                infoType: 'leads',
                criadoEm: 1675255972919,
                info: {
                  cnpj: OBJ.CNPJ,
                  contact: '',
                  socialReason: '',
                  additionalData: '',
                  address: {
                    cep: '',
                    city: '',
                    district: '',
                    publicPlace: '',
                    state: ''
                  }
                }
              }

              if (await OBJ.Nome) {
                proposal.name = await OBJ.Nome
              }
              if (await OBJ.CEP) {
                proposal.info.address.cep = await OBJ.CEP
              }
              this.ArrayProposals.push(proposal)
            }
          }
        })
      }
    },
    allReplace (str, obj) {
      for (const x in obj) {
        str = str.replace(new RegExp(x, 'g'), obj[x])
      }
      return str
    },
    async clearProposal () {
      this.proposal = {
        client: 'PF',
        email: '',
        name: '',
        phone: '',
        criadoEm: null,
        followUp: [],
        info: {
          additionalData: '',
          address: {
            cep: '',
            city: '',
            district: '',
            publicPlace: '',
            state: ''
          }
        }
      }
      this.currentProposal = null
    },
    async openNewModalProposal () {
      this.clearProposal()
      this.isUpdate = false
      this.modalProposal = true
    },
    async openProposals (item, index) {
      const followUp = this.getFollowUp.filter(F => {
        return F.docRef === item.docId
      })
      switch (index) {
        case 0:
          this.isUpdate = true
          this.proposal = Object.assign({}, item)
          this.modalProposal = true
          break
        case 1:
          this.currentProposal = item
          this.currentProposal.followUp = followUp
          this.modalProposalList = true
          break
        case 2:
          this.currentProposal = item
          this.modalDelProposal = true
          break
        default:
          break
      }
    },
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    async createProposal () {
      if (this.proposal.name !== '' && this.proposal.email !== '' && this.proposal.phone !== '') {
        this.proposal.criadoEm = new Date().getTime()
        await this.$store.dispatch('createProposal', Object.assign({}, this.proposal)).then(() => {
          this.modalProposal = false
          this.clearProposal()
        })
      }
    },
    async createProposalExcel () {
      if (this.ArrayProposals.length > 0) {
        await this.$store.dispatch('createProposalLote', this.ArrayProposals)
      }
    },
    async updateProposal () {
      this.proposal.criadoEm = new Date().getTime()
      await this.$store.dispatch('updateProposal', Object.assign({}, this.proposal)).then(() => {
        this.modalProposal = false
        this.isUpdate = false
        this.clearProposal()
      })
    },
    async updateFollowUp (index, item) {
      const filt = this.getFollowUp.filter(F => {
        return F.criadoEm === item.criadoEm
      })
      if (filt.length > 0) {
        if (index === 1) {
          filt[0].fechadoEm = new Date().getTime()
        }
        await this.$store.dispatch('updateFollowUp', Object.assign({}, filt[0]))
      } else {
        if (index === 1) {
          item.fechadoEm = new Date().getTime()
        }
        await this.$store.dispatch('updateFollowUp', Object.assign({}, item))
      }
    },
    async addFollowUp () {
      if (this.followUp.qualification !== '' && this.followUp.category.length > 0 && this.followUp.name !== '' && this.followUp.items !== '') {
        this.followUp.criadoEm = new Date().getTime()
        this.followUp.nextContact = new Date(this.followUp.nextContact).setUTCHours(3)
        this.followUp.creatorEmail = this.email
        this.followUp.docRef = this.currentProposal.docId
        this.followUp.fechadoEm = null
        await this.$store.dispatch('createFollowUp', Object.assign({}, this.followUp)).then(async () => {
          this.currentProposal.content = await this.getProducts(this.currentProposal)
          this.followUp = {
            docRef: null,
            creatorEmail: null,
            qualification: '', // Nivel de calor
            category: [],
            interest: 'Não',
            material: 'Não',
            name: '',
            nextContact: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            reason: '',
            items: '',
            criadoEm: null,
            closure: false,
            fechadoEm: null
          }
          this.modalFollowUp = false
          this.modalProposalList = false
          this.openProposals(this.currentProposal, 1)
        })
      } else {
        alert('Preencher todos os campos!')
      }
    },
    async delProposals () {
      var docId = this.currentProposal.docId
      await this.$store.dispatch('delFollowUps', docId).then(async () => {
        await this.$store.dispatch('delProposals', this.currentProposal.docId).then(() => {
          this.modalDelProposal = false
          this.clearProposal()
        })
      })
    },
    async delFollowUp (item) {
      await this.$store.dispatch('delFollowUp', item.docId).then(() => {
        this.modalProposalList = false
        this.openProposals(this.currentProposal, 1)
      })
    },
    exportExcel () {
      const data = []
      var filtroFU = this.getFollowUp.filter(L => {
        return L.items.includes(this.selectProductExp)
      })
      if (filtroFU.length > 0) {
        filtroFU.forEach(F => {
          var filtroCliente = this.getProposals.filter(P => {
            return F.docRef === P.docId
          })

          if (filtroCliente.length > 0) {
            var filtroData = data.filter(D => {
              return D.Email === filtroCliente[0].email
            })
            if (!filtroData.length > 0) {
              data.push({
                Nome: filtroCliente[0].name,
                Email: filtroCliente[0].email,
                Telefone: filtroCliente[0].phone,
                Produto: this.selectProductExp
              })
            }
          }
        })
      }

      if (data.length > 0) {
        import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ json2excel }) => {
          json2excel({
            data,
            name: 'Listagem de Produto',
            formateDate: 'dd/mm/yyyy'
          })
        }).catch((e) => {
          console.log(e)
        })
      } else {
        console.log('Nenhum resultado encontrado.')
      }
    },
    getNextContact (item) {
      var content = '-------------'

      const filt = this.getFollowUp.filter(F => {
        return F.docRef === item.docId
      })
      if (filt.length > 0) {
        var dateContact = filt.slice().sort(this.compare)[filt.length - 1].nextContact
        content = new Date(dateContact).toLocaleString('pt-BR', { hour12: false }).split(' ')[0]
      }
      return content
    },
    getProducts (item) {
      var content = '-------------'

      const filt = this.getFollowUp.filter(F => {
        return F.docRef === item.docId
      })
      if (filt.length > 0) {
        content = ''
        filt.forEach(F => {
          try {
            if (F.items.length > 0) {
              F.items.forEach(A => {
                if (!content.includes(A)) {
                  content += A + ' | '
                }
              })
            }
            content = content.slice(0, content.length - 2)
          } catch {

          }
        })
      }

      return content
    },
    compare (proposal1, proposal2) {
      // Use toUpperCase() to ignore character casing
      const bandA = proposal1.criadoEm
      const bandB = proposal2.criadoEm

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    }
  },
  computed: {
    email () {
      return this.$store.state.profile.email
    },
    isSales () {
      return this.$store.state.profile.rules.isSales
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    numberOfPages () {
      if (this.currentProposal !== null) {
        return Math.ceil(this.currentProposal.followUp.length / this.itemsPerPage)
      } else {
        return 0
      }
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'criadoEm')
    },
    proposalCEP: {
      get () {
        return this.proposal.info.address.cep
      },
      set (value) {
        if (value.toString().includes('-')) {
          value = value.replace('-', '')
        }
        if (value.toString().length === 8) {
          axios.get(`https://viacep.com.br/ws/${value}/json/`)
            .then(response => {
              this.proposal.info.address.cep = response.data.cep
              this.proposal.info.address.state = response.data.uf
              this.proposal.info.address.city = response.data.localidade
              this.proposal.info.address.publicPlace = response.data.logradouro
              this.proposal.info.address.district = response.data.bairro
            }).catch(error => console.log(error))
        }
      }
    },
    getProposals () {
      return this.$store.state.proposals
      /* if (this.search === '') {
      } else {
        const array = this.$store.state.proposals.filter(U => {
          return this.getProducts(U).toLowerCase().match(this.search.toLowerCase())
        })
        console.log(array)
        return array
      } */
    },
    getFollowUp () {
      return this.$store.state.followUp
    }
  },
  async created () {
    if (this.isMaster || this.isSales) {
      if (!this.$store.state.followUp.length > 0) {
        await this.$store.dispatch('getAllFollowUp')
      }
      if (!this.$store.state.proposals.length > 0) {
        await this.$store.dispatch('getAllProposals')
      }
    }
  }
}
</script>

    <style>

    </style>

<template>
  <Radar :chart-data="chartData" :chart-options="chartOptions" />
</template>
<script>
import { Radar } from 'vue-chartjs/legacy'

export default {
  components: { Radar },
  methods: {
    getCategorys (category) {
      return this.$store.state.masterHome.followUp.values.filter(T => {
        return T.category.includes(category)
      })
    }
  },
  computed: {
    chartData () {
      return {
        labels: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        datasets: [
          {
            label: 'Produto',
            backgroundColor: 'rgba(96, 2, 251, 0.4)',
            borderColor: '#6002fb',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getCategorys('Produto').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Solução',
            backgroundColor: 'rgba(107, 247, 239, 0.4)',
            borderColor: '#6bf7ef',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getCategorys('Solução').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Serviço',
            backgroundColor: 'rgba(227, 120, 198, 0.7)',
            borderColor: '#e378c6',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getCategorys('Serviço').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          }
        ]
      }
    },
    chartOptions () {
      return { responsive: true, maintainAspectRatio: false }
    }
  }
}
</script>
<style>
</style>

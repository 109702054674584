<template>
<v-card style="height: 100%;">
    <v-toolbar flat>
      <v-app-bar-nav-icon @click="changeNav()"></v-app-bar-nav-icon>
      <v-toolbar-title>
          &nbsp;
          Licenças
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row v-if="isAdmin && tab !== 0">
        <v-col>
          <v-combobox class="pt-7" style="max-width: 200px;" v-model="options" :items="boxes" label="Visualizar" multiple outlined dense></v-combobox>
        </v-col>
      </v-row>
      <v-tooltip bottom v-if="!isSales && isAdmin && tab === 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="pink" @click="tab = 0" v-bind="attrs" v-on="on">
            <v-icon>mdi-key</v-icon>
          </v-btn>
        </template>
        <span>Chaves de produto</span>
      </v-tooltip>
      &nbsp;
      <v-tooltip bottom v-if="!isSales && isAdmin && tab === 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="purple" @click="tab = 1" v-bind="attrs" v-on="on">
            <v-icon>mdi-map-marker-multiple-outline</v-icon>
          </v-btn>
        </template>
        <span>Mapa de Licenças</span>
      </v-tooltip>
      &nbsp;
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark class="link" color="#ffd438" @click="openModal.addPermission = true, generateProductKey()" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-key-outline</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Licença</span>
      </v-tooltip>
      &nbsp;
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark color="green" v-bind="attrs" v-on="on" @click="openModal.modalExpExcel = true">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Exportar Excel</span>
      </v-tooltip>
    </v-toolbar>
    <v-card v-if="tab === 0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" label="Buscar" single-line hide-details outlined></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="getLicenses" :search="search" :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'Quantidade'
      }">
        <template slot="no-data">
            <small>
                <v-icon>mdi-check</v-icon> &nbsp; Não há Licenças no momento.
            </small>
        </template>
        <template slot="no-results">
            <small>
                <v-icon>mdi-information-outline</v-icon> &nbsp; Nenhum resultado encontrado.
            </small>
        </template>
        <template v-slot:[`item.perpetual`]="{ item }">
           <v-chip v-if="item.perpetual" class="ma-2" text-color="white" color="#333333">Perpétua</v-chip>
           <v-chip v-else class="ma-2" text-color="white" color="orange">Temporária</v-chip>
        </template>
        <template v-slot:[`item.licType`]="{ item }"  >
            <v-chip v-if="item.licType === 'CADInfra'" class="ma-2" text-color="white" color="primary">{{ changeFrontName(item.licType) }}</v-chip>
            <v-chip v-else-if="item.licType === 'DRENESGINFRA'" class="ma-2" text-color="white" color="#1597BB">{{ changeFrontName(item.licType) }}</v-chip>
            <v-chip v-else-if="item.licType === 'C3DFarm'" class="ma-2" text-color="white" color="#536d47">{{ changeFrontName(item.licType) }}</v-chip>
            <v-chip v-else-if="item.licType === 'DRENESG'" class="ma-2" text-color="white" color="#867f38">{{ changeFrontName(item.licType) }}</v-chip>
            <v-chip v-else-if="item.licType === 'CortexForNavis'" class="ma-2" text-color="white" color="#808080">{{ changeFrontName(item.licType) }}</v-chip>
            <v-chip v-else class="ma-2" text-color="white" color="#4d4d4d">{{ changeFrontName(item.licType) }}</v-chip>
        </template>
        <template v-slot:[`item.criadoEm`]="{ item }">
        {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}
        </template>
        <template v-slot:[`item.expires`]="{ item }">
        <span v-if="!item.perpetual">{{ new Date(item.expires).toLocaleString('pt-BR', { hour12: false }) }}</span>
        <span v-else>--------------------</span>
        </template>
        <template v-slot:[`item.statusLic`]="{ item }">
            <v-chip v-if="item.statusLic" class="ma-2" text-color="white" color="green">Ativa</v-chip>
            <v-chip v-else class="ma-2" text-color="white" color="red">Desativada</v-chip>
        </template>
        <template v-slot:[`item.situation`]="{ item }">
            <v-chip v-if="item.situation === 0 || !item.situation" class="ma-2" text-color="white" color="yellow darken-1">Nova Licença</v-chip>
            <v-chip v-if="item.situation === 1" class="ma-2" text-color="white" color="yellow darken-2">Em Negociação</v-chip>
            <v-chip v-if="item.situation === 2" class="ma-2" text-color="white" color="green">Renovação</v-chip>
        </template>
        <template v-slot:[`item.activates`]="{ item }">
          <v-hover v-slot="{ hover }">
            <v-avatar v-if="hover" class="cursor-pointer" size="25" color="yellow" @click="openPermissionDialog('dev', item)">{{ item.activates.length }}</v-avatar>
            <v-avatar v-else size="25" color="blue">{{ item.activates.length }}</v-avatar>
          </v-hover>
        </template>
        <template v-slot:[`item.profileId`]="{ item }" v-if="isAdmin">
          <v-hover v-slot="{ hover }">
            <v-icon v-if="hover" color="green" @click="openModal.modalSendEmail = true, selLic = item">mdi-email-multiple-outline</v-icon>
            <v-icon v-else color="blue">mdi-email-multiple-outline</v-icon>
          </v-hover>
        </template>
      </v-data-table>
      <v-dialog v-model="openModal.addPermission" max-width="500px">
      <v-card>
          <v-card-title class="white--text blue darken-4" color="#ffd438">
            Permissões de Licenciamento
            <v-spacer></v-spacer>
            <v-btn color="green" class="text--primary" fab small @click="setPermission()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
                <v-col>
                  <v-checkbox v-if="!menus.perp && !menus.renovation" v-model="menus.negociation" label="Negociação" color="yellow darken-3" hide-details></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox v-if="!menus.perp && !menus.negociation" v-model="menus.renovation" label="Renovação" color="purple darken-3" hide-details></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox v-if="!menus.negociation && !menus.renovation" v-model="menus.perp" label="Perpétua" color="orange darken-3" hide-details></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu :disabled="menus.perp" v-model="menus.menuModal" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="addPermission.date" label="Expiração" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addPermission.date"
                    @input="menus.menuModal = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8" class="mt-4">
                <v-combobox v-model="newPermission" :items="getUsers" outline dense hide-selected>
                  <template v-slot:item="{ item }">
                    <span class="text-overline grey--text text--darken-3 mx-2">
                      {{ item.nomeCompleto + ' (' + item.email +')' }}
                     </span>
                   </template>
                   <template v-slot:selection="{ item }">
                     <span class="text-overline grey--text text--darken-3 mx-2">
                       {{ item.nomeCompleto }}
                     </span>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <span>{{ addPermission.serialKey }}</span>
                </v-col>
                <v-col cols="3">
                    <v-combobox v-model="addPermission.licType" :items="['CADInfra™ (Completo)', 'CADInfra™ (Básico)', 'C3DFarm™', 'Drenagem e Esgoto', 'CortexForNavis™']" label="Produto" dense hide-selected></v-combobox>
                </v-col>
                <v-col cols="3">
                    <v-text-field v-model="addPermission.stations" :rules="rules" type="number" label="Ativações" dense hide-selected></v-text-field>
                </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-virtual-scroll :items="getUsers" :item-height="50" height="300">
            <template v-slot:default="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar color="white" class="white--text" size="56"><img :src="item.photoURL" alt="User"></v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ item.nomeCompleto }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="hasPermission(item.profileId)">
                  <v-speed-dial class="bottom-0"
                     v-model="item.fab"
                     :top="false"
                     :bottom="true"
                     :right="true"
                     :left="false"
                     direction="left"
                     :open-on-hover="true"
                     :transition="transition">
                    <template v-slot:activator>
                      <v-btn fab dark small color="blue darken-2" v-model="item.fab" >
                        <v-icon size="22" v-if="item.fab">
                          mdi-close
                        </v-icon>
                        <v-icon size="22" v-else>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-btn @click="openPermissionDialog('new', item)" fab dark small color="green">
                      <v-icon size="22">mdi-dots-horizontal-circle-outline</v-icon>
                    </v-btn>
                    <v-btn @click="openPermissionDialog('men', item)" fab dark small color="pink">
                      <v-icon size="22">mdi-message-text-lock-outline</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
      </v-card>
      </v-dialog>
      <v-dialog v-model="nPermission" max-width="500px" max-height="500px" @keydown.esc="setPermission()">
      <v-card>
          <v-card-title v-for="(item, i) in upPermission.perms" :key="i">
            <v-tooltip bottom v-if="isAdmin">
                <template v-slot:activator="{ on, attrs }">
                    <v-row>
                        <v-col cols="10">
                            <span v-bind="attrs" v-on="on"><h5>{{ changeFrontName(item.licType) }}</h5></span>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text small @click="delLic(item.serialKey)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <span>{{ item.serialKey }}</span>
            </v-tooltip>
            <v-row>
            <v-col cols="8">
              <v-menu v-model="menus.menuDialog[i]" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="upPermission.dates[i]" label="Modificar permissão" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="upPermission.dates[i]"
                @input="menus.menuDialog[i] = false"
              ></v-date-picker>
            </v-menu>
              </v-col>
              <v-col cols="4">
                    <v-text-field v-model="upPermission.stations[i]" :rules="rules" type="number" label="Ativações" hide-selected></v-text-field>
              </v-col>
            </v-row>
              <v-row style="position: relative; bottom: 20px;margin:0">
                <v-col>
                  <v-checkbox v-if="!menus.renovations[i]" v-model="menus.nLics[i]" label="Nova Licença" color="orange darken-3" hide-details></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox v-if="!menus.nLics[i]" v-model="menus.renovations[i]" label="Renovação" color="purple darken-3" hide-details></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox v-model="menus.pLics[i]" label="Perpétua" color="black darken-3" hide-details></v-checkbox>
                </v-col>
            </v-row>
          </v-card-title>
      </v-card>
      </v-dialog>
      <v-dialog v-model="openModal.devPermission" max-width="1400px">
      <v-card v-if="upPermission.lic">
        <v-data-iterator :items="upPermission.lic.activates" :items-per-page.sync="itemsPerPage" :page.sync="page" :search="searchActivates"
        :sort-by="sortBy" :sort-desc="sortDesc" hide-default-footer>
          <template v-slot:header>
            <v-toolbar dark color="blue darken-3" class="mb-1">
              <v-text-field v-model="searchActivates" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Procurar Ativação"></v-text-field>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                &nbsp;
                <v-select v-model="sortBy" flat solo-inverted hide-details :items="uKeys" prepend-inner-icon="mdi-magnify" label="ordenar por"></v-select>
                <v-spacer></v-spacer>
                <span v-if="isAdmin">Status</span>&nbsp;&nbsp;
                <v-switch  v-if="isSales || isAdmin" v-model="upPermission.lic.statusLic" @change="changeStatusLic()" class="ma-0" color="green darken-2" hide-details></v-switch>
                <v-tooltip bottom v-if="isMaster && upPermission.lic.activates.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openModal.delActivates = true" large depressed dark class="link" color="red" v-bind="attrs" v-on="on">
                      <v-icon size="28">mdi-eraser</v-icon>
                    </v-btn>
                  </template>
                  <span>Apagar Ativações</span>
                </v-tooltip>
                &nbsp;
                <v-tooltip bottom v-if="isAdmin && upPermission.lic.activates.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openModal.disableActivates = true" large depressed dark class="link" color="orange" v-bind="attrs" v-on="on">
                      <v-icon size="28">mdi-broom</v-icon>
                    </v-btn>
                  </template>
                  <span>Desativar Ativações</span>
                </v-tooltip>
                &nbsp;
                <v-btn-toggle v-model="sortDesc" mandatory>
                  <v-btn large depressed color="blue" :value="false">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn large depressed color="blue" :value="true">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:default="props">
            <v-row class="px-5 pt-2">
              <v-col v-for="item in props.items" :key="item.criadoEm" cols="12" sm="6" md="4" lg="3">
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}<v-spacer></v-spacer><v-switch v-if="isAdmin" :input-value="changeSwitch(item.status)" :disabled="item.status === 'desactive'" @change="changeStatusActive(item)" class="ma-0" color="red darken-2" hide-details></v-switch>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                      <v-list-item-content :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        {{ nKeys[key] }}:
                      </v-list-item-content>
                      <v-list-item-content v-if="key !== 'status'" class="align-end" :class="{ 'blue--text': sortBy ===  nKeys[key] }">
                        {{ item[key] }}
                      </v-list-item-content>
                      <v-list-item-content v-else class="align-end">
                        <v-chip x-small v-if="item[key] === 'desactive'" color="red"></v-chip>
                        <v-chip x-small v-else color="green"></v-chip>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:footer v-if="upPermission.lic.activates.length > 0">
            <v-row class="mx-5 pb-5" align="center" justify="center">
              <span class="grey--text">Ativações por Página</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(number, index) in [4, 8, 12]" :key="index" @click="updateItemsPerPage(number)">
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <span class="mr-4 grey--text">
                Page {{ page }} of {{ numberOfPages }}
              </span>
              <v-btn fab dark color="blue darken-3" class="mr-1" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab dark color="blue darken-3" class="ml-1" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template v-slot:no-data>
             <v-card-title class="justify-center">Não há Ativações no momento.</v-card-title>
          </template>
        </v-data-iterator>
      </v-card>
      </v-dialog>
      <v-dialog v-model="openModal.disableActivates" max-width="300px">
      <v-card>
          <v-card-title class="text-h6">
              Limpar ativações?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="setPermission()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>
      <v-dialog v-model="openModal.delActivates" max-width="300px">
      <v-card>
          <v-card-title class="text-h6">
              Excluir ativações?
          </v-card-title>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="setPermission()">
                  Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>
      <v-dialog v-model="openModal.menPermission" max-width="500px">
        <v-card>
        <v-card-title v-for="(item, i) in upPermission.perms" :key="i" v-show="!item.statusLic">
          <v-tooltip right v-if="isAdmin">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><h5>{{ changeFrontName(item.licType) }}</h5></span>
            </template>
            <span>{{ item.serialKey }}</span>
          </v-tooltip>
          <v-card-text class="pt-0">
            <v-text-field label="Mensagem" v-model="item.message" hide-details="auto"></v-text-field>
          </v-card-text>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="resetDialog()">
              Fechar
          </v-btn>
          <v-btn color="success" text @click="setPermission()">
              Atualizar
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openModal.modalExpExcel" max-width="350px">
        <v-card>
          <v-card-text class="pt-5 pb-0">
            <v-select v-model="selPlugin" :items="listPlugins" label="Plugins" outlined></v-select>
          </v-card-text>
            <v-card-actions class="pt-0" v-if="selPlugin">
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="exportExcel()">
                  Exportar
              </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openModal.modalSendEmail" max-width="350px">
        <v-card>
          <v-card-text class="pt-5 pb-0">
            <v-radio-group v-model="radioGroup" row>
            <v-col cols="6">
              <v-radio label="Renovação" value="Renovação"></v-radio>
            </v-col>
            <v-col cols="6">
              <v-radio label="Instalação" value="Instalação"></v-radio>
            </v-col>
            </v-radio-group>
            <v-select v-model="selPlugin" :items="listPlugins" label="Plugins" outlined></v-select>
          </v-card-text>
            <v-card-actions class="pt-0" v-if="selPlugin">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="sendEmail()">
                  Enviar Email
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-card>
    <v-card v-else style="height: 100%;">
    <MglMap :map-style.sync="mapStyle" :attributionControl="false">
      <MglGeolocateControl position="top-right"/> <!-- Minha Localização -->
      <MglMarker v-for="(item, i) in markers" :key="i" :coordinates="item.coords">
          <v-avatar class="markerIcon" slot="marker" size="30">
            <img :src="require(`@/assets/map-logos/${item.icon}`)">
          </v-avatar>
            <v-card elevation="0" slot='popup'>
              <v-card-title class="d-flex justify-center">
                <b>Informações</b>
              </v-card-title>
              <v-card-text>
                <v-row>
                <v-col cols="6">
                <p><b>Serial:</b> {{item.serialKey}}</p>
                <p><b>Licença Criada:</b> {{ new Date(item.criadoEm).toLocaleString('pt-BR', { hour12: false }) }}</p>
                <p v-if="!item.perpetual"><b>Licença Expirada:</b> {{ new Date(item.expires).toLocaleString('pt-BR', { hour12: false }) }}</p>
                <p v-else><b>Licença Expirada:</b> ------------------------</p>
                <p><b>Status de Atividade:</b> {{item.status}}</p>
                <p><b>Plugin:</b> {{ changeFrontName(item.licType) }}</p>
                <p><b>Assentos:</b> {{item.stations}}</p>
                <p><b>Perpetual:</b> {{item.perpetual}}</p>
                <p><b>Status de Licença:</b> {{item.statusLic}}</p>
                </v-col>
                <v-col cols="6">
                <p><b>Cidade:</b> {{item.city}}</p>
                <p><b>Coordenadas:</b> {{item.coords}}</p>
                <p><b>Data de Atividade Criada:</b> {{ new Date(item.criadoAEm).toLocaleString('pt-BR', { hour12: false }) }}</p>
                <p><b>Disco Rígido:</b> {{item.hardDisk}}</p>
                <p><b>Nome da Máquina:</b> {{item.machineName}}</p>
                <p><b>Placa Mãe:</b> {{item.motherBoard}}</p>
                <p><b>Sistema Operacional:</b> {{item.osName}}</p>
                </v-col>
                </v-row>
              </v-card-text>
            </v-card>
      </MglMarker>
    </MglMap>
    </v-card>
</v-card>
</template>

<script>
import {
  MglGeolocateControl,
  MglMarker
} from 'vue-mgl'

import emailjs from 'emailjs-com'

export default {
  components: {
    MglGeolocateControl, MglMarker
  },
  computed: {
    isSales () {
      return this.$store.state.profile.rules.isSales
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    getUsers () {
      if (this.addPermission.user === null) {
        return this.$store.state.usersMembers.slice().sort(this.compare)
      } else {
        var users = []
        this.$store.state.usersMembers.forEach(U => {
          users.push(U)
        })
        this.$store.state.adminMembers.forEach(U => {
          users.push(U)
        })
        return users.filter(U => {
          if (this.addPermission.user.nomeCompleto) {
            return U.nomeCompleto.toLowerCase().match(this.addPermission.user.nomeCompleto.toLowerCase()) || U.email.toLowerCase().match(this.addPermission.user.email.toLowerCase())
          } else {
            return U.nomeCompleto.toLowerCase().match(this.addPermission.user.toLowerCase()) || U.email.toLowerCase().match(this.addPermission.user.toLowerCase())
          }
        }).sort(this.compare)
      }
    },
    getLicenses () {
      if (this.showRenov === true) {
        return this.$store.state.licenses.filter(T => {
          return new Date().getTime() >= (T.expires - 2592000000) && T.expires >= new Date().getTime() && T.perpetual === false
        })
      } else {
        return this.$store.state.licenses
      }
    },
    newPermission: {
      get () {
        return this.addPermission.user
      },
      set (value) {
        this.addPermission.user = value
      }
    },
    numberOfPages () {
      if (this.upPermission.lic !== null) {
        return Math.ceil(this.upPermission.lic.activates.length / this.itemsPerPage)
      } else {
        return 0
      }
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'criadoEm')
    },
    markers () {
      if (this.getLicenses.length > 0) {
        const nMarkers = []
        const filt = this.getLicenses.filter(L => {
          return L.activates.length > 0 && this.options.includes(this.changeFrontName(L.licType))
        })
        filt.forEach(async doc => {
          await doc.activates.forEach(async A => {
            const data = {
              serialKey: doc.serialKey,
              criadoEm: doc.criadoEm,
              expires: doc.expires,
              profileId: doc.profileId,
              licType: this.changeFrontName(doc.licType),
              stations: doc.stations,
              perpetual: doc.perpetual,
              statusLic: doc.statusLic,
              message: doc.message,
              city: A.city,
              // coords: { lat: parseFloat(A.coords.split(',')[0]), lng: parseFloat(A.coords.split(',')[1]) },
              coords: [parseFloat(A.coords.split(',')[1]), parseFloat(A.coords.split(',')[0])],
              criadoAEm: A.criadoEm,
              hardDisk: A.hardDisk,
              machineName: A.machineName,
              motherBoard: A.motherBoard,
              osName: A.osName,
              status: A.status,
              icon: this.getURL(doc.licType)
            }
            nMarkers.push(data)
          })
        })
        return nMarkers
      } else {
        return []
      }
    },
    boxes () {
      const filt = this.getLicenses.filter(L => {
        return L.activates.length > 0
      })
      var arry = []
      filt.forEach(F => {
        if (!arry.includes(this.changeFrontName(F.licType))) {
          arry.push(this.changeFrontName(F.licType))
        }
      })
      return arry
    },
    listPlugins () {
      const nArray = []
      this.getLicenses.forEach(L => {
        if (!nArray.includes(this.changeFrontName(L.licType))) {
          nArray.push(this.changeFrontName(L.licType))
        }
      })
      return nArray
    },
    headers () {
      if (this.isAdmin) {
        return [
          {
            text: 'Produto',
            value: 'licType',
            sortable: true
          },
          {
            text: 'Tipo',
            value: 'perpetual',
            sortable: true,
            align: 'center'
          },
          {
            text: 'Serial',
            value: 'serialKey',
            sortable: false,
            align: 'center'
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Data de Criação',
            value: 'criadoEm',
            align: 'center'
          },
          {
            text: 'Data de Vencimento',
            value: 'expires',
            align: 'center'
          },
          {
            text: 'Status',
            value: 'statusLic',
            align: 'center'
          },
          {
            text: 'Situação',
            value: 'situation',
            align: 'center',
            sortable: true
          },
          {
            text: 'Ativações',
            value: 'activates',
            align: 'center'
          },
          {
            text: 'Envio',
            value: 'profileId',
            align: 'center',
            sortable: false
          }
        ]
      } else {
        return [
          {
            text: 'Produto',
            value: 'licType',
            sortable: true
          },
          {
            text: 'Tipo',
            value: 'perpetual',
            sortable: true,
            align: 'center'
          },
          {
            text: 'Serial',
            value: 'serialKey',
            sortable: false,
            align: 'center'
          },
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Data de Criação',
            value: 'criadoEm',
            align: 'center'
          },
          {
            text: 'Data de Vencimento',
            value: 'expires',
            align: 'center'
          },
          {
            text: 'Status',
            value: 'statusLic',
            align: 'center'
          },
          {
            text: 'Ativações',
            value: 'activates',
            align: 'center'
          }
        ]
      }
    },
    nPermission: {
      get () {
        return this.openModal.newPermission
      },
      set (value) {
        this.setPermission()
        this.openModal.newPermission = value
      }
    }
  },
  methods: {
    async changeNav () {
      this.$store.state.showNav = await !this.$store.state.showNav
    },
    getURL (licType) {
      switch (licType) {
        case 'CADInfra':
          return 'cadinfra-map-icon.png'
        case 'C3DFarm':
          return 'c3dfarm-map-icon.png'
        case 'DRENESG':
          return 'drenesg-map-icon.png'
        case 'DRENESGINFRA':
          return 'cadinfra-map-icon.png'
        default:
          return 'countrykit-map-icon.png'
      }
    },
    changeFrontName (licType) {
      switch (licType) {
        case 'CADInfra':
          return 'CADInfra™ (Básico)'
        case 'DRENESGINFRA':
          return 'CADInfra™ (Completo)'
        case 'DRENESG':
          return 'Drenagem e Esgoto'
        case 'CortexForNavis':
          return 'CortexForNavis™'
        case 'C3DFarm':
          return 'C3DFarm™'
        default:
          return licType
      }
    },
    changeBackName (licType) {
      switch (licType) {
        case 'CADInfra™ (Básico)':
          return 'CADInfra'
        case 'CADInfra™ (Completo)':
          return 'DRENESGINFRA'
        case 'Drenagem e Esgoto':
          return 'DRENESG'
        case 'CortexForNavis™':
          return 'CortexForNavis'
        case 'C3DFarm™':
          return 'C3DFarm'
        default:
          return licType
      }
    },
    async setPermission () {
      if (this.isAdmin) {
        if (!this.openModal.newPermission && !this.openModal.devPermission && !this.openModal.menPermission && !this.openModal.disableActivates && !this.openModal.delActivates) {
          if (this.addPermission.stations <= 5 && this.addPermission.licType !== null && this.addPermission.serialKey !== null && this.addPermission.user.profileId) {
            var situation = 0
            if (this.menus.negociation) {
              situation = 1
            } else if (this.menus.renovation) {
              situation = 2
            }
            const data = {
              criadoEm: Date.now(),
              expires: new Date(this.addPermission.date).getTime(),
              profileId: this.addPermission.user.profileId,
              licType: this.changeBackName(this.addPermission.licType),
              serialKey: this.addPermission.serialKey,
              stations: this.addPermission.stations,
              perpetual: this.menus.perp,
              situation: situation,
              activates: [],
              statusLic: true,
              message: ''
            }
            await this.$store.dispatch('createLicense', data)
            this.openModal.addPermission = false
          } else {
            alert('Verificar se Todos os Campos estão Preenchidos. Caso corretos clique em Adicionar novamente.')
          }
          this.resetDialog()
        } else if (this.openModal.newPermission) {
          const nPerms = []
          for (let i = 0; i < this.upPermission.perms.length; i++) {
            var nSituation = 1
            var nLic = this.menus.nLics[i]
            var renovation = this.menus.renovations[i]
            var pLic = this.menus.pLics[i]
            if (nLic) {
              nSituation = 0
            } else if (renovation) {
              nSituation = 2
            }
            if (new Date(this.upPermission.dates[i]).getTime() !== this.upPermission.perms[i].expires || this.upPermission.perms[i].stations !== this.upPermission.stations[i] || this.upPermission.perms[i].situation !== nSituation || this.upPermission.perms[i].perpetual !== pLic) {
              this.upPermission.perms[i].situation = nSituation
              this.upPermission.perms[i].perpetual = pLic
              this.upPermission.perms[i].expires = new Date(this.upPermission.dates[i]).getTime()
              this.upPermission.perms[i].stations = this.upPermission.stations[i]
              nPerms.push(this.upPermission.perms[i])
            }
          }
          if (nPerms.length > 0) {
            await this.$store.dispatch('updateLicense', nPerms).then(() => {
              this.resetDialog()
            })
          }
        } else if (this.openModal.disableActivates) {
          this.upPermission.lic.activates.forEach(A => {
            A.status = 'desactive'
          })
          await this.$store.dispatch('updateLicense', [this.upPermission.lic]).then(() => {
            this.resetDialog()
          })
        } else if (this.openModal.delActivates) {
          this.upPermission.lic.activates = []
          await this.$store.dispatch('updateLicense', [this.upPermission.lic]).then(() => {
            this.resetDialog()
          })
        } else if (this.openModal.menPermission) {
          this.upPermission.perms.forEach(P => {
            if (!P.statusLic) {
              this.getLicenses.filter(L => { return L.serialKey === P.serialKey })[0].message = P.message
            }
          })
          await this.$store.dispatch('updateLicense', this.upPermission.perms).then(() => {
            this.resetDialog()
          })
        } else {
          alert('Quantidade de Ativações maior que o Suportado.')
          this.resetDialog()
        }
      }
    },
    async openPermissionDialog (btn, item) {
      this.upPermission.profileId = item.profileId
      switch (btn) {
        case 'dev':
          this.upPermission.lic = this.getLicenses.filter(P => {
            return P.serialKey === item.serialKey
          })[0]
          this.openModal.devPermission = true
          this.openModal.newPermission = false
          this.openModal.menPermission = false
          break
        case 'new':
          this.upPermission.perms = this.getLicenses.filter(P => {
            return P.profileId === item.profileId
          })
          var i = 0
          this.upPermission.perms.forEach(L => {
            this.upPermission.dates.push((new Date(L.expires)).toISOString().substr(0, 10))
            this.upPermission.stations.push(L.stations)
            this.menus.pLics[i] = L.perpetual
            if (L.situation !== 1 && L.situation !== 2) {
              this.menus.nLics[i] = true
              this.menus.renovations[i] = false
            } else if (L.situation === 2) {
              this.menus.renovations[i] = true
              this.menus.nLics[i] = false
            }
            i += 1
          })
          this.openModal.newPermission = true
          this.openModal.devPermission = false
          this.openModal.menPermission = false
          break
        case 'men':
          this.upPermission.perms = this.getLicenses.filter(P => {
            return P.profileId === item.profileId
          })
          this.openModal.newPermission = false
          this.openModal.devPermission = false
          var filt = this.upPermission.perms.filter(P => {
            return P.statusLic === false
          })
          if (filt.length > 0) {
            this.openModal.menPermission = true
          } else {
            this.openModal.menPermission = false
            alert('Nenhuma Licença esta Desativada')
          }
          break
        default:
          break
      }
    },
    async delLic (serialKey) {
      await this.$store.dispatch('delLicense', serialKey).then(() => {
        this.resetDialog()
      })
    },
    async changeStatusActive (item) {
      item.status = 'desactive'
      await this.$store.dispatch('updateLicense', [this.upPermission.lic])
    },
    async changeStatusLic () {
      await this.$store.dispatch('updateLicense', [this.upPermission.lic])
    },
    exportExcel () {
      const data = []
      var filtrolics = this.getLicenses.filter(L => {
        return L.licType === this.changeBackName(this.selPlugin)
      })
      if (filtrolics.length > 0) {
        filtrolics.forEach(L => {
          var user = this.getUsers.filter(U => {
            return U.profileId === L.profileId
          })
          if (user.length > 0) {
            var situation = 'Nova Licença'
            var type = 'Temporária'
            var status = 'Desativada'

            if (L.situation === 1) {
              situation = 'Em Negociação'
            } else if (L.situation === 2) {
              situation = 'Renovação'
            }
            if (L.perpetual) {
              type = 'Perpétua'
            }
            if (L.statusLic) {
              status = 'Ativa'
            }
            data.push({
              Nome: L.nomeCompleto,
              Email: L.email,
              Contato: L.telCelular,
              Chave: L.serialKey,
              'Data de Criação': new Date(L.criadoEm),
              'Data de Expiração': new Date(L.expires),
              Tipo: type,
              Status: status,
              Situação: situation,
              Assentos: L.stations
            })
          }
        })
      }

      if (data.length > 0) {
        import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({ json2excel }) => {
          json2excel({
            data,
            name: this.selPlugin,
            formateDate: 'dd/mm/yyyy'
          })
        }).catch((e) => {
          console.log(e)
        })
      } else {
        console.log('Nenhum resultado encontrado.')
      }
    },
    hasPermission (profileId) {
      const permission = this.getLicenses.filter(L => {
        return L.profileId === profileId
      })
      if (permission.length > 0) {
        return true
      }
      return false
    },
    changeSwitch (status) {
      if (status === 'active') {
        return false
      } else {
        return true
      }
    },
    compare (profile1, profile2) {
      // Use toUpperCase() to ignore character casing
      const bandA = profile1.nomeCompleto.toUpperCase()
      const bandB = profile2.nomeCompleto.toUpperCase()

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    },
    resetDialog () {
      this.addPermission.licType = null
      this.addPermission.stations = 1
      this.addPermission.user = null
      this.addPermission.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.openModal.devPermission = false
      this.openModal.newPermission = false
      this.openModal.disableActivates = false
      this.openModal.delActivates = false
      this.openModal.menPermission = false
      this.upPermission = {
        profileId: null,
        dates: [],
        stations: [],
        perms: [],
        lic: null
      }
    },
    generateProductKey () {
      var tokens = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var chars = 5
      var segments = 4
      var keyString = ''

      for (var i = 0; i < segments; i++) {
        var segment = ''

        for (var j = 0; j < chars; j++) {
          var k = Math.floor(Math.random() * (36))
          segment += tokens[k]
        }

        keyString += segment

        if (i < (segments - 1)) {
          keyString += '-'
        }
      }
      this.addPermission.serialKey = keyString
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    async sendEmail () {
      try {
        if (this.radioGroup === 'Instalação') {
          const templateParams = {
            to_email: this.selLic.email,
            plugin: this.selPlugin
          }
          await emailjs.send('service_3hh7drk', 'template_aoy1kdt', templateParams, 'user_OY3ouE4NnsXpKXoHOTnmF').then(T => console.log(T)).catch(error => console.log(error))
        } else if (this.radioGroup === 'Renovação') {
          const templateParams = {
            to_email: this.selLic.email,
            plugin: this.selPlugin
          }
          await emailjs.send('service_3hh7drk', 'template_aoy1kdt', templateParams, 'user_OY3ouE4NnsXpKXoHOTnmF').then(T => console.log(T)).catch(error => console.log(error))
        }
      } catch (error) {
        console.log(error)
      }
      this.selLic = null
      this.radioGroup = null
      this.openModal.modalSendEmail = false
    }
  },
  data () {
    return {
      selLic: null,
      radioGroup: null,
      showRenov: false,
      options: [],
      accessToken: 'pk.eyJ1IjoidmljdG9yZmFndW5kZXMiLCJhIjoiY2t6b2p0Ymh1MmQ3ZzJ2czh5bTR1YmhtayJ9.Ri-sSaZvt5MLuRhTIyHTMg',
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      search: '',
      rules: [
        value => !!value || 'Requerido.',
        value => (value && value <= 5) || 'Máximo de 5 Máquinas.'
      ],
      fab: false,
      transition: 'scale-transition',
      upPermission: {
        profileId: null,
        dates: [],
        stations: [],
        perms: [],
        activates: [],
        lic: null
      },
      menus: {
        perp: false,
        negociation: false,
        renovation: false,
        nLics: [],
        pLics: [],
        renovations: [],
        menuModal: false,
        menuDialog: []
      },
      addPermission: {
        stations: 1,
        licType: null,
        serialKey: null,
        user: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      openModal: {
        modalSendEmail: false,
        modalExpExcel: false,
        addPermission: false,
        devPermission: false,
        newPermission: false,
        menPermission: false,
        disableActivates: false,
        delActivates: false
      },
      itemsPerPageArray: [3, 6, 9],
      searchActivates: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'Criado Em',
      keys: [
        'machineName',
        'osName',
        'hardDisk',
        'criadoEm',
        'motherBoard',
        'city',
        'coords',
        'status'
      ],
      nKeys: {
        machineName: 'Computador',
        osName: 'Nome do Dispositivo',
        hardDisk: 'HD',
        criadoEm: 'Criado Em',
        motherBoard: 'Placa Mãe',
        city: 'Cidade',
        coords: 'Coordenadas',
        status: 'Status'
      },
      uKeys: [
        'Computador',
        'Nome do Dispositivo',
        'HD',
        'Criado Em',
        'Placa Mãe',
        'Cidade',
        'Coordenadas',
        'Status'
      ],
      selPlugin: null,
      tab: 0
    }
  },
  async created () {
    if (this.isAdmin) {
      /* this.map = null */
      if (!this.$store.state.usersMembers.length > 0) {
        await this.$store.dispatch('getAllUsers')
      }
      if (!this.$store.state.adminMembers.length > 0) {
        await this.$store.dispatch('getAllAdmins')
      }
    }
    if (!this.$store.state.licenses.length > 0) {
      await this.$store.dispatch('getAllLicenses')
    }
  }
}
</script>

<style>
.mapboxgl-popup-content {
  padding: 0
}
.v-speed-dial--bottom {
  bottom: 0 !important;
}
::-webkit-scrollbar {
    display: none;
}
.markerIcon {
  position: absolute !important;
  cursor: pointer !important;
}
 .mapboxgl-popup {
  max-width: none !important;
}
.mapboxgl-popup-content {
  padding: 0;
}
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
</style>

<template>
    <Bar :chart-data="chartData" :chart-options="chartOptions" />
  </template>
<script>
import { Bar } from 'vue-chartjs/legacy'

export default {
  components: { Bar },
  computed: {
    getTickets () {
      return this.$store.state.tickets
    },
    getTimeLapse () {
      return this.$store.state.timeLapse
    },
    tickets () {
      return this.getTickets.filter(T => {
        const newDateS = new Date(new Date(this.getTimeLapse.dateS).getTime() + 10800000)
        const newDateE = new Date(new Date(this.getTimeLapse.dateE).getTime() + 86400000)
        return new Date(T.criadoEm).getTime() >= newDateS.getTime() && new Date(T.criadoEm).getTime() <= newDateE.getTime()
      })
    },
    chartData () {
      return {
        labels: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        datasets: [
          {
            label: 'Chamados Abertos',
            backgroundColor: 'rgba(255, 100, 130, 0.4)',
            borderColor: 'red',
            borderWidth: 1.5,
            data: [
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Chamados em Andamento',
            backgroundColor: 'rgba(220, 170, 64, 0.4)',
            borderColor: 'orange',
            borderWidth: 1.5,
            data: [
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 0 && T.status === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 1 && T.status === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 2 && T.status === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 3 && T.status === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 4 && T.status === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 5 && T.status === 2 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 6 && T.status === 2 }).length
            ]
          },
          {
            label: 'Chamados Respondidos',
            backgroundColor: 'rgba(54, 162, 235, 0.4)',
            borderColor: 'blue',
            borderWidth: 1.5,
            data: [
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 0 && T.status === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 1 && T.status === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 2 && T.status === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 3 && T.status === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 4 && T.status === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 5 && T.status === 5 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 6 && T.status === 5 }).length
            ]
          },
          {
            label: 'Chamados Finalizados',
            backgroundColor: 'rgba(100, 192, 192, 0.4)',
            borderColor: 'green',
            borderWidth: 1.5,
            data: [
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 0 && T.status === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 1 && T.status === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 2 && T.status === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 3 && T.status === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 4 && T.status === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 5 && T.status === 3 }).length,
              this.tickets.filter(T => { return new Date(T.criadoEm).getDay() === 6 && T.status === 3 }).length
            ]
          }
        ]
      }
    },
    chartOptions () {
      return { responsive: true, maintainAspectRatio: false }
    }
  }
}
</script>
  <style>
  </style>

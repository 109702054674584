<template>
  <Radar :chart-data="chartData" :chart-options="chartOptions" />
</template>
<script>
import { Radar } from 'vue-chartjs/legacy'

export default {
  components: { Radar },
  methods: {
    getTypes (tipo) {
      return this.$store.state.masterHome.followUp.cValues.filter(T => {
        return tipo === T.type
      })
    }
  },
  computed: {
    chartData () {
      return {
        labels: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        datasets: [
          {
            label: 'Prospecção',
            backgroundColor: 'rgba(18, 79, 219, 0.4)',
            borderColor: '#124fdb',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getTypes('Prospecção').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Paving',
            backgroundColor: 'rgba(18, 79, 219, 0.4)',
            borderColor: '#124fdb',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getTypes('Paving').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Indicação',
            backgroundColor: 'rgba(14, 207, 38, 0.4)',
            borderColor: '#0ecf26',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getTypes('Indicação').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Site',
            backgroundColor: 'rgba(180, 46, 0, 0.4)',
            borderColor: '#b42e00',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getTypes('Site').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Rede Sociais',
            backgroundColor: 'rgba(217, 6, 169, 0.4)',
            borderColor: '#d906a9',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getTypes('Rede Sociais').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          },
          {
            label: 'Renovação',
            backgroundColor: 'rgba(180, 46, 0, 0.4)',
            borderColor: '#e6477c',
            pointBackgroundColor: 'red',
            pointBorderColor: 'red',
            pointHoverBackgroundColor: 'orange',
            pointHoverBorderColor: 'orange',
            data: [
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 0 }).length,
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 1 }).length,
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 2 }).length,
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 3 }).length,
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 4 }).length,
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 5 }).length,
              this.getTypes('Renovação').filter(T => { return new Date(T.criadoEm).getDay() === 6 }).length
            ]
          }
        ]
      }
    },
    chartOptions () {
      return { responsive: true, maintainAspectRatio: false }
    }
  }
}
</script>
<style>
</style>

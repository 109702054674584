<template>
  <Pie :chart-options="chartOptions" :chart-data="chartData" />
</template>
<script>
import { Pie } from 'vue-chartjs/legacy'

export default {
  components: { Pie },
  computed: {
    chartOptions () {
      return {
        responsive: true, maintainAspectRatio: false
      }
    },
    chartData () {
      const NTickets = this.getTickets.filter(T => {
        return new Date(T.criadoEm) >= new Date(this.getTimeLapse.dateS).getTime() && new Date(T.criadoEm) <= new Date(this.getTimeLapse.dateE)
      })
      const ATickets = this.getTickets.filter(T => {
        return T.status === 2 && new Date(T.criadoEm) >= new Date(this.getTimeLapse.dateS).getTime() && new Date(T.criadoEm) <= new Date(this.getTimeLapse.dateE)
      })
      const FTickets = this.getTickets.filter(T => {
        return T.status === 3 && new Date(T.criadoEm) >= new Date(this.getTimeLapse.dateS).getTime() && new Date(T.criadoEm) <= new Date(this.getTimeLapse.dateE)
      })
      const RTickets = this.getTickets.filter(T => {
        return T.status === 4 && new Date(T.criadoEm) >= new Date(this.getTimeLapse.dateS).getTime() && new Date(T.criadoEm) <= new Date(this.getTimeLapse.dateE)
      })
      const RSTickets = this.getTickets.filter(T => {
        return T.status === 5 && new Date(T.criadoEm) >= new Date(this.getTimeLapse.dateS).getTime() && new Date(T.criadoEm) <= new Date(this.getTimeLapse.dateE)
      })
      return {
        labels: [
          'Novos',
          'Em Aberto',
          'Finalizados',
          'Reabertos',
          'Respondidos'
        ],
        datasets: [{
          label: 'Chamados',
          data: [NTickets.length, ATickets.length, FTickets.length, RTickets.length, RSTickets.length],
          backgroundColor: [
            'red',
            'orange',
            'green',
            '#34c0eb',
            '#17a2b8'
          ],
          hoverOffset: 5
        }]
      }
    },
    getTickets () {
      return this.$store.state.tickets
    },
    getTimeLapse () {
      return this.$store.state.timeLapse
    }
  }
}
</script>
<style>
</style>
